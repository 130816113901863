import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    catchError,
    Observable,
    of,
    switchMap,
    throwError,
    BehaviorSubject,
} from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
// import { UserService } from '../services/user.service';
import { ApiUrls } from 'app/config';
import { UserService } from 'app/core/services/user-service/user.service';

@Injectable()
export class AuthService {
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        sessionStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return sessionStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    activeDirectorySignIn(credentials: {
        userName: string;
        password: string;
    }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this.accessToken) {
            return throwError('User is already logged in.');
        }

        return this._httpClient
            .post(ApiUrls.ACTIVE_DIRECTORY_LOGIN, credentials)
            .pipe(
                switchMap((response: any) => {
                    localStorage.removeItem("auto-logout");
                    // Store the access token in the local storage
                    this.accessToken = response.token;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this.accessToken) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(ApiUrls.USER_LOGIN, credentials).pipe(
            switchMap((response: any) => {
                localStorage.removeItem("auto-logout");
                // Store the access token in the local storage
                this.accessToken = response.token;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Renew token
        return this._httpClient
            .post('api/auth/refresh-access-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    localStorage.removeItem("auto-logout");
                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Store the user on the user service
                    this._userService.user = response.user;
                    // Return true
                    return of(true);
                })
            );
    }
    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        sessionStorage.clear();
        localStorage.clear();
        
        localStorage.setItem("auto-logout", "true");

        // Set the authenticated flag to false
        // console.log('auth3', localStorage.getItem('accessToken'));

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this.accessToken) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
