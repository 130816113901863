<mat-form-field class="fuse-mat-dense w-full" floatLabel="always">
    <mat-label [ngClass]="{'danger' : (control?.touched || control?.dirty) && control?.errors}" *ngIf="fieldLabel">
        {{ fieldLabel }}
    </mat-label>

    <input matInput [matDatepicker]="picker" [disabled]="isFieldDisabled" placeholder="DD/MM/YYYY" [readonly]="true"
        [(ngModel)]="defaultDate" [min]=" disablePastDate ? PastDateToDisable : null"
        [max]="PostDateToDisable ? PostDateToDisable : null " (dateInput)="dateEvent($event.value)"
        [required]="isFieldRequird" [errorStateMatcher]="stateMatcher" [style.cursor]="'pointer'"
        [ngClass]="{'disabled-color' : isFieldDisabled}" (click)="picker.open()">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <button type="button" mat-icon-button matSuffix *ngIf="showClearButton" (click)="resetDate()">
        <mat-icon class="text-lg">close</mat-icon>
    </button>
    <mat-datepicker #picker [disabled]="isFieldDisabled">
    </mat-datepicker>
</mat-form-field>