import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ApiUrls } from 'app/config';
import { DownloadService } from 'app/core/services/common/download.service';
import { PurchaseOrgCompany, PurchaseOrgHighCountCompany, PurchaseOrgHighCountCompanyCode, PurchaseOrgHighCountCompanyPo } from 'app/interfaces/purchase-org';
import { RfxForm } from 'app/interfaces/rfx-interface/rfx-form.interface';
import { PaginationResponse } from 'app/interfaces/pagination-response';
import { UserAccessRoles } from 'app/interfaces/User.interface';
import { GRAA, PR, PRE_PR, SUPPLIER_MANAGEMENT, USER_MODULE, USER_ROLE_ACCESS_MODULE_IS_NAMES, USER_ROLE_ACCESS_MODULE_NAMES } from 'app/enums/common-enum/common-enum';

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    groupTwo: number[] = [308, 662, 475, 305, 274, 268, 275, 351, 513, 661, 81, 510, 733, 632, 594, 792, 789];
    groupThree: number[] = [597, 598];
    groupFour: number[] = [187, 179, 290, 385, 476, 640, 178, 650, 413, 167, 756, 755, 318, 757];

    private _data: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private downloadService: DownloadService
    ) {
    }

    set selectedCompany(company: PurchaseOrgCompany) {
        sessionStorage.setItem('selected-company', JSON.stringify(company));
    }

    get selectedCompany(): PurchaseOrgCompany {
        return JSON.parse(sessionStorage.getItem('selected-company')) ?? null;
    }

    getUserRoleAccess(): any {
        return JSON.parse(sessionStorage.getItem('user-role')) ?? null;
    }

    removeSelectedCompany(): any {
        return sessionStorage.removeItem('selected-company');
    }

    set defaultHighCountCompany(company: PurchaseOrgHighCountCompany) {
        sessionStorage.setItem('default-high-count-company', JSON.stringify(company));
    }

    get defaultHighCountCompany(): PurchaseOrgHighCountCompany {
        return JSON.parse(sessionStorage.getItem('default-high-count-company')) ?? null;
    }

    set defaultHighCountCompanyPo(company: PurchaseOrgHighCountCompanyPo) {
        sessionStorage.setItem('default-high-count-company-po', JSON.stringify(company));
    }

    get defaultHighCountCompanyPo(): PurchaseOrgHighCountCompanyPo {
        return JSON.parse(sessionStorage.getItem('default-high-count-company-po')) ?? null;
    }

    getHighPRCount() {
        if (this.defaultHighCountCompany && this.defaultHighCountCompany.highestPRCountByPurch && this.defaultHighCountCompany.highestPRCountByPurch.length > 0) {
            return this.getMaxCount(this.defaultHighCountCompany.highestPRCountByPurch);
        }
        return null;
    }

    getHighPOCount() {
        if (this.defaultHighCountCompanyPo && this.defaultHighCountCompanyPo.highestPoCountByPurch && this.defaultHighCountCompanyPo.highestPoCountByPurch.length > 0) {
            return this.getMaxCount(this.defaultHighCountCompanyPo.highestPoCountByPurch);
        }
        return null;
    }

    getHighGRACount() {
        if (this.defaultHighCountCompany && this.defaultHighCountCompany.highestGraCountByPurch && this.defaultHighCountCompany.highestGraCountByPurch.length > 0) {
            return this.getMaxCount(this.defaultHighCountCompany.highestGraCountByPurch);
        }
        return null;
    }

    getHighInvoiceCount() {
        if (this.defaultHighCountCompany && this.defaultHighCountCompany.highestInvoiceCountByPurch && this.defaultHighCountCompany.highestInvoiceCountByPurch.length > 0) {
            return this.getMaxCount(this.defaultHighCountCompany.highestInvoiceCountByPurch);
        }
        return null;
    }

    getHighBuyerContractCount() {
        if (this.defaultHighCountCompany && this.defaultHighCountCompany.highestContractPriceByPurch && this.defaultHighCountCompany.highestContractPriceByPurch.length > 0) {
            return this.getMaxCount(this.defaultHighCountCompany.highestContractPriceByPurch);
        }
        return null;
    }

    getHighPaymentRemittanceCount() {
        if (this.defaultHighCountCompany && this.defaultHighCountCompany.highestPaymentRemitCountByPurch && this.defaultHighCountCompany.highestPaymentRemitCountByPurch.length > 0) {
            return this.getMaxCount(this.defaultHighCountCompany.highestPaymentRemitCountByPurch);
        }
        return null;
    }

    getPurchasingOrgList(apiUrl: string, type: string): Observable<any> {
        let params = new HttpParams();

        if(type == USER_MODULE.PAYMENT_REMITTANCE){
            let access = this.getUserRoleModuleWise(USER_MODULE.PAYMENT_REMITTANCE);
            if (access.length) {
                params = params.set(this.userAccessGetPermissionIsName(access), true);
                params = params.set('isPaymentRemittance', access[0]);
            }
        }else if(type == USER_MODULE.PR){
            let access = this.getUserRoleModuleWiseChild(USER_MODULE.PRE_PR, PRE_PR.PRE_PR_List);
            if (access.length) {
                params = params.set('isPr', access[0]);
            }
        }else if(type == USER_MODULE.INVOICE){
            let access = this.getUserRoleModuleWise(USER_MODULE.INVOICE);
            if (access.length) {
                params = params.set('isInvoice', access[0]);
            }
        }else if(type == USER_MODULE.GRA){
            let access = this.getUserRoleModuleWiseChild(USER_MODULE.GRA, GRAA.GRA_LIST);
            if (access.length) {
                params = params.append('status', 'draft,pending');
                params = params.set('isGra', access[0]);
            }
        }else{
            params = params.append('status', 'draft,pending');
        }
        return this._httpClient.get<any>(apiUrl, { params });
    }

    getMaxCount(varName, isAddOne: boolean = false) {
        var returnCount: number = 0;
        var returnCode: number = 0;
        varName.map((res: PurchaseOrgHighCountCompanyCode, index) => {
            var count: number = Number(res.count);
            var purchorg: number = Number(res.purchorg);
            if (isAddOne) {
                purchorg = Number(purchorg + "1");
            }
            if (index == 0) {
                returnCode = purchorg;
                returnCount = count;
            } else {
                if (res && count && count > returnCount) {
                    returnCode = purchorg;
                    returnCount = count;
                }
            }
        });
        return returnCode.toString();
    }

    getPurchasingOrgListByHighCountByPo(): Observable<any> {
        let params = new HttpParams();
        // params = params.append('status', 'draft,pending');

        let access = this.getUserRoleModuleWise(USER_MODULE.PO);
        if (access.length) {
            params = params.set(this.userAccessGetPermissionIsName(access), true);
            params = params.set('isPo', access[0]);
        }

        return this._httpClient.get<any>(ApiUrls.PURCHASE_ORG_LIST_HIGH_COUNT_BY_PO, { params });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any> {
        return this._data.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(): Observable<any> {
        return this._httpClient.get('api/dashboards/project').pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }

    getDashboardCount(options): Observable<any> {
        let params = new HttpParams();
        if (options.purchasingOrgCode && options.purchasingOrgCode !== '') {
            params = params.set('purchasingOrgCode', options.purchasingOrgCode);
        }
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_COUNT}`,
            { params }
        );
    }

    getDashboardDataCount(type, options): Observable<any> {
        const params = this.getParamsForDashboardCount(options);

        let url = '';
        if(type == 'due'){
            url = ApiUrls.DASHBOARD_DUE_TOTAL_COUNT;
        }else if(type == 'draft'){
            url = ApiUrls.DASHBOARD_DRAFT_TOTAL_COUNT;
        }else if(type == 'pending'){
            url = ApiUrls.DASHBOARD_PENDING_TOTAL_COUNT;
        }else {
            url = ApiUrls.DASHBOARD_ACTIVE_TOTAL_COUNT;
        }
        return this._httpClient.get<any>(
            `${url}`,
            { params }
        );
    }

    getParamsForDashboardCount(options) {
        let params = new HttpParams();
        if (options.purchasingOrgCode && options.purchasingOrgCode !== '') {
            params = params.set('purchasingOrg', options.purchasingOrgCode);
        }
        params = params.set('status', 'finished');

        let access = this.getUserRoleModuleWiseChild(USER_MODULE.GRA, GRAA.GRA_LIST);
        if (access.length) {
            params = params.set('isGra', access[0]);
        }
        access = this.getUserRoleModuleWiseChild(USER_MODULE.PRE_PR, PRE_PR.PRE_PR_List);
        if (access.length) {
            params = params.set('isPrePr', access[0]);
        }
        access = this.getUserRoleModuleWiseChild(USER_MODULE.PR, PR.PR_LIST);
        if (access.length) {
            params = params.set('isPr', access[0]);
        }
        access = this.getUserRoleModuleWise(USER_MODULE.SOURCING);
        if (access.length) {
            params = params.set('isSourcing', access[0]);
        }
        access = this.getUserRoleModuleWise(USER_MODULE.PO);
        if (access.length) {
            params = params.set('isPo', access[0]);
        }
        access = this.getUserRoleModuleWise(USER_MODULE.ANNOUNCEMENT);
        if (access.length) {
            params = params.set('isAnnounce', access[0]);
        }
        access = this.getUserRoleModuleWise(USER_MODULE.INVOICE);
        if (access.length) {
            params = params.set('isInvoice', access[0]);
        }
        access = this.getUserRoleModuleWiseChild(USER_MODULE.SUPPLIER_MANAGEMENT, SUPPLIER_MANAGEMENT.SUPPLIER_FORM);
        if (access.length) {
            params = params.set('isSupplierForm', access[0]);
        }
        access = this.getUserRoleModuleWiseChild(USER_MODULE.SUPPLIER_MANAGEMENT, SUPPLIER_MANAGEMENT.SUPPLIER_LIST);
        if (access.length) {
            params = params.set('isSupplierList', access[0]);
        }

        return params;
    }

    //Due Tasks Start
    getDueTasksPrePrData(): Observable<any> {
        let params = new HttpParams();
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_DUE_TASKS_PRE_PR}`,
            { params }
        );
    }

    getDueTasksSourcingEventData(): Observable<any> {
        let params = new HttpParams();
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_DUE_TASKS_SOURCING_EVENT}`,
            { params }
        );
    }

    getDueTasksResumeSourcingData(): Observable<any> {
        let params = new HttpParams();
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_DUE_TASKS_RESUME_SOURCING_EVENT}`,
            { params }
        );
    }

    getDueTasksSourcingEvaluationData(): Observable<any> {
        let params = new HttpParams();
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_DUE_TASKS_SOURCING_EVALUATION}`,
            { params }
        );
    }

    getDueTasksPendingBuyerEvaluationData(): Observable<any> {
        let params = new HttpParams();
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_DUE_TASKS_PENDING_BUYER_EVALUATION}`,
            { params }
        );
    }

    getDueTasksPendingRequestorEvaluationData(): Observable<any> {
        let params = new HttpParams();
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_DUE_TASKS_PENDING_REQUESTOR_EVALUATION}`,
            { params }
        );
    }

    getDueTasksPendingPRCreationData(options): Observable<any> {
        let params = new HttpParams();
        if (options.purchaseOrgCode && options.purchaseOrgCode !== '') {
            params = params.set('purchaseOrgCode', options.purchaseOrgCode);
        }
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_DUE_TASKS_PENDING_PR_CREATION}`,
            { params }
        );
    }

    getDueTasksSourcingProposalData(): Observable<any> {
        let params = new HttpParams();
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_DUE_TASKS_SOURCING_PROPOSAL}`,
            { params }
        );
    }
    //Due Tasks End

    //Tasks strat
    getTasksPrePrListData(): Observable<any> {
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_TASKS_PRE_PR_LIST}`,
        );
    }
    //Tasks End

    //Pending End
    getPendingPrePrData(): Observable<any> {
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_PENDING_PRE_PR}`,
        );
    }
    //Pending End

    getDashboardScheduleTaskData(): Observable<any> {
        let url: any = ApiUrls.DASHBOARD_SCHEDULE_TASK;
        let access = this.getUserRoleModuleWise(USER_MODULE.SOURCING);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}${this.userAccessGetPermissionIsName(access)}=true`);
        }

        return this._httpClient.get<any>(
            `${url}`,
        );
    }

    getBuyerDashboardRequestSummary(filter: { fromDate?: string; toDate?: string; purchaseOrg: string }): Observable<any> {
        let url: any = ApiUrls.DASHBOARD_REQUEST_SUMMARY;
        Object.keys(filter).forEach((key) => {
            if (filter[key] || filter[key] === false) {
                url = url.concat(`${url.includes('?') ? '&' : '?'}${key}=${filter[key]}`);
            }
        });

        let access = this.getUserRoleModuleWiseChild(USER_MODULE.GRA, GRAA.GRA_LIST);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}isGra=${access[0]}`);
        }
        access = this.getUserRoleModuleWiseChild(USER_MODULE.PRE_PR, PRE_PR.PRE_PR_List);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}isPrePr=${access[0]}`);
        }
        access = this.getUserRoleModuleWiseChild(USER_MODULE.PR, PR.PR_LIST);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}isPr=${access[0]}`);
        }
        access = this.getUserRoleModuleWise(USER_MODULE.SOURCING);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}isSourcing=${access[0]}`);
        }
        access = this.getUserRoleModuleWise(USER_MODULE.PO);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}isPo=${access[0]}`);
        }
        return this._httpClient.get<any>(url);
    }

    getDashboardRequestTracking(filter: { fromDate?: string; toDate?: string; purchaseOrg: string }): Observable<any> {
        let url: any = ApiUrls.DASHBOARD_REQUEST_TRACKING;
            Object.keys(filter).forEach((key) => {
                if (filter[key]) {
                    url = url.concat(`${url.includes('?') ? '&' : '?'}${key}=${filter[key]}`);
                }
            });

        let access = this.getUserRoleModuleWiseChild(USER_MODULE.PRE_PR, PRE_PR.PRE_PR_List);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}isPrePr=${access[0]}`);
        }

        return this._httpClient.get<any>(url);
    }

    getDashboardUserWisePurchaseOrg(): Observable<any> {
        let url: any = ApiUrls.DASHBOARD_USER_ORG_LIST;
        return this._httpClient.get<any>(url);
    }

    dashboardPrPendingCountByPurchaseOrg(filter: any): Observable<any> {
        let url: any = ApiUrls.DASH_PR_PENDING_COUNT_BY_PURCHASE_ORG;
        Object.keys(filter).forEach((key) => {
            if (filter[key]) {
                url = url.concat(`${url.includes('?') ? '&' : '?'}${key}=${filter[key]}`);
            }
        });
        let access = this.getUserRoleModuleWiseChild(USER_MODULE.PR, PR.PR_LIST);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}isPr=${access[0]}`);
        }
        return this._httpClient.get<any>(url);
    }

    dashboardPoPendingCountByPurchaseOrg(filter: any): Observable<any> {
        let url: any = ApiUrls.DASH_PO_PENDING_COUNT_BY_PURCHASE_ORG;
        Object.keys(filter).forEach((key) => {
            if (filter[key]) {
                url = url.concat(`${url.includes('?') ? '&' : '?'}${key}=${filter[key]}`);
            }
        });
        return this._httpClient.get<any>(url);
    }

    // downloadrequestTrackingReportExcel(): void {
    //     this.downloadService.downloadExcelByUrl(
    //         `${ApiUrls.DASHBOARD_DOWNLOAD_REQUEST_TRACKING_REPORT_EXCEL}`,
    //         'Request Tracking Report'
    //     );
    // }

    downloadrequestTrackingReportExcel(filter: { keyword?: string; fromDate?: string; toDate?: string; purchaseOrg: string }): void {
        let url: any = ApiUrls.DASHBOARD_DOWNLOAD_REQUEST_TRACKING_REPORT_EXCEL;
        Object.keys(filter).forEach((key) => {
            if (filter[key]) {
                url = url.concat(`${url.includes('?') ? '&' : '?'}${key}=${filter[key]}`);
            }
        });

        this.downloadService.callDownloadGetAPI(url, 'Request Tracking Report', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    getDashboardFinishedSourcingProposal(): Observable<any> {
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_Finished_Sourcing_Proposal}`,
        );
    }

    getBuyerActiveEventsData(options: any): Observable<PaginationResponse<Array<RfxForm>>> {
        let params = new HttpParams();
        const sourcingKeys = ['title', 'internalReferenceNumber', 'eventType', 'rfxSequenceId', 'buyer', 'purchasingOrgCode'];
        for (const [key, value] of Object.entries(options)) {
            if (key && value) {
                if (sourcingKeys.includes(key)) {
                    if (key === 'buyer') {
                        params = params.set(`fq=creator.name`, value.toString())
                    } else if (key === 'purchasingOrgCode') {
                        params = params.set(`fq=purchOrg.name`, value.toString())
                    } else {
                        params = params.set(`fq=rfx.${key}`, value.toString())
                    }
                } else {
                    params = params.set(key, value.toString())
                }
            }
        }

        const accessObj = this.getUserRoleModuleWise(USER_MODULE.SOURCING);
        if (accessObj.length) {
            params = params.set('isSourcing', accessObj[0]);
        }

        // const access = this.getUserRoleModuleWise(USER_MODULE.SOURCING);
        // if (access.length) {
        //     params = params.set(this.userAccessGetPermissionIsName(access), true);
        // }
        return this._httpClient.get<any>(
            `${ApiUrls.DASHBOARD_BUYER_ACTIVE_EVENTS}`,
            { params }
        );
    }

    getDashboardSelfManagementTracking(filter: { fromDate?: string; toDate?: string; purchasingOrg: string }): Observable<any> {
        let url: any = ApiUrls.DASHBOARD_SELF_MANAGEMENT_TRACKING;
        Object.keys(filter).forEach((key) => {
            if (filter[key]) {
                url = url.concat(`${url.includes('?') ? '&' : '?'}${key}=${filter[key]}`);
            }
        });

        let access = this.getUserRoleModuleWise(USER_MODULE.SOURCING);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}isSourcing=${access[0]}`);
        }

        return this._httpClient.get<any>(url);
    }

    getDashboardExportSelfManagementTrackingList(filter: { fromDate?: string; toDate?: string; purchasingOrg: string }): void {
        let url: any = ApiUrls.DASHBOARD_SELF_MANAGEMENT_TRACKING_EXPORT;
        Object.keys(filter).forEach((key) => {
            if (filter[key]) {
                url = url.concat(`${url.includes('?') ? '&' : '?'}${key}=${filter[key]}`);
            }
        });

        this.downloadService.callDownloadGetAPI(url, 'Self Management Tracking Report', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    getUserRoleModuleWise(module): Array<any> {
        if (sessionStorage.getItem('t-pro') === null) {
            return [];
        }
        let userRole = JSON.parse(sessionStorage.getItem('user-role')) ?? null;

        if (userRole !== null && userRole != "{}") {
            if (userRole[module]) {
                return userRole[module];
            }
        }
        return [];
    }

    hasUserRoleModuleWise(module): boolean {
        if (sessionStorage.getItem('t-pro') === null) {
            return true;
        }
        let userRole = JSON.parse(sessionStorage.getItem('user-role')) ?? null;

        if (userRole !== null && userRole != "{}") {
            if (userRole[module]) {
                return true;
            }
        }
        return false;
    }

    getUserRoleModuleWiseChild(module, child): Array<any> {
        if (sessionStorage.getItem('t-pro') === null) {
            return [];
        }
        let userRole = JSON.parse(sessionStorage.getItem('user-role')) ?? null;

        if (userRole !== null && userRole != "{}") {
            if (userRole[module]) {
                if (userRole[module][child]) {
                    return userRole[module][child];
                }
            }
        }
        return [];
    }

    hasUserRoleModuleWiseChild(module, child): boolean {
        if (sessionStorage.getItem('t-pro') === null) {
            return true;
        }
        let userRole = JSON.parse(sessionStorage.getItem('user-role')) ?? null;

        if (userRole !== null && userRole != "{}") {
            if (userRole[module]) {
                if (userRole[module][child]) {
                    return true;
                }
            }
        }
        return false;
    }

    userAccessCheckRolesChild(module, child = ''): UserAccessRoles {
        if (child != '') {
            var roles: string[] = this.getUserRoleModuleWiseChild(module, child);
        } else {
            var roles: string[] = this.getUserRoleModuleWise(module);
        }

        var accessObj: UserAccessRoles = {
            isAdmin: false,
            isAdminViewer: false,
            isDeptAdmin: false,
            isDeptViewer: false,
            isNormal: false,
        };

        if (roles.length) {
            if (roles.includes(USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR)) {
                accessObj.isAdmin = true;
                return accessObj;
            }
            if (roles.includes(USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR_VIEWER)) {
                accessObj.isAdminViewer = true;
                if (roles.includes(USER_ROLE_ACCESS_MODULE_NAMES.NORMAL)) {
                    accessObj.isNormal = true;
                }
                return accessObj;
            }
            if (roles.includes(USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_ADMINISTRATOR)) {
                accessObj.isDeptAdmin = true;
                return accessObj;
            }
            if (roles.includes(USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_VIEWER)) {
                accessObj.isDeptViewer = true;
                return accessObj;
            }
            if (roles.includes(USER_ROLE_ACCESS_MODULE_NAMES.NORMAL)) {
                accessObj.isNormal = true;
            }
        }

        return accessObj;
    }

    permissionAccessCheckByRole(item: {recordDepartmentId: any, currentDepartmentId: any, isDV: boolean, module: string, child?: string}): boolean {
        if (item.child && item.child != '') {
            var access: string[] = this.getUserRoleModuleWiseChild(item.module, item.child);
        } else {
            var access: string[] = this.getUserRoleModuleWise(item.module);
        }

        if (access.length) {
            if (access[0] == USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR) {
                return true;
            }
            if (access[0] == USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR_VIEWER) {
                return true;
            }
            if(item.recordDepartmentId && item.currentDepartmentId && item.recordDepartmentId == item.currentDepartmentId){
                if (access[0] == USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_VIEWER && item.isDV) {
                    return true;
                }
                if (access[0] == USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_ADMINISTRATOR) {
                    return true;
                }
            }
        }

        return false;
    }

    userAccessGetPermissionIsName(access: any[]) : USER_ROLE_ACCESS_MODULE_IS_NAMES {
        if(access.length){
            if (access[0] == USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR) {
                return USER_ROLE_ACCESS_MODULE_IS_NAMES.isAdmin;
            } else if (access[0] == USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR_VIEWER) {
                return USER_ROLE_ACCESS_MODULE_IS_NAMES.isAdminViewer;
            } else if (access[0] == USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_ADMINISTRATOR) {
                return USER_ROLE_ACCESS_MODULE_IS_NAMES.isDepartmentAdmin;
            } else if (access[0] == USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_VIEWER) {
                return USER_ROLE_ACCESS_MODULE_IS_NAMES.isDepartmentViewer;
            }
        }
        return USER_ROLE_ACCESS_MODULE_IS_NAMES.isNormal;
    }
}
