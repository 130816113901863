import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from 'app/core/services/util.service';
import { DROPDOWN_TYPE } from 'app/enums/form-enum/form-enum';
import { ViewerTypeEnum } from 'app/enums/viewer-type.enum';
import { Member } from 'app/interfaces/rfx-interface/rfx-template.interface';
import { User } from 'app/interfaces/user-interface/user-interface';
import { Subscription } from 'rxjs';
import { TeamMemberViewerTypeComponent } from './team-member-viewer-type/team-member-viewer-type.component';

@Component({
    selector: 'app-team-member-form',
    templateUrl: './team-member-form.component.html',
    styleUrls: ['./team-member-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: TeamMemberFormComponent,
            multi: true
        }
    ]
})
export class TeamMemberFormComponent implements ControlValueAccessor {
    @Input() userList: User[] = []
    @Input() displayReadonlyCheckbox: boolean
    @Input() checkboxFormControl: FormControl
    @Input() disableForm: boolean = false

    isFieldDisabled: boolean
    teamMemberSubs$: Subscription[] = []
    DROPDOWN_TYPE = DROPDOWN_TYPE

    selectedMembers: Member[] = []
    duplicatedUserId: number

    updateMainForm = (formValue: Member[]) => { }

    constructor(
        public dialog: MatDialog,
        private util_service: UtilService
    ) { }

    addTeamMember(selectedTeam: { name: string, userId: number }): void {
        let isMemberAlreadyExist: boolean = false
        const clearDuplicateId = () => this.duplicatedUserId = null

        this.selectedMembers.forEach((member) => {
            if (member.userId === selectedTeam.userId) {
                isMemberAlreadyExist = true
                this.duplicatedUserId = selectedTeam.userId
                this.util_service.showErrorSnack('Member Already Exist')
                setTimeout(clearDuplicateId, 5000)
            }
        })

        if (isMemberAlreadyExist) return
        clearDuplicateId()

        const dialogRef = this.dialog.open(TeamMemberViewerTypeComponent, {
            autoFocus: false,
            width: '300px',
            backdropClass: 'transparent-backdrop'
        });


        const dRef = dialogRef.afterClosed().subscribe((viewStatus: ViewerTypeEnum) => {
            if (!viewStatus) return
            this.selectedMembers.push({ ...selectedTeam, viewStatus })

            this.updateMainForm(this.selectedMembers)
        });

        this.teamMemberSubs$.push(dRef)
    }

    removeTeamMember(index: number): void {
        this.selectedMembers.splice(index, 1);
        this.updateMainForm(this.selectedMembers)
    }

    filterUserName(userId: number) {
        return this.userList.find((list) => list.id === userId).name
    }

    writeValue(members: Member[]): void {
        if (!members) return
        this.selectedMembers = members
    }

    registerOnChange(fn: any): void { this.updateMainForm = fn }
    registerOnTouched(fn: any): void { }

    setDisabledState?(isDisabled: boolean): void {
        this.isFieldDisabled = isDisabled
    }
}
