import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MultilineErrorSnackComponent } from 'app/shared/multiline-error-snack/multiline-error-snack.component';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor(
        private matSnackBar: MatSnackBar,
    ) { }

    isNullOrEmpty(value: string | undefined | null): boolean {
        return (value == null || value === undefined || this.isEmpty(value));
    }

    isEmpty(value: string): boolean {
        return (!value || value.trim() === '' || (value.trim()).length === 0);
    }

    showSuccessSnack(message: string): void {
        if (message) {
            this.matSnackBar.open(message, undefined, {
                duration: 5000,
                panelClass: ['green-snackbar'],
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        }
    }

    showErrorSnack(message: string, duration: number = 5000): void {
        if (message) {
            this.matSnackBar.open(message, undefined, {
                duration: duration,
                panelClass: ['red-snackbar'],
                horizontalPosition: 'center',
                verticalPosition: 'top',
            });
        }
    }

    showMultilineErrorSnack(message: string[], duration: number = 5000): void {
        this.matSnackBar.openFromComponent(MultilineErrorSnackComponent, {
            duration: duration,
            panelClass: ['red-snackbar'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: message
        });
    }

    public getEnumForDropDown(enums: any): any {
        const newObjects: Array<any> = [];
        const enumKeys = Object.keys(enums);
        enumKeys.forEach((data) => {
            const newObject: any = {};
            newObject.id = data;
            newObject.name = enums[data];
            newObjects.push(newObject);
        });
        return newObjects;
    }

    formatString(str: string, ...val: string[]): string {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }

    encode(value: any) {
        return window.btoa(value);
    }

    decode(value: any) {
        return window.atob(value);
    }
}
