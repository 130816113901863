<mat-tab-group id="tabBlock">
    <form action=""
        [formGroup]="rfxEventForm">
        <mat-tab label="Create Sourcing Event">
            <div class="p-6">
                <div class="w-full md:inline-flex">
                    <mat-label class="font-medium text-gray-900 md:w-4/12 md:mt-3">Event Type</mat-label>
                    <app-dropdown [options]="eventType"
                        optionViewKey="name"
                        [outputPattern]="['name']"
                        formControlName="event"
                        [useUppercase]="true"
                        placeholder="Request For Quotation / Request For Tender"
                        class="md:w-8/12"
                        (changeEvent)="selectedEvent($event)">
                    </app-dropdown>
                </div>
                <div class="w-full md:inline-flex"
                    *ngIf="rfxEventForm.get('event').value">
                    <mat-label class="font-medium text-gray-900 md:w-4/12 md:mt-3">Select Template</mat-label>
                    <app-dropdown [options]="templateList"
                        optionViewKey="templateName"
                        [outputPattern]="['id']"
                        formControlName="rfxTemplateId"
                        placeholder="Select Template"
                        class="md:w-8/12"
                        [dropdownType]="dropdownType.SEARCH"
                        [useDefaultCasing]="true">
                    </app-dropdown>
                </div>
            </div>

            <div class="px-6 py-3 flex items-center justify-end bg-gray-100">
                <button type="button"
                    mat-raised-button
                    class="bg-[#D9D9D9] rounded"
                    (click)="closeRfx()"
                    mat-dialog-close>Cancel</button>
                <button class="mat-primary ml-4 rounded"
                    (click)="createRfx()"
                    type="button"
                    mat-raised-button>Create</button>
            </div>
        </mat-tab>

        <!-- Repeat Purchase -->
        <mat-tab label="Repeat Purchase">
            <form action=""
                [formGroup]="repeatPurchaseForm">
                <div class="p-6">
                    <div class="w-full md:inline-flex">
                        <mat-label class="font-medium text-gray-900 md:w-4/12 md:mt-3">Sourcing Title</mat-label>
                        <app-dropdown [options]="finishedRfxList"
                            optionViewKey="displayName"
                            [outputPattern]="['id']"
                            placeholder="Sourcing Title"
                            class="md:w-8/12"
                            formControlName="rfxId"
                            [dropdownType]="dropdownType.SEARCH"
                            [useDefaultCasing]="true">
                        </app-dropdown>
                    </div>
                    <div class="w-full md:inline-flex">
                        <mat-label class="font-medium text-gray-900 md:w-4/12 md:mt-3">Assign As Team Member</mat-label>
                        <app-multi-select-dropdown [options]="userList"
                            optionViewKey="title"
                            [outputPattern]="[[], 'id']"
                            optionViewKey="name"
                            class="md:w-8/12"
                            formControlName="newTeamMembers"
                            [useDefaultCasing]="true">
                        </app-multi-select-dropdown>
                    </div>
                </div>
            </form>

            <div class="px-6 py-3 flex items-center justify-end bg-gray-100">
                <button type="button"
                    mat-raised-button
                    class="bg-[#D9D9D9] rounded"
                    mat-dialog-close>Cancel</button>
                <button class="mat-primary ml-4 rounded"
                    (click)="concludeEvent()"
                    mat-raised-button>Conclude</button>
            </div>
        </mat-tab>
    </form>
</mat-tab-group>