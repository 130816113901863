import { CommonService } from 'app/core/services/common/common-service/common.service';
import { Component, Optional, Self, OnDestroy, Input } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
    Qsection,
    Question,
    Questionnaire,
} from 'app/interfaces/rfx-interface/questionnaire.interface';
import { QSectionFormComponent } from './q-section-form/q-section-form.component';
import { QestionFormComponent } from './qestion-form/qestion-form.component';

let questionnaireBase = [
    {
        id: null,
        setName: null,
        sections: [],
        setSequence: 1,
        isEvaluationMappingRequired: false,
    },
];

export enum ADD_OR_UPDATE {
    ADD = 'add',
    UPDATE = 'update',
}

@Component({
    selector: 'app-questionnaire-form',
    templateUrl: './questionnaire-form.component.html',
    styleUrls: ['./questionnaire-form.component.scss'],
})
export class QuestionnaireFormComponent
    implements ControlValueAccessor, OnDestroy
{
    @Input() enableAddMoreButton: boolean = false;
    @Input() displayMappingCheckbox: boolean;

    questionnaireList: Questionnaire[] = [...questionnaireBase];
    ADD_OR_UPDATE_ENUM = ADD_OR_UPDATE;

    displayError: boolean = true;
    isFieldDisabled: boolean;

    onTouched = () => {};
    updateParentForm = (formValue: Questionnaire[]) => {};

    constructor(
        private dialog: MatDialog,
        private common_services: CommonService,
        @Self() @Optional() public control: NgControl
    ) {
        this.control && (this.control.valueAccessor = this);
        this.common_services.markAsTouchMethods.push(
            this.activateError.bind(this)
        );
    }

    addQuestionnaireSet() {
        const queLength = this.questionnaireList.length;
        const prevQuestionnaire = this.questionnaireList[queLength - 1];

        if (prevQuestionnaire.setName && prevQuestionnaire.sections.length) {
            this.displayError = false;
            this.questionnaireList.push({
                id: null,
                setName: '',
                sections: [],
                setSequence: queLength + 1,
            });
        }
    }

    removeQuestionnaierSet(parentIndex: number) {
        if (this.questionnaireList.length <= 1) return;
        this.questionnaireList.splice(parentIndex, 1);
    }

    sectionList(parentIndex: number): Array<Qsection> {
        return this.questionnaireList[parentIndex].sections;
    }

    questionList(parentIndex: number, childIndex: number): Array<Question> {
        return this.questionnaireList[parentIndex].sections[childIndex]
            .questions;
    }

    editSection(parentIndex: number, childIndex: number) {
        const section: Qsection =
            this.questionnaireList[parentIndex].sections[childIndex];
        this.sectionPopup(
            ADD_OR_UPDATE.UPDATE,
            parentIndex,
            childIndex,
            section
        );
    }

    removeSection(parentIndex: number, childIndex: number) {
        this.sectionList(parentIndex).splice(childIndex, 1);

        this.updateParentForm(this.questionnaireList);
    }

    sectionPopup(
        addOrUpdate: ADD_OR_UPDATE,
        parentIndex: number,
        childIndex?: number,
        selcetedObj?: Qsection
    ) {
        this.onTouched();
        function updateOrAddSection(
            action: ADD_OR_UPDATE,
            formValue: Qsection
        ) {
            if (action === ADD_OR_UPDATE.ADD) {
                this.sectionList(parentIndex).push(formValue);
                this.questionnaireList[parentIndex].setName
                    ? (this.displayError = false)
                    : (this.displayError = true);
            }

            if (action === ADD_OR_UPDATE.UPDATE) {
                this.questionnaireList[parentIndex].sections[childIndex] =
                    formValue;
            }
            this.submitQuestionnaire();
        }

        this.dialog.open(QSectionFormComponent, {
            data: {
                formValue: selcetedObj,
                sectionLength: this.sectionList(parentIndex).length + 1,
                submitForm: updateOrAddSection.bind(this, addOrUpdate),
            },
            panelClass: 'eventpop',
            minWidth: '45vw',
            autoFocus: false,
        });
    }

    editQuestion(parentIndex: number, childIndex: number, qIndex: number) {
        const question: Question = this.questionList(parentIndex, childIndex)[
            qIndex
        ];
        this.questionPopup(
            ADD_OR_UPDATE.UPDATE,
            parentIndex,
            childIndex,
            qIndex,
            question
        );
    }

    removeQuestion(parentIndex: number, childIndex: number, qIndex: number) {
        this.questionList(parentIndex, childIndex).splice(qIndex, 1);

        this.submitQuestionnaire();
    }

    questionPopup(
        addOrUpdate: ADD_OR_UPDATE,
        parentIndex: number,
        childIndex?: number,
        qIndex?: number,
        selcetedObj?: Question
    ) {
        function addOrUpdateQuestion(action: ADD_OR_UPDATE, formValue: any) {
            if (action === ADD_OR_UPDATE.ADD) {
                this.questionList(parentIndex, childIndex).push(formValue);
            }
            if (action === ADD_OR_UPDATE.UPDATE) {
                this.questionList(parentIndex, childIndex)[qIndex] = formValue;
            }

            this.submitQuestionnaire();
        }

        this.dialog.open(QestionFormComponent, {
            data: {
                formValue: selcetedObj,
                set: this.questionnaireList[parentIndex],
                previousQuestions: this.questionList(parentIndex, childIndex),
                submitForm: addOrUpdateQuestion.bind(this, addOrUpdate),
                config : { action : ADD_OR_UPDATE }
            },
            panelClass: 'eventpop',
            minWidth: '48vw',
            maxWidth: '55vw',
            autoFocus: false,
            maxHeight: '95vh',
        });
    }

    setNameEvent() {
        this.submitQuestionnaire();
    }

    submitQuestionnaire() {
        this.updateParentForm(this.questionnaireList);
    }

    writeValue(defaultQuestionnaire: Questionnaire[]): void {
        if (defaultQuestionnaire && defaultQuestionnaire.length) {
            const sortedQues = defaultQuestionnaire.sort(
                (a, b) => a.setSequence - b.setSequence
            );
            sortedQues.map((quest) => {
                const sortedSection = quest.sections.sort(
                    (a, b) => a.sectionSequence - b.sectionSequence
                );
                return sortedSection.map((section) => {
                    const sortedQuestion = section.questions.sort(
                        (a, b) => a.questionSequence - b.questionSequence
                    );
                    return sortedQuestion;
                });
            });
            this.questionnaireList = sortedQues;
        } else this.questionnaireList = questionnaireBase;
    }

    registerOnChange(fn: any): void {
        this.updateParentForm = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.isFieldDisabled = isDisabled;
    }

    activateError() {
        this.onTouched();
        this.displayError = true;
    }

    ngOnDestroy(): void {
        questionnaireBase = [
            {
                id: null,
                setName: null,
                sections: [],
                setSequence: 0,
                isEvaluationMappingRequired: false,
            },
        ];
    }
}
