/* eslint-disable @typescript-eslint/naming-convention */
export const COMMON_ENDPOINT = {
    COST_CENTER_LIST: '/api/buyer/master/cost-center-list',
    COST_CENTER_LIST_DROPDOWN: '/api/buyer/master/cost-centers-list-dropdown',
    CURRENCY_LIST: '/api/buyer/master/currency-list',
    PLANT_LIST: '/api/buyer/master/plant-list',
    ACCOUNT_ASSIGNMENT_LIST: '/api/buyer/master/account-assignment-code-list',
    BUDGET_REFERENCE_LIST: '/api/buyer/budget-reference/budget-reference-list',
    PURCHASE_ORG_LIST: '/api/buyer/master/purchase-org-list',
    GL_CODE_LIST: '/api/buyer/master/gl-code-list',

    DELIVERY_ADDRESS_LIST: '/api/buyer/master/address-list',
    // DEPARTMENTOPTION_LIST: '/api/buyer/flat/department-list',
    DEPARTMENTOPTION_LIST: '/user/active-department-list',
    DEPARTMENT_LIST_BY_PURCHASE_ORG: '/user/get-department-by-purchaseOrgID/',

    DEPARTMEN_BY_ID: '/api/buyer/flat/department',
    WARRANTY_LIST: '/api/buyer/master/warranty-list',
    SUPPLIER_CATEGORY_LIST: '/api/buyer/supplier/supplier-cat-list',
    FLAT_SUPPLIER_LIST: '/api/buyer/flat/supplier-list',
    UOM_LIST: '/api/buyer/master/uom-list',
    UOM_LIST_DROPDOWN: '/api/buyer/master/uom-list-dropdown',
    INTERNAL_ORDER_LIST: '/api/buyer/flat/internalorder-list',
    PART_NUMBER_LIST: '/api/buyer/flat/part-number-list',
    // ALL_USER_LIST: '/api/buyer/user/allUserList',
    ALL_USER_LIST: '/user/user-list',

    SUPPLIER_TC_LIST: '/api/buyer/documents/suppliers-terms-cond-list',
    // EVENT_TYPE: '/api/buyer/master/event-type-list',
    EVENT_TYPE: '/api/buyer/master/eventType',
    CURRENCY_LIST_DROPDOWN: '/api/buyer/master/currency-list-dropdown',
    DECIMAL_LIST: '/api/buyer/master/decimal-list',
    SEARCH_SUPPLIER_LIST: '/api/buyer/supplier/supplier-name-list',
    DISTINCT_SEARCH_SUPPLIER_LIST: '/api/buyer/supplier/distinct-supplier-name-list',
    SUPPLIER_BY_VENDOR_CODE: '/api/buyer/supplier/supplier-by-vendor-code',
    ADDRESS_LIST: '/api/buyer/master/address/drop/list',
    GET_PAYMENT_TERMS: '/api/buyer/master/paymentterms',
    GET_BANK_NAMES: '/api/buyer/master/banks',
    PURCHASE_ORG_LIST_DROP: '/api/buyer/master/purchase-org-list-drop',
    INDUTRY_CATEGORY_DROP: '/api/buyer/master/indutry-category-drop',
    VENDOR_ACCOUNT_DROP: '/api/buyer/master/vendor-account-drop',
    PAYMENT_METHOD_DROP: '/api/buyer/master/payment-drop',
    RECON_ACCOUNT_DROP: '/api/buyer/master/recon-account-drop',
    HOUSE_BANK_DROP: '/api/buyer/master/house-bank-drop',
};

export const USER = {
    ROLE_BY_DEPARTMENT: '/user/unique-user-role-data/', // ${departmentId}
    USERS_BY_DEPARTMENT: '/user/userListByDepartmentId', // departmentId
    ALL_USERS: '/user/all-users',
    UNIQUE_USER_ROLE: '/user/unique-user-role-data-tfls'
};

export const RFX_TEMPLATE = {
    CREATE_BUYER_RFX_TEMPLATE: '/api/buyer/rfx-template/add',
    GET_BUYER_RFX_TEMPLATE_BY_ID: '/api/buyer/rfx-template/get-by-id', // pass id in query param
    GET_BUYER_RFX_TEMPLATE: '/api/buyer/rfx-template/list',
    UPDATE_BUYER_RFX_TEMPLATE: '/api/buyer/rfx-template/update',
    DOWNLOAD_RFX_TEMPLATE:
        '/api/buyer/rfx-template/download-sourcing-template-format',
    EXPORT_RFX_TEMPLATE: '/api/buyer/rfx-template/export-rfx-template-list',
    IMPORT_RFX_TEMPLATE: '/api/buyer/rfx-template/import-sourcing-template',
    RFX_DROPDOWN_TEMPLATE: '/api/buyer/rfx-template/dropdown',
};

export const RFX_FORM = {
    CREATE_RFX: '/api/buyer/rfx-form/create',
    CREATE_NEGO: '/api/buyer/rfx-form/create-negotiation', // RFXID
    RFX_LIST: '/api/buyer/rfx-form/list',
    RFX_PENDING_BUYER_EVALUATION_LIST: '/api/buyer/buyer-dashboard/pendingBuyerEvaluationList',
    RFX_SOURCING_EVALUATION_LIST: '/api/buyer/buyer-dashboard/sourcingEvaluationList',
    RFX_SOURCING_PROPOSAL_LIST: '/api/buyer/buyer-dashboard/sourcingProposalList',
    RFX_PENDING_REQUESTOR_EVALUATION_LIST: '/api/buyer/buyer-dashboard/pendingRequestorEvaluationList',
    RFX_PENDING_PR_CREATION: '/api/buyer/buyer-dashboard/pendingPRCreationList',
    RFX_PENDING_SOURCING_EVENT_LIST: '/api/buyer/buyer-dashboard/dueSourcingList',
    RFX_RESUME_PENDING_SOURCING_EVENT_LIST: '/api/buyer/buyer-dashboard/dueResumeSourcingList',
    RFX_BY_ID: '/api/buyer/rfx-form/rfx',
    PRE_PR_AND_RFX_METADATA: '/api/buyer/rfx-form/rfx/data', // {templateId}/{pprId}
    RFX_LIST_SEARCH: '/api/buyer/rfx-form/rfx/list/search',
    RFX_LIST_EXPORT: '/api/buyer/rfx-form/export-rfx-form-list',
    UPDATE_RFX_FORM: '/api/buyer/rfx-form/update', // pass id in query param
    SUBMIT_FOR_APPROVAL: '/api/buyer/rfx-form/submit-for-approval',
    SUBMIT_NEGOTIATION: '/api/buyer/rfx-form/submit-negotiation',
    CHANGE_APPROVAL_STATUS: '/api/buyer/rfx-form/approval/change-status',
    RFX_APPROVAL_HISTORY: '/api/buyer/rfx-form/approval/list-history',
    UPDATE_APPROVAL_ROUTE: '/api/buyer/rfx-form/approval/update-route',
    UPDATE_PROPOSAL_ROUTE: '/api/buyer/rfx-form/proposal/update-route',
    // REJECT_PRE_PR: '/api/buyer/rfx-form/approval/reject-pre-pr',
    APPROVER_BACK: '/api/buyer/rfx-form/approval/go-back',
    DOWNLOAD_RFX_SUMMARY: '/api/buyer/prePr/rfx_summary',
    GET_QUALIFIED_SUPPLIER: '/api/buyer/buyer-response/get-qualified-suppliers',
    CONCLUDE_RFX: '/api/buyer/conclude-rfx/conclude-rfx',
    REFRESH_SOURCING_PROPOSAL: '/api/buyer/conclude-rfx/refresh-sourcing-proposal',
    GET_ALL_NEGOS: '/api/buyer/rfx-form/get-all-negos',
    CANCEL_RFX_REQUEST: '/api/buyer/rfx-form/cancel-rfx-request',
    FINISHED_RFX_LIST: '/api/buyer/rfx-form/repeat-purchase/get-rfx-list',
    REPEAT_PURCHASE: '/api/buyer/rfx-form/repeat-purchase/conclude',
    RFX_AUDIT_HISTORY: '/api/buyer/rfx-form/get-audit-history',

    UPDATE_RFX_SUMMARY_MEETING: '/api/buyer/rfx-form/meeting/update',
    RFX_ESCALATE: '/api/buyer/rfx-form/escalate',
    SAMPLE_RFX_BOQ: '/api/buyer/rfx-form/sample-boq-rfx',
    EXPORT_RFX_BOQ: '/api/buyer/rfx-form/export-boq-rfx',
    UPLOAD_RFX_BOQ: '/api/buyer/rfx-form/upload-boq-rfx'
};

export const ASSET_NUMBER_MASTER = {
    ASSET_NUMBER_LIST: '/api/buyer/master/asset-number-list',
    ASSET_NUMBER_LIST_BY_PURCHASE_ORG: '/api/buyer/master/asset-number-list/byPurchasingOrg/',
    ASSET_NUMBER_EXCEL: '/api/buyer/master/asset-number/exportExcel',
    ASSET_NUMBER_SYNC: '/api/buyer/master/asset-number-sync',
};

export const SAP_SERIES_MASTER = {
    SAP_SERIES_LIST: '/api/buyer/master/sap-series-list',
    SAP_SERIES_EXCEL: '/api/buyer/master/sap-series-list/exportExcel',
    SAP_SERIES: '/api/buyer/master/sap-series',
};

export const SAP_CLASS_MASTER = {
    SAP_CLASS_LIST: '/api/buyer/master/sap-class-list',
    SAP_CLASS_EXCEL: '/api/buyer/master/sap-class-list/exportExcel',
    SAP_CLASS: '/api/buyer/master/sap-class',
};

export const SAP_MODEL_MASTER = {
    SAP_MODEL_LIST: '/api/buyer/master/sap-model-list',
    SAP_MODEL_EXCEL: '/api/buyer/master/sap-model-list/exportExcel',
    SAP_MODEL: '/api/buyer/master/sap-model',
};

export const BUSINESS_AREA_LIST_DOC = {
    BUSINESS_AREA_LIST: '/api/buyer/master/business-area-list',
    BUSINESS_AREA_EXCEL: '/api/buyer/master/business-area-list/exportExcel',
    BUSINESS_AREA: '/api/buyer/master/business-area',
};

export const ADDRESS_LIST_DOC = {
    ADDRESS_LIST: '/api/buyer/master/address-list',
    ADDRESS_LIST_EXCEL: '/api/buyer/master/address-list/exportExcel',
    ADDRESS: '/api/buyer/master/address',
};

export const VENDOR_ACCOUNT_LIST_DOC = {
    VENDOR_ACCOUNT_GROUP_LIST: '/api/buyer/master/vendor-account-group-list',
    VENDOR_ACCOUNT_GROUP_EXCEL:
        '/api/buyer/master/vendor-account-group-list/exportExcel',
    VENDOR_ACCOUNT_GROUP: '/api/buyer/master/vendor-account-group',
};

export const INDUSTRY_CATEGORY_LIST_DOC = {
    INDUSTRY_CATEGORY_LIST: '/api/buyer/master/industry-category-list',
    INDUSTRY_CATEGORY_EXCEL: '/api/buyer/master/industry-category-list/exportExcel',
    INDUSTRY_CATEGORY: '/api/buyer/master/industry-category',
};

export const BANK_DOC = {
    BANK_LIST: '/api/buyer/master/bank-list',
    BANK_EXCEL: '/api/buyer/master/bank-list/exportExcel',
    BANK: '/api/buyer/master/bank',
};

export const COST_CENTER_DOC = {
    COST_CENTER_LIST: '/api/buyer/master/cost-center-list',
    COST_CENTER_EXCEL: '/api/buyer/master/cost-center-list/exportExcel',
    COST_CENTER_SYNC: '/api/buyer/master/cost-center-sync',
};

export const PLANT_LIST_DOC = {
    PLANT_LIST: '/api/buyer/master/plant-list',
    PLANT_EXPORT_EXCEL: '/api/buyer/master/plant/exportExcel',
    PLANT: '/api/buyer/master/plant',
};

export const WARRANTY_LIST_DOC = {
    WARRANTY_LIST: '/api/buyer/master/warranty-list',
    WARRANTY_EXPORT_EXCEL: '/api/buyer/master/warranty/exportExcel',
    WARRANTY: '/api/buyer/master/warranty',
};

export const PURCHASE_ORG_LIST_DOC = {
    PURCHASE_ORG: '/api/buyer/master/purchase-org-list',
    PURCHASE_ORG_EXPORT_EXCEL: '/api/buyer/master/purchase-org-list/exportExcel',
    PURCHASE_ORG_CREATE: '/api/buyer/master/purchase-org',
};

export const UOM_LIST_DOC = {
    UOM_LIST: '/api/buyer/master/uom-list',
    UOM_LIST_DROPDOWN: '/api/buyer/master/uom-list-dropdown',
    UOM_EXPORT_EXCEL: '/api/buyer/master/uom/exportExcel',
    UOM: '/api/buyer/master/uom',
};

export const RECON_ACCOUNT_LIST_DOC = {
    RECON_ACCOUNT_LIST: '/api/buyer/master/recon-account-list',
    RECON_ACCOUNT_EXPORT_EXCEL: '/api/buyer/master/recon-account/exportExcel',
    RECON_ACCOUNT: '/api/buyer/master/recon-account',
};

export const PAYMENT_METHOD_LIST_DOC = {
    PAYMENT_METHOD_LIST: '/api/buyer/master/payment-method-list',
    PAYMENT_METHOD_EXPORT_EXCEL: '/api/buyer/master/payment-method/exportExcel',
    PAYMENT_METHOD: '/api/buyer/master/payment-method',
};

export const HOUSE_BANK_LIST_DOC = {
    HOUSE_BANK_LIST: '/api/buyer/master/house-bank-list',
    HOUSE_BANK_EXPORT_EXCEL: '/api/buyer/master/house-bank/exportExcel',
    HOUSE_BANK: '/api/buyer/master/house-bank',
    HOUSE_BANK_LIST_DROP: '/api/buyer/master/house-bank-list-drop'
};

export const GLCODE_LIST_DOC = {
    GLCODE_LIST: '/api/buyer/master/gl-code-list',
    GLCODE_EXPORT_EXCEL: '/api/buyer/master/gl-code-list/exportExcel', //----
    GLCODE_SYNC: '/api/buyer/master/gl-code-sync',
};

export const INTERNAL_ORDER_LIST_DOC = {
    INTERNAL_ORDER_LIST: '/api/buyer/master/internal-order-list',
    INTERNAL_ORDER_LIST_DROPDOWN: '/api/buyer/master/internalOrderNumber-list-dropdown',
    INTERNAL_ORDER_EXPORT_EXCEL: '/api/buyer/master/internal-order-list/exportExcel',
    INTERNAL_ORDER_SYNC: '/api/buyer/master/internalOrderNumber-sync',
};

export const ACCOUNT_ASSIGNMENT_LIST_DOC = {
    ACCOUNT_ASSIGNMENT_LIST: '/api/buyer/master/account-assignment-code-list',
    ACCOUNT_ASSIGNMENT_EXPORT_EXCEL:
        '/api/buyer/master/account-assignment-code/exportExcel',
    ACCOUNT_ASSIGNMENT: '/api/buyer/master/account-assignment-code',
};

export const PROFIT_CENTER_LIST_DOC = {
    PROFIT_CENTER_LIST: '/api/buyer/master/profit-center-list',
    PROFIT_CENTER_LIST_BY_PURCHASE_ORG: '/api/buyer/master/profit-center-list/byPurchaseOrg',
    PROFIT_CENTER_EXPORT_EXCEL: '/api/buyer/master/profit-center-list/exportExcel', //----
    PROFIT_CENTER: '/api/buyer/master/profit-center',
};

export const PAYMENT_TERMS_LIST_DOC = {
    PAYMENT_TERMS_LIST: '/api/buyer/master/payment-terms-list',
    PAYMENT_TERMS_EXPORT_EXCEL: '/api/buyer/master/payment-terms-list/exportExcel', //----
    PAYMENT_TERMS: '/api/buyer/master/payment-terms',
};

export const CURRENCY_LIST_DOC = {
    CURRENCY_LIST: '/api/buyer/master/currency-list',
    CURRENCY_EXPORT_EXCEL: '/api/buyer/master/currency/exportExcel',
    CURRENCY: '/api/buyer/master/currency',
};

export const ID_SETTING_LIST_DOC = {
    ID_SETTING_LIST: '/api/buyer/master/id-setting-list',
    ID_SETTING: '/api/buyer/master/id-setting',
};

export const MASTER_SUPPORTING_DOC = {
    SUPPORTING_DOC_LIST: '/api/buyer/documents/supporting-doc-list',
    SUPPORTING_DOCS: '/api/buyer/documents/supporting-docs',
    SUPPORTING_DOCS_BY_ID: '/api/buyer/documents/supportingdocs',
};

export const MASTER_SUPPLIER_CATEGORY = {
    SUPPLIER_CATEGORY_LIST: '/api/buyer/supplier/supplier-category-list',
    SUPPLIER_CATEGORY_BY_ID: '/api/buyer/supplier/category-supplier-list',
    SUPPLIER_CATEGORY_CREATE: '/api/buyer/supplier/create-category-supplier',
    SUPPLIER_CATEGORY_UPDATE: '/api/buyer/supplier/supplier-category',
    SUPPLIER_CATEGORY_DROPDOWN: '/api/buyer/supplier/suppliersCategoryDrop',
    SUPPLIER_BY_CATEGORY_ID: '/api/buyer/supplier/supplierslist',
};


export const SOURCING_EVENT_PROGRESS = {
    SOURCING_PROGRESS:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/progress',
    SOURCING_EVALUTION:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/evaluation',
    SOURCING_EVALUTION_DETAILS_BY_RFXID:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/evaluation/details',
    SOURCING_EVALUTION_DOWNLOAD_BOQ:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/evaluation/boq',
    SOURCING_EVALUTION_DOWNLOAD_QUESTIONNAIRE:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/evaluation/questionnaire',
    SAVE_EVALUTION:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/evaluation/save',
    SUBMIT_EVALUTION:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/evaluation/submit/',
    FINISH_EVALUTION:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/evaluation/finish/',
    CHANGE_SUPPLIER_STATUS_BY_RFXID:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/evaluation/supplier',
    SOURCING_PROPOSAL_DETAILS:
        '/api/buyer/rfx-buyer-sourcing-proposal/proposal/details',
    APPROVE_REJECT_ENVELOP:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/evaluation/action',
    SAVE_DRAFT_OR_APPROVAL:
        '/api/buyer/rfx-buyer-sourcing-proposal/proposal/save',
    GET_RECOMANDATION_PROPOSAL: '/api/buyer/rfx-buyer-sourcing-proposal/award',
    GET_SOURCING_AWARD_BY_RFXID:
        '/api/buyer/rfx-buyer-sourcing-proposal/proposal/award',
    MANUAL_SUPPLIER_SELECTION:
        '/api/buyer/rfx-buyer-sourcing-proposal/proposal/award/manual',
    APPROVE_REJECT_PROPOSAL:
        '/api/buyer/rfx-buyer-sourcing-proposal/approvar/action',
    SUSPEND_EVENT:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/progress/suspend-event',
    CLOSE_EVENT:
        '/api/buyer/rfx-buyer-sourcing-progress-evaluation/progress/close-event',
    EXTEND_EVENT: '/api/buyer/rfx-buyer-sourcing-progress-evaluation/progress/extend-event',
    DOWNLOAD_PDF_SUMMARY: '/api/buyer/rfx-form/rfx-evaluation-report', // Detail pdf
    RFX_EVALUATION_SUMMARY_REPORT: '/api/buyer/rfx-form/rfx-evaluation-summary-report',
    DOWNLOAD_SOURCING_PROPOSAL_EXCEL: '/api/buyer/rfx-form/export-rfx-sourcing-proposal',
    DELETE_UNDO_EVALUATION_BOQ_ITEM: '/api/buyer/rfx-buyer-sourcing-progress-evaluation/boq/item',
    OPEN_ENVELOPE: '/api/buyer/rfx-buyer-sourcing-progress-evaluation/envelop/open',
    ENVELOP_APPROVAL_HISTORIES: '/api/buyer/rfx-buyer-sourcing-progress-evaluation/envelop/approval/histories'
};

export const SUPPLIER_RESPONSE = {
    GET_SUPPLIER_RESPONSE_MESSAGES:
        '/api/buyer/supplier-response/get-supplier-response-messages',
    BUYER_SEND_MESSAGE: '/api/buyer/supplier-response/buyer/send-one-message',
    SEND_MESSAGE_FROM_BUYER: '/api/buyer/supplier-response/send-message-from-buyer',
    GET_MESSAGE_HISTORY: '/api/buyer/supplier-response/get-message-history',
    UPDATE_ATTENDANCE: '/api/buyer/supplier-response/supplier/update-attendance',
    CONLCUDE_ATTENDANCE: '/api/buyer/supplier-response/buyer/conlcude-attendance'
};

export const PR = {
    PR_PLANT_LIST: '/api/buyer/master/plant',
    PR_PLANT_LIST_BY_PURCHASING_ORG: '/api/buyer/master/plant-by-purchasing-org',
    PR_ACCOUNT_ASSIGNMENT_LIST:
        '/api/buyer/master/account-assignment-code-list/byGLCode-PurchasingOrg',
    PR_GL_CODE_LIST: '/api/buyer/master/gl-code-list/byPurchaseOrg',
    PR_COST_CENTER_LIST: '/api/buyer/master/cost-center-list/byPurchaseOrg',
};

export const DOCUMENTS = {
    DOCUMENT_UPLOAD: '/api/buyer/documents/upload',
    DOCUMENT_DELETE: '/api/buyer/documents/file/delete',
    DOCUMENT_DOWNLOAD: '/api/buyer/documents/file/download',
    MULTIPLE_DOCUMENT_UPLOAD: '/api/buyer/documents/multi/upload'
};

export const EMAIL = {
    SERVER_SETTING: '/api/buyer/master/email-server-setting',
};

export const SUPPLIER = {
    GET_FIELDS_FOR_BUYER: '/api/buyer/supplier/profile/update/get-fields-for-buyer',
    SUPPLIER_PROFILE_BY_VENDOR: '/api/buyer/supplier/profile-by-vendor',
    PATCH_SAP_TABLE_BY_BUYER: '/api/buyer/supplier/profile/patch-sap-table-by-buyer',
    APPROVE_PROFILE: '/api/buyer/supplier/approve-profile',
    REJECT_PROFILE: '/api/buyer/supplier/reject-profile',
    SAP_VENDOR_CREATE: '/api/buyer/supplier/sap-vendor-create',
    SAP_VENDOR_UPDATE: '/api/buyer/supplier/sap-vendor-update',
    SUPPLIER_PROFILE_AUDIT_HISTORY: '/api/buyer/supplier/profile/get-history',
    UPDATE_PROFILE_BY_BUYER: '/api/buyer/supplier/update-profile-by-buyer',
    UPDATE_ACTIVE_PROFILE_BY_BUYER: '/api/buyer/supplier/update-active-profile-by-buyer',
    GET_LATEST_APPROVAL_ROUTE_BY_VENDOR: '/api/buyer/supplier/profile/get-latest-approval-route',
    TRIGGER_EMAIL_FOR_PROFILE_UPDATE: '/api/buyer/supplier/send-email/request-profile-update',
};

export const ANNOUNCEMENT = {
    ANNOUNCEMENT_DISTINCT_STATUS: '/api/buyer/announcement/distinct-status',
    ANNOUNCEMENT_SUPPLIER_MANUAL_DROPDOWN: '/api/buyer/supplier/distinct-supplier-name-list',
    ANNOUNCEMENT_SUPPLIER_TAG_DROPDOWN: '/api/buyer/announcement/active-supplier-Tag',

    ANNOUNCEMENT_LIST: '/api/buyer/announcement/announcement-list',
    ANNOUNCEMENT_EXPORT: '/api/buyer/announcement/export-buyer-announcement-list',
    ANNOUNCEMENT_UPLOAD_DOCS: '/api/buyer/announcement/upload-doc',
    // APPROVAL_MANAGEMENT_LIST_BY_PURCHASE_ORG: '/api/buyer/approval-management/approvers/list-by-org',
    APPROVAL_MANAGEMENT_LIST_BY_PURCHASE_ORG: '/api/buyer/approval-management/approvers-for-announcement/list-by-org',
    ANNOUNCEMENT_CREATE: '/api/buyer/announcement/create-announcement',
    ANNOUNCEMENT_UPDATE: '/api/buyer/announcement/update-announcement',
    ANNOUNCEMENT_SUMMARY: '/api/buyer/announcement/announcement-summary',

    ANNOUNCEMENT_SUMMARY_SUBMIT_FOR_APPROVAL: '/api/buyer/announcement/submit-for-approval',
    ANNOUNCEMENT_APPROVAL_ACCEPT: '/api/buyer/announcement/announcement-approval/update-route',

    ANNOUNCEMENT_COUNT_BY_PURCHASEORG: '/api/buyer/buyer-dashboard/announcement-count-by-purchaseOrg',
    ANNOUNCEMENT_COUNT_BY_PURCHASEORG_DUE: '/api/buyer/buyer-dashboard/pending-announcement-count-by-purchaseOrg',
    ANNOUNCEMENT_COUNT_BY_PURCHASEORG_PENDING: '/api/buyer/buyer-dashboard/announcement-pending-count-by-purchaseOrg',
    ANNOUNCEMENT_COUNT_BY_PURCHASEORG_DRAFT: '/api/buyer/buyer-dashboard/draft-announcement-count-by-purchaseOrg',
    ANNOUNCEMENT_COUNT_BY_PURCHASEORG_DRAFT_DASHBOARD: '/api/buyer/announcement/draft-announcement-count-by-purchasing-org',
    ANNOUNCEMENT_COUNT_BY_PURCHASEORG_PENDING_DASHBOARD: '/api/buyer/announcement/pending-announcement-count-by-purchasing-org',
    ANNOUNCEMENT_COUNT_BY_PURCHASEORG_DUE_DASHBOARD: '/api/buyer/buyer-dashboard/due-announcement-count-by-purchaseOrg',
    ANNOUNCEMENT_DOWNLOAD_PDF: '/api/buyer/announcement/buyer-Announcement-pdf',
    ANNOUNCEMENT_CANCEL: '/api/buyer/announcement/cancel-announcement-request',

    ANNOUNCEMENT_EXTEND:'/api/buyer/announcement/announcement-extend',
    ANNOUNCEMENT_DEACTIVATE:'/api/buyer/announcement/announcement-deactivate',
};

export const INVOICE_TWO_WAY = {
    APPROVE_INVOICE: '/api/buyer/supplier2way-invoice/approve-invoice',
    REJECT_INVOICE: '/api/buyer/supplier2way-invoice/reject-invoice',
    ESCALATE_INVOICE: '/api/buyer/supplier2way-invoice/escalate-invoice',
    DOCUMENT_UPLOAD: '/api/buyer/supplier2way-invoice/upload/doc',
    DOCUMENT_DELETE: '/api/buyer/supplier2way-invoice/delete/doc',
    INVOICE_COUNT_BY_PURCHASEORG_PENDING_DASHBOARD: '/api/buyer/Supplier3wayInvoice/pending-invoice-count-by-purchasing-org',
    INVOICE_COUNT_BY_PURCHASEORG_DUE_DASHBOARD: '/api/buyer/Supplier3wayInvoice/due-invoice-count-by-purchasing-org',
};


