import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { curentUserInfo } from 'app/helper/shared-function';
import {
    BaseCurrency
} from 'app/interfaces/base-currency';
import { CostCenter } from 'app/interfaces/cost-center';
import { DeliveryAddress } from 'app/interfaces/delivery-address';
import { Department } from 'app/interfaces/department';
import { AccountAssignment } from 'app/interfaces/gl-code-list';
import { PaginationResponse } from 'app/interfaces/pagination-response';
import { SupplierCategory } from 'app/interfaces/rfx-interface/rfx-common.interface';
import { SupplierTCList } from 'app/interfaces/rfx-interface/rfx-template.interface';
import { Supplier } from 'app/interfaces/supplier';
import { UOM } from 'app/interfaces/uom';
import { User } from 'app/interfaces/user-interface/user-interface';
import { Warranty } from 'app/interfaces/Warranty';
import moment from 'moment';
import { map, Observable } from 'rxjs';
import * as apiConstants from '../../../constants/api-constant';
import { CustomHttpService } from './../custom-http-service/custom-http.service';
import { PurchaseOrg } from 'app/interfaces/purchase-org';
import { BankList } from 'app/interfaces/bank-list';
import { PaymentTermsList } from 'app/interfaces/payment-terms-list';
import { IndustryCategoryList } from 'app/interfaces/industry-category-list';
import { VendorAccountGroupList } from 'app/interfaces/vendor-account-group-list';
import { PaymentMethod } from 'app/interfaces/payment-method';
import { ReconAccountList } from 'app/interfaces/recon-account-list';
import { HouseBankList } from 'app/interfaces/house-bank-list';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    markAsTouchMethods: Function[] = [];
    constructor(private customHttp: CustomHttpService) { }

    fetchAllActiveUserList(): Observable<any> {
        return this.customHttp
            .reactiveGet<any>(apiConstants.USER.ALL_USERS)
            .pipe(
                map((res: any) => {
                    res.map((element: User) => {
                        element.name = element.name;
                        element.userId = element.id;
                        return element;
                    })
                    return res;
                })
            );
    }

    fetchAllUserList(): Observable<any> {
        return this.customHttp
            .reactiveGet<any>(apiConstants.COMMON_ENDPOINT.ALL_USER_LIST)
            .pipe(
                map((res: any) => {
                    res.data.map((element: User) => {
                        element.name = element.name;
                        element.userId = element.id;
                        return element;
                    });

                    return res.data;
                })
            );
    }

    fetchCurrencyList(): Observable<PaginationResponse<BaseCurrency[]>> {
        return this.customHttp.reactiveGet<PaginationResponse<BaseCurrency[]>>(
            apiConstants.COMMON_ENDPOINT.CURRENCY_LIST
        );
    }

    fetchCurrencyListDropdown(): Observable<BaseCurrency[]> {
        return this.customHttp.reactiveGet<BaseCurrency[]>(
            apiConstants.COMMON_ENDPOINT.CURRENCY_LIST_DROPDOWN
        );
    }

    fetchaccountAssignmentList(): Observable<
        PaginationResponse<AccountAssignment[]>
    > {
        return this.customHttp.reactiveGet<
            PaginationResponse<AccountAssignment[]>
        >(apiConstants.COMMON_ENDPOINT.ACCOUNT_ASSIGNMENT_LIST);
    }

    fetchBudgetReferenceList(): Observable<PaginationResponse<any[]>> {
        return this.customHttp.reactiveGet<PaginationResponse<any[]>>(
            apiConstants.COMMON_ENDPOINT.BUDGET_REFERENCE_LIST
        );
    }

    fetchGlcodeList(): Observable<any> {
        return this.customHttp.reactiveGet<any>(
            apiConstants.COMMON_ENDPOINT.GL_CODE_LIST
        );
    }

    fetchWarrentyList(): Observable<PaginationResponse<Warranty[]>> {
        return this.customHttp.reactiveGet<PaginationResponse<Warranty[]>>(
            apiConstants.COMMON_ENDPOINT.WARRANTY_LIST
        );
    }

    fetchSupplierCategoryList(): Observable<SupplierCategory[]> {
        return this.customHttp.reactiveGet<SupplierCategory[]>(
            apiConstants.COMMON_ENDPOINT.SUPPLIER_CATEGORY_LIST
        );
    }

    fetchSupplierList(): Observable<Supplier[]> {
        return this.customHttp.reactiveGet<Supplier[]>(
            apiConstants.COMMON_ENDPOINT.FLAT_SUPPLIER_LIST
        );
    }

    fetchCostCentreList(status?: string): Observable<PaginationResponse<CostCenter[]>> {
        // const params = new HttpParams().set('status', status);
        return this.customHttp.reactiveGet<PaginationResponse<CostCenter[]>>(
            apiConstants.COMMON_ENDPOINT.COST_CENTER_LIST
        );
    }
    fetchCostCentreListDropdown(status?: string): Observable<PaginationResponse<CostCenter[]>> {
        // const params = new HttpParams().set('status', status);
        return this.customHttp.reactiveGet<PaginationResponse<CostCenter[]>>(
            apiConstants.COMMON_ENDPOINT.COST_CENTER_LIST_DROPDOWN
        );
    }

    // fetchDepartmentList(): Observable<Department[]> {
    //     return this.customHttp.reactiveGet<Department[]>(apiConstants.COMMON_ENDPOINT.DEPARTMENTOPTION_LIST)
    // }

    fetchDepartmentList(): Observable<Department[]> {
        const userId = curentUserInfo()?.id;
        return this.customHttp.reactiveGet<Department[]>(
            apiConstants.COMMON_ENDPOINT.DEPARTMENTOPTION_LIST
        );
    }

    fetchDepartmentListByPurchaseOrg(orgId: number): Observable<Department[]> {
        return this.customHttp.reactiveGet<Department[]>(
            apiConstants.COMMON_ENDPOINT.DEPARTMENT_LIST_BY_PURCHASE_ORG + orgId
        );
    }

    fetchSupplierTcList(
        moduleName: string = 'rfx'
    ): Observable<SupplierTCList[]> {
        const params = new HttpParams().set('module', moduleName);
        return this.customHttp.reactiveGet<SupplierTCList[]>(
            apiConstants.COMMON_ENDPOINT.SUPPLIER_TC_LIST,
            { params }
        );
    }

    fetchEventType(): Observable<{ id: number; name: string }[]> {
        return this.customHttp.reactiveGet<{ id: number; name: string }[]>(
            apiConstants.COMMON_ENDPOINT.EVENT_TYPE
        );
    }

    fetchUomList(): Observable<{ total: number; data: UOM[] }> {
        return this.customHttp.reactiveGet<{ total: number; data: UOM[] }>(
            apiConstants.COMMON_ENDPOINT.UOM_LIST
        );
    }
    fetchUomListDropdown(): Observable<{ total: number; data: UOM[] }> {
        return this.customHttp.reactiveGet<{ total: number; data: UOM[] }>(
            apiConstants.COMMON_ENDPOINT.UOM_LIST_DROPDOWN
        );
    }



    // fetchDeliveryAddress(): Observable<PaginationResponse<DeliveryAddress[]>> {
    //     return this.customHttp.reactiveGet<PaginationResponse<DeliveryAddress[]>>(apiConstants.COMMON_ENDPOINT.DELIVERY_ADDRESS_LIST);
    // }

    fetchDeliveryAddress(): Observable<DeliveryAddress[]> {
        return this.customHttp.reactiveGet<DeliveryAddress[]>(apiConstants.COMMON_ENDPOINT.ADDRESS_LIST);
    }

    fetchDecimalsList(): Observable<any> {
        const params = new HttpParams().set('status', 'Active');
        return this.customHttp.reactiveGet<PaginationResponse<any>>(
            apiConstants.COMMON_ENDPOINT.DECIMAL_LIST,
            { params }
        );
    }

    utcToLocal(utcDate: string, format: string): string {
        // convert utc to local
        const stillUtc = moment.utc(utcDate).toDate();
        const local = moment(stillUtc).local().format(format);
        return local;
    }

    getBankNames(): Observable<Array<BankList>> {
        return this.customHttp.reactiveGet(apiConstants.COMMON_ENDPOINT.GET_BANK_NAMES);
    }

    getPaymentTerms(): Observable<Array<PaymentTermsList>> {
        return this.customHttp.reactiveGet(apiConstants.COMMON_ENDPOINT.GET_PAYMENT_TERMS);
    }

    getPurchaseOrgForDD(): Observable<Array<PurchaseOrg>> {
        return this.customHttp.reactiveGet<Array<PurchaseOrg>>(apiConstants.COMMON_ENDPOINT.PURCHASE_ORG_LIST_DROP);
    }

    getIndustryCategoryForDD(): Observable<Array<IndustryCategoryList>> {
        return this.customHttp.reactiveGet(apiConstants.COMMON_ENDPOINT.INDUTRY_CATEGORY_DROP);
    }

    getVendorAccountGroupForDD(): Observable<Array<VendorAccountGroupList>> {
        return this.customHttp.reactiveGet(apiConstants.COMMON_ENDPOINT.VENDOR_ACCOUNT_DROP);
    }

    getPaymentMethodForDD(): Observable<Array<PaymentMethod>> {
        return this.customHttp.reactiveGet(apiConstants.COMMON_ENDPOINT.PAYMENT_METHOD_DROP);
    }

    getReconAccountForDD(): Observable<Array<ReconAccountList>> {
        return this.customHttp.reactiveGet(apiConstants.COMMON_ENDPOINT.RECON_ACCOUNT_DROP);
    }

    getHouseBankForDD(): Observable<Array<HouseBankList>> {
        return this.customHttp.reactiveGet<Array<HouseBankList>>(apiConstants.HOUSE_BANK_LIST_DOC.HOUSE_BANK_LIST_DROP);
    }
}
