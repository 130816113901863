<mat-form-field class="fuse-mat-dense w-full multi-select-container " floatLabel="always">
    <mat-label [ngClass]="{'danger' : (control?.touched && control?.errors) || required }" *ngIf="fieldLabel">
        {{ fieldLabel }}
    </mat-label>
    <mat-select [errorStateMatcher]="stateMatcher" [placeholder]="placeholder" [(ngModel)]='selectedResults'
        (blur)="onTouched()" [disabled]="isFieldDisabled || disableField"
        (selectionChange)="checkboxHandler($event.value)" [required]="isFieldRequired || required" [multiple]="true"
        (openedChange)="pannelToggle($event)" (closed)="closedMatSelect()">

        <div class="selection-search-field" *ngIf="useSearch" >
            <mat-option>
                <ngx-mat-select-search (input)="searchHandler($event.target.value)"
                    [placeholderLabel]="searchPlaceHolder ? searchPlaceHolder : placeholder || '' "
                    noEntriesFoundLabel="No data found">
                    <mat-icon (click)="matSearchClear()" ngxMatSelectSearchClear>close</mat-icon>
                </ngx-mat-select-search>
            </mat-option>
        </div>


        <mat-option *ngFor="let base of filteredOptions" [value]="base[optionViewKey]" [disabled]="base?.isDisabled">
            <ng-container *ngIf="useDefaultCasing">
                {{ base[optionViewKey] }}
            </ng-container>
            <ng-container *ngIf="!useDefaultCasing">
                {{ useUppercase ? (base[optionViewKey] | uppercase) : (base[optionViewKey] | titlecase) }}
            </ng-container>
        </mat-option>

    </mat-select>
</mat-form-field>