<div class="input-field-container">
    <mat-form-field class="fuse-mat-dense w-full" floatLabel="always" appearance="fill">
        <mat-label [ngClass]="{'danger' : (control?.touched || control?.dirty) && control?.errors}" *ngIf="fieldLabel">
            {{ fieldLabel }}
        </mat-label>
        <input matInput [placeholder]="placeholder" type="number"  onkeydown="return event.keyCode !== 190" 
            (input)="inputHandler($event.target.value)" [required]="isFieldRequird" [disabled]="isFieldDisabled"
            [errorStateMatcher]="stateMatcher" [ngModel]="initialValue" (blur)="onTouched()" onInput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />

    </mat-form-field>
    <app-error-message *ngIf="control?.control?.errors" [control]="control?.control"></app-error-message>
</div>