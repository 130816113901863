import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    RfxTemplateDropdown,
    RfxTemplateListing,
} from 'app/interfaces/rfx-interface/rfx-dashboard.interface';
import * as apiConstants from '../../../constants/api-constant';
import { RfxTemplateFormInterface } from 'app/interfaces/rfx-interface/rfx-template.interface';
import {
    DeleteSupportingDoc,
    RfxForm,
    SupportingDocument,
} from 'app/interfaces/rfx-interface/rfx-form.interface';
import { PaginationResponse } from 'app/interfaces/pagination-response';
import { CustomHttpService } from '../../common/custom-http-service/custom-http.service';
import { DownloadService } from '../../common/download.service';
import { GlobalVariable } from 'app/config';
import { environment } from 'environments/environment';
import {
    RfxListColumnFilter,
    RfxListGlobalFilter,
} from 'app/interfaces/rfx-interface/rfx-list-filter';
import { ProjectService } from 'app/modules/admin/dashboards/project/project.service';
import { USER_MODULE, USER_ROLE_ACCESS_MODULE_NAMES } from 'app/enums/common-enum/common-enum';

@Injectable({
    providedIn: 'root',
})
export class RfxTemplateService {
    constructor(
        private customService: CustomHttpService,
        private projectService: ProjectService,
        private downloadService: DownloadService
    ) {}

    createRfxTemplateForm(
        templateForm: any
    ): Observable<RfxTemplateFormInterface> {
        return this.customService.reactivePost<RfxTemplateFormInterface>(
            apiConstants.RFX_TEMPLATE.CREATE_BUYER_RFX_TEMPLATE,
            templateForm
        );
    }

    getRfxBuyerTemplate(
        page: number,
        size: number,
        keyword?: string
    ): Observable<RfxTemplateListing> {
        let params = new HttpParams().set('page', page).set('size', size);
        if (keyword && keyword !== '') {
            params = new HttpParams()
                .set('page', page)
                .set('size', size)
                .set('keyword', keyword);
        }
        return this.customService.reactiveGet<RfxTemplateListing>(
            apiConstants.RFX_TEMPLATE.GET_BUYER_RFX_TEMPLATE,
            { params }
        );
    }

    getRfxBuyerTemplateById(id: number): Observable<any> {
        const params = new HttpParams().set('id', id);
        return this.customService.reactiveGet(
            apiConstants.RFX_TEMPLATE.GET_BUYER_RFX_TEMPLATE_BY_ID,
            { params }
        );
    }

    getRfxTemplateDropdown(
        eventType?: string
    ): Observable<RfxTemplateDropdown[]> {
        let params = new HttpParams();
        if (eventType) {
            params = params.append('eventType', eventType);
        }
        return this.customService.reactiveGet<RfxTemplateDropdown[]>(
            apiConstants.RFX_TEMPLATE.RFX_DROPDOWN_TEMPLATE,
            { params }
        );
    }

    updateRfxTemplateForm(
        templateForm: any
    ): Observable<RfxTemplateFormInterface> {
        return this.customService.reactivePatch<RfxTemplateFormInterface>(
            apiConstants.RFX_TEMPLATE.UPDATE_BUYER_RFX_TEMPLATE,
            templateForm
        );
    }

    downloadRFxTemplate(keyword: string): void {
        this.downloadService.downloadExcelByUrl(
            `${GlobalVariable.BASE_URL}${apiConstants.RFX_TEMPLATE.DOWNLOAD_RFX_TEMPLATE}?keyword=${keyword}`,
            'Sourcing-Template-List'
        );
    }

    exportRFxTemplate(page: number, size: number, keyword: string): void {
        this.downloadService.downloadExcelByUrl(
            `${GlobalVariable.BASE_URL}${apiConstants.RFX_TEMPLATE.EXPORT_RFX_TEMPLATE}?keyword=${keyword}&page=${page}&size=${size}`,
            'Sourcing-Template-List'
        );
    }

    importSourcingTemplate(file: any): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.customService.reactivePost(
            apiConstants.RFX_TEMPLATE.IMPORT_RFX_TEMPLATE,
            formData,
            { headers: { accept: '*/*' } }
        );
    }

    createRfx(rfxData): Observable<any> {
        return this.customService.reactivePost<any>(
            apiConstants.RFX_FORM.CREATE_RFX,
            rfxData
        );
    }

    getRfxListing(
        filter: RfxListGlobalFilter,
        columnFilters: RfxListColumnFilter
    ): Observable<PaginationResponse<Array<RfxForm>>> {
        let params = new HttpParams()
            .set('page', filter.pageIndex)
            .set('size', filter.pageSize);
        if (filter.keyword && filter.keyword !== '') {
            params = params.set('keyword', filter.keyword);
        }
        if (
            filter.fromDate &&
            filter.fromDate !== '' &&
            filter.toDate &&
            filter.toDate !== ''
        ) {
            params = params.set('fromDate', filter.fromDate);
            params = params.set('toDate', filter.toDate);
        }
        if (
            filter.sortBy &&
            filter.sortBy !== '' &&
            filter.sortOrder &&
            filter.sortOrder !== ''
        ) {
            params = params.set('sortBy', filter.sortBy);
            params = params.set('sortOrder', filter.sortOrder.toUpperCase());
        }

        Object.keys(columnFilters).forEach((key) => {
            if (key === 'status' || key === 'eventType') {
                params = params.append(
                    `fq=rfx.${key}`,
                    columnFilters[key].join(',')
                );
            } else if (key === 'purchasingOrg') {
                params = params.append('fq=purchOrg.name', columnFilters[key]);
            } else if (key === 'buyer') {
                params = params.append('fq=creator.name', columnFilters[key]);
            } else if (key === 'prePrSequenceId') {
                params = params.append(
                    'fq=prePr.prePrSequenceId',
                    columnFilters[key]
                );
            } else if (key === 'isDue') {
                if (columnFilters[key] === true) {
                    params = params.append('isDue', columnFilters[key]);
                }
            } else if (key === 'isDuePendingBuyerEvaluation') {
                if (columnFilters[key] === true) {
                    params = params.append('isDuePendingBuyerEvaluation', columnFilters[key]);
                }
            } else if (key === 'isDuePendingRequestorEvaluation') {
                if (columnFilters[key] === true) {
                    params = params.append('isDuePendingRequestorEvaluation', columnFilters[key]);
                }
            } else if (key === 'isPendingPRCreation') {
                if (columnFilters[key] === true) {
                    params = params.append(
                        'isPendingPRCreation',
                        columnFilters[key]
                    );
                }
            } else if (key === 'isEventSuspended') {
                params = params.append('isEventSuspended', columnFilters[key]);
            } else if (key === 'isSourcingEventDraft') {
                params = params.append(
                    'isSourcingEventDraft',
                    columnFilters[key]
                );
            } else if (key === 'isSourcingProposalDraft') {
                params = params.append(
                    'isSourcingProposalDraft',
                    columnFilters[key]
                );
            } else if (key === 'isPendingSourcingEvaluation') {
                params = params.append(
                    'isPendingSourcingEvaluation',
                    columnFilters[key]
                );
            } else if (key === 'sourcingEvent') {
                params = params.append('sourcingEvent', columnFilters[key]);
            } else if (key === 'isRequestSummary') {
                params = params.append('isRequestSummary', columnFilters[key]);
            } else if (key === 'isFinishedSourcingProposal') {
                params = params.append(
                    'isFinishedSourcingProposal',
                    columnFilters[key]
                );
            } else if (key === 'isPendingNegoCreation') {
                params = params.append(
                    'isPendingNegoCreation',
                    columnFilters[key]
                );
            } else if (key === 'isSourcingDraftPending') {
                params = params.append(
                    'isSourcingDraftPending',
                    columnFilters[key]
                );
            } else if (key === 'isActiveSourcing') {
                params = params.append('isActiveSourcing', columnFilters[key]);
            } else if (key === 'isPendingEvalutions') {
                params = params.append(
                    'isPendingEvalutions',
                    columnFilters[key]
                );
            } else if (key === 'isSourcingProposals') {
                params = params.append(
                    'isSourcingProposals',
                    columnFilters[key]
                );
            } else if (key === 'isSourcingProposal') {
                params = params.append(
                    'isSourcingProposal',
                    columnFilters[key]
                );
            } else {
                params = params.append(`fq=rfx.${key}`, columnFilters[key]);
            }
        });

        let access : USER_ROLE_ACCESS_MODULE_NAMES[] = this.projectService.getUserRoleModuleWise(USER_MODULE.SOURCING);
        if (access.length) {
            params = params.set(this.projectService.userAccessGetPermissionIsName(access), true);
        }

        return this.customService.reactiveGet<
            PaginationResponse<Array<RfxForm>>
        >(apiConstants.RFX_FORM.RFX_LIST, { params });
    }

    getRfxList(
        filter: RfxListGlobalFilter,
        columnFilters: RfxListColumnFilter,
        type?: string
    ): Observable<PaginationResponse<Array<RfxForm>>> {
        let params = new HttpParams()
            .set('page', filter.pageIndex)
            .set('size', filter.pageSize);
        if (filter.keyword && filter.keyword !== '') {
            params = params.set('keyword', filter.keyword);
        }
        if (
            filter.fromDate &&
            filter.fromDate !== '' &&
            filter.toDate &&
            filter.toDate !== ''
        ) {
            params = params.set('fromDate', filter.fromDate);
            params = params.set('toDate', filter.toDate);
        }

        Object.keys(columnFilters).forEach((key) => {
            if (key === 'status' || key === 'eventType') {
                params = params.append(
                    `fq=rfx.${key}`,
                    columnFilters[key].join(',')
                );
            } else if (key === 'purchasingOrg') {
                params = params.append('fq=purchOrg.name', columnFilters[key]);
            } else if (key === 'buyer') {
                params = params.append('fq=creator.name', columnFilters[key]);
            } else {
                params = params.append(`fq=rfx.${key}`, columnFilters[key]);
            }
        });
        if (type && type === 'Resume Sourcing Event') {
            return this.customService.reactiveGet<
                PaginationResponse<Array<RfxForm>>
            >(apiConstants.RFX_FORM.RFX_RESUME_PENDING_SOURCING_EVENT_LIST, {
                params,
            });
        }
        if (columnFilters.status[0] === 'Pending Evaluation Approval') {
            return this.customService.reactiveGet<
                PaginationResponse<Array<RfxForm>>
            >(apiConstants.RFX_FORM.RFX_SOURCING_EVALUATION_LIST, { params });
        } else if (columnFilters.status[0] === 'Sourcing Proposal Pending') {
            return this.customService.reactiveGet<
                PaginationResponse<Array<RfxForm>>
            >(apiConstants.RFX_FORM.RFX_SOURCING_PROPOSAL_LIST, { params });
        } else if (columnFilters.status[0] === 'Pending Buyer Evaluation') {
            return this.customService.reactiveGet<
                PaginationResponse<Array<RfxForm>>
            >(apiConstants.RFX_FORM.RFX_PENDING_BUYER_EVALUATION_LIST, {
                params,
            });
        } else if (columnFilters.status[0] === 'Pending Requestor Evaluation') {
            return this.customService.reactiveGet<
                PaginationResponse<Array<RfxForm>>
            >(apiConstants.RFX_FORM.RFX_PENDING_REQUESTOR_EVALUATION_LIST, {
                params,
            });
        } else if (columnFilters.status[0] === 'Pending') {
            return this.customService.reactiveGet<
                PaginationResponse<Array<RfxForm>>
            >(apiConstants.RFX_FORM.RFX_PENDING_SOURCING_EVENT_LIST, {
                params,
            });
        } else if (columnFilters.status[0] === 'Finished') {
            return this.customService.reactiveGet<
                PaginationResponse<Array<RfxForm>>
            >(apiConstants.RFX_FORM.RFX_PENDING_PR_CREATION, { params });
        } else {
            return this.customService.reactiveGet<
                PaginationResponse<Array<RfxForm>>
            >(apiConstants.RFX_FORM.RFX_LIST, { params });
        }
    }

    getRfxListingSearch(
        filter: { pageIndex: number; pageSize: number; keyword?: string },
        columnFilter: any
    ): Observable<PaginationResponse<Array<RfxForm>>> {
        let params = new HttpParams()
            .set('page', filter.pageIndex)
            .set('size', filter.pageSize);
        if (filter.keyword && filter.keyword !== '') {
            params = params.set('keyword', filter.keyword);
        }

        Object.keys(columnFilter).forEach((key) => {
            if (columnFilter[key] && columnFilter[key] !== '') {
                params = params.set(key, columnFilter[key]);
            }
        });
        return this.customService.reactiveGet<
            PaginationResponse<Array<RfxForm>>
        >(apiConstants.RFX_FORM.RFX_LIST_SEARCH, { params });
    }

    downloadExcelRFxList(
        filter: RfxListGlobalFilter,
        columnFilters: RfxListColumnFilter
    ): void {
        let url = environment.URL + apiConstants.RFX_FORM.RFX_LIST_EXPORT;
        url = url.concat(
            `${url.includes('?') ? '&' : '?'}page=${filter.pageIndex}`
        );
        url = url.concat(
            `${url.includes('?') ? '&' : '?'}size=${filter.pageSize}`
        );
        if (filter.keyword && filter.keyword !== '') {
            url = url.concat(
                `${url.includes('?') ? '&' : '?'}keyword=${filter.keyword}`
            );
        }
        if (
            filter.fromDate &&
            filter.fromDate !== '' &&
            filter.toDate &&
            filter.toDate !== ''
        ) {
            url = url.concat(
                `${url.includes('?') ? '&' : '?'}fromDate=${filter.fromDate}`
            );
            url = url.concat(
                `${url.includes('?') ? '&' : '?'}toDate=${filter.toDate}`
            );
        }
        if (
            filter.sortBy &&
            filter.sortBy !== '' &&
            filter.sortOrder &&
            filter.sortOrder !== ''
        ) {
            url = url.concat(
                `${url.includes('?') ? '&' : '?'}sortBy=${filter.sortBy}`
            );
            url = url.concat(
                `${
                    url.includes('?') ? '&' : '?'
                }sortOrder=${filter.sortOrder.toUpperCase()}`
            );
        }

        Object.keys(columnFilters).forEach((key) => {
            if (key === 'status' || key === 'eventType') {
                url = url.concat(
                    `${
                        url.includes('?') ? '&' : '?'
                    }fq=rfx.${key}=${columnFilters[key].join(',')}`
                );
            } else if (key === 'purchasingOrg') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}fq=purchOrg.name=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'buyer') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}fq=creator.name=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'prePrSequenceId') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}fq=prePr.prePrSequenceId=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isDue') {
                if (columnFilters[key] === true) {
                    url = url.concat(
                        `${url.includes('?') ? '&' : '?'}isDue=${
                            columnFilters[key]
                        }`
                    );
                }
            } else if (key === 'isDuePendingBuyerEvaluation') {
                if (columnFilters[key] === true) {
                    url = url.concat(
                        `${url.includes('?') ? '&' : '?'}isDuePendingBuyerEvaluation=${
                            columnFilters[key]
                        }`
                    );
                }
            } else if (key === 'isDuePendingRequestorEvaluation') {
                if (columnFilters[key] === true) {
                    url = url.concat(
                        `${url.includes('?') ? '&' : '?'}isDuePendingRequestorEvaluation=${
                            columnFilters[key]
                        }`
                    );
                }
            } else if (key === 'isPendingPRCreation') {
                if (columnFilters[key] === true) {
                    url = url.concat(
                        `${url.includes('?') ? '&' : '?'}isPendingPRCreation=${
                            columnFilters[key]
                        }`
                    );
                }
            } else if (key === 'isEventSuspended') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isEventSuspended=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isSourcingEventDraft') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isSourcingEventDraft=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isSourcingProposalDraft') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isSourcingProposalDraft=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isPendingSourcingEvaluation') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isPendingSourcingEvaluation=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'sourcingEvent') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}sourcingEvent=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isRequestSummary') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isRequestSummary=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isFinishedSourcingProposal') {
                url = url.concat(
                    `${
                        url.includes('?') ? '&' : '?'
                    }isFinishedSourcingProposal=${columnFilters[key]}`
                );
            } else if (key === 'isPendingNegoCreation') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isPendingNegoCreation=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isSourcingDraftPending') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isSourcingDraftPending=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isActiveSourcing') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isActiveSourcing=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isPendingEvalutions') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isPendingEvalutions=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isSourcingProposals') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isSourcingProposals=${
                        columnFilters[key]
                    }`
                );
            } else if (key === 'isSourcingProposal') {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}isSourcingProposal=${
                        columnFilters[key]
                    }`
                );
            } else {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}fq=rfx.${key}=${
                        columnFilters[key]
                    }`
                );
            }
        });

        let access = this.projectService.getUserRoleModuleWise(USER_MODULE.SOURCING);
        if (access.length) {
            url = url.concat(`${url.includes('?') ? '&' : '?'}${this.projectService.userAccessGetPermissionIsName(access)}=true`);
        }
        return this.downloadService.downloadExcelByUrl(url, 'SourcingList');
    }

    downloadExcelRFxList1(filter: {
        pageIndex: number;
        pageSize: number;
        keyword?: string;
        fromDate?: string;
        toDate?: string;
    }): void {
        let url = environment.URL + apiConstants.RFX_FORM.RFX_LIST_EXPORT;

        Object.keys(filter).forEach((key) => {
            if (filter[key]) {
                if (key === 'pageIndex') {
                    url = url.concat(
                        `${url.includes('?') ? '&' : '?'}page=${filter[key]}`
                    );
                } else if (key === 'pageSize') {
                    url = url.concat(
                        `${url.includes('?') ? '&' : '?'}size=${filter[key]}`
                    );
                } else {
                    url = url.concat(
                        `${url.includes('?') ? '&' : '?'}${key}=${filter[key]}`
                    );
                }
            }
        });

        this.downloadService.downloadExcelByUrl(url, 'SourcingList');
    }

    // RFx Form apis
    uploadDocs(file: FormData): Observable<SupportingDocument> {
        return this.customService.reactivePost<SupportingDocument>(apiConstants.DOCUMENTS.DOCUMENT_UPLOAD, file);
    }

    // deleteDocs(fileInfo): Observable<DeleteSupportingDoc> {
    //     return this.customService.reactivePost<DeleteSupportingDoc>(apiConstants.DOCUMENTS.DOCUMENT_DELETE, fileInfo);
    // }
}
