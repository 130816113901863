<form [formGroup]="escalateForm" (ngSubmit)="onSubmit()">
    <div class="m-6">
      <mat-form-field class="fuse-mat-dense w-full">
        <mat-label class="font-bold text-black mb-4">Escalation Reason</mat-label>
        <textarea matInput placeholder="Reason" autocomplete="off" rows="3" formControlName="remark"
            [maxLength]="maxRemarkLength"></textarea>
        <mat-hint>{{ maxRemarkLength - escalateForm.controls.remark.value.length }} characters remaining ({{maxRemarkLength}} maximum)</mat-hint>
      </mat-form-field>
    </div>

    <div class="px-6 py-3 flex items-center justify-end mt-5 bg-gray-100">
      <button type="button" mat-raised-button class="bg-[#D9D9D9] rounded" mat-dialog-close>Cancel</button>
      <button class="mat-primary ml-4 rounded" mat-raised-button>Submit</button>
    </div>
  </form>

