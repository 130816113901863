import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiUrls } from 'app/config';
import { AuthService } from 'app/core/auth/auth.service';
import {
    ACCOUNT_ASSIGNMENT_LIST_DOC,
    ADDRESS_LIST_DOC,
    ASSET_NUMBER_MASTER,
    BANK_DOC,
    BUSINESS_AREA_LIST_DOC,
    COST_CENTER_DOC,
    CURRENCY_LIST_DOC,
    GLCODE_LIST_DOC,
    HOUSE_BANK_LIST_DOC,
    INDUSTRY_CATEGORY_LIST_DOC,
    INTERNAL_ORDER_LIST_DOC,
    PAYMENT_METHOD_LIST_DOC,
    PAYMENT_TERMS_LIST_DOC,
    PLANT_LIST_DOC,
    PROFIT_CENTER_LIST_DOC,
    PURCHASE_ORG_LIST_DOC,
    RECON_ACCOUNT_LIST_DOC,
    SAP_CLASS_MASTER,
    SAP_MODEL_MASTER,
    SAP_SERIES_MASTER,
    SOURCING_EVENT_PROGRESS,
    UOM_LIST_DOC,
    VENDOR_ACCOUNT_LIST_DOC,
    WARRANTY_LIST_DOC
} from 'app/core/constants/api-constant';
import { downloadAndSaveFile } from 'app/helper/shared-function';
import { PdfViewerInput } from 'app/interfaces/common-interface/common-interface';
import { environment } from 'environments/environment';
import moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { DateTimeUtilService } from './date-time-util.service';
import { PdfViewerComponent } from 'app/shared/pdf-viewer/pdf-viewer.component';

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private dateTimeUtil: DateTimeUtilService,
        private dialog: MatDialog
    ) { }

    downloadExcelByUrl(url: string, fileName: string): void {
        const offset = moment().utcOffset();
        const urlArry = url.split('?');
        if (typeof urlArry[1] === 'undefined') {
            this.downloadExcel(`${url}?offset=${offset}`, fileName, null);
        } else {
            this.downloadExcel(`${url}&offset=${offset}`, fileName, null);
        }
    }

    downloadExcel(url: string, fileName: string, body: any, onlyFileName?: string): void {
        this.callDownloadAPI(
            url,
            fileName,
            body,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            onlyFileName
        );
    }

    downloadPdf(url: string, fileName: string, body: any): void {
        this.callDownloadAPI(url, fileName, body, 'application/pdf');
    }

    callDownloadAPI(
        url: string,
        fileName: string,
        body: any,
        type?: string,
        onlyFileName?: string,
        openPdfViewer?: boolean,
    ): void {
        const headers = new HttpHeaders().set(
            'authorization',
            'Bearer ' + this.authService.accessToken
        );

        this.http
            .post(url, body, {
                headers,
                responseType: 'blob' as 'text',
            })
            .subscribe((response: any) => {
                const binaryData = [response];
                const fileUrl = window.URL.createObjectURL(
                    new Blob(binaryData, {
                        type: type,
                    })
                );
                fileName = onlyFileName ? onlyFileName : `${fileName} - ${this.dateTimeUtil.getDateForFileName(new Date())}`;
                if (type === 'application/pdf' && openPdfViewer) {
                    this.openPdfViewer({ pdfSrc: fileUrl, downloadedFileName: fileName });
                } else {
                    downloadAndSaveFile(fileUrl, fileName);
                }
                // hideLoader;
            });
    }

    callDownloadGetAPI(
        url: string,
        fileName: string,
        type?: string,
        eventEmitter?: EventEmitter<any>,
        onlyFileName?: string
    ): void {
        const headers = new HttpHeaders().set(
            'authorization',
            'Bearer ' + this.authService.accessToken
        );

        this.http
            .get(url, {
                headers,
                responseType: 'blob' as 'text',
            })
            .subscribe(
                (data) => {
                    const blob = new Blob([data], { type: type });
                    fileName = onlyFileName ? onlyFileName : `${fileName} - ${this.dateTimeUtil.getDateForFileName(new Date())}`;
                    downloadAndSaveFile(window.URL.createObjectURL(blob), fileName);
                    if (eventEmitter) {
                        eventEmitter.emit();
                    }
                },
                (error) => {
                    console.log(error.error.message);
                    if (eventEmitter) {
                        eventEmitter.emit();
                    }
                }
            );
    }

    downloadPrList(param: object, type: string): Promise<HttpResponse<Blob>> {
        let url = `${ApiUrls.DOWNLOAD_PR_LIST_EXCEL}`;
        if (type === 'pdf') {
            url = `${ApiUrls.DOWNLOAD_PR_LIST_PDF}`;
        }

        Object.keys(param).forEach((key) => {
            if (param[key]) {
                url = url.concat(
                    `${url.includes('?') ? '&' : '?'}${key}=${param[key]}`
                );
            }
        });

        return lastValueFrom(
            this.http.post(url, null, {
                responseType: 'blob',
                observe: 'response',
            })
        );
    }

    exportPrErrorReport(param: object): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(ApiUrls.EXPORT_PR_ERROR_REPORT, param, {
                responseType: 'blob',
                observe: 'response',
            })
        );
    }

    downloadAssetNumberList(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + ASSET_NUMBER_MASTER.ASSET_NUMBER_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadSAPSeriesList(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + SAP_SERIES_MASTER.SAP_SERIES_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadSAPClassList(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + SAP_CLASS_MASTER.SAP_CLASS_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadSAPModelList(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + SAP_MODEL_MASTER.SAP_MODEL_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadBusinessAreaList(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + BUSINESS_AREA_LIST_DOC.BUSINESS_AREA_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadAddressList(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + ADDRESS_LIST_DOC.ADDRESS_LIST_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadVendorAccountGroupListReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL +
                VENDOR_ACCOUNT_LIST_DOC.VENDOR_ACCOUNT_GROUP_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadIndustryCategoryListReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL +
                INDUSTRY_CATEGORY_LIST_DOC.INDUSTRY_CATEGORY_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadBankReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + BANK_DOC.BANK_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadCostCenterReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + COST_CENTER_DOC.COST_CENTER_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadPlantExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + PLANT_LIST_DOC.PLANT_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadWarrantyExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + WARRANTY_LIST_DOC.WARRANTY_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadPurchaseOrgExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL +
                PURCHASE_ORG_LIST_DOC.PURCHASE_ORG_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadUOMExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + UOM_LIST_DOC.UOM_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadReconAccountExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL +
                RECON_ACCOUNT_LIST_DOC.RECON_ACCOUNT_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadPaymentMethodExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL +
                PAYMENT_METHOD_LIST_DOC.PAYMENT_METHOD_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadHouseBankExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + HOUSE_BANK_LIST_DOC.HOUSE_BANK_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadInternalOrderListExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL +
                INTERNAL_ORDER_LIST_DOC.INTERNAL_ORDER_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadGLCodeListExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + GLCODE_LIST_DOC.GLCODE_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadAccountAssignmentExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL +
                ACCOUNT_ASSIGNMENT_LIST_DOC.ACCOUNT_ASSIGNMENT_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadProfitCenterExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL +
                PROFIT_CENTER_LIST_DOC.PROFIT_CENTER_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadPaymentTermsExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL +
                PAYMENT_TERMS_LIST_DOC.PAYMENT_TERMS_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadCurrencyExcelReport(
        page: number,
        size: number,
        keyword?: string,
        sortBy?: string,
        sortOrder?: string
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.post(
                environment.URL + CURRENCY_LIST_DOC.CURRENCY_EXPORT_EXCEL,
                {
                    keyword: keyword ?? '',
                    sortBy: sortBy ?? '',
                    sortOrder: sortOrder ?? '',
                    size: size,
                    page: page,
                },
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadRfxEvalutionBoqCompareExcel(
        rfxId: number
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.get(
                environment.URL +
                SOURCING_EVENT_PROGRESS.SOURCING_EVALUTION_DOWNLOAD_BOQ +
                '/' +
                rfxId,
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    downloadRfxEvalutionQuesCompareExcel(
        rfxId: number
    ): Promise<HttpResponse<Blob>> {
        return lastValueFrom(
            this.http.get(
                environment.URL +
                SOURCING_EVENT_PROGRESS.SOURCING_EVALUTION_DOWNLOAD_QUESTIONNAIRE +
                '/' +
                rfxId,
                {
                    responseType: 'blob',
                    observe: 'response',
                }
            )
        );
    }

    public saveAsFile(data: HttpResponse<Blob>, fileNamePrefix: string, toShowDateTime?: boolean, isCustomName?: boolean, customName?: string): void {
        let filename = ''
        if (isCustomName) {
            filename = customName;
        } else {
            const contentDisposition = data.headers.get('content-disposition');
            filename = this.getFilenameFromContentDisposition(
                contentDisposition ?? '',
                fileNamePrefix,
                toShowDateTime ?? true
            );
        }
        const blob = data.body;
        const url = window.URL.createObjectURL(blob ?? new Blob());
        this.createAnchorElement(url, filename);
    }
    public saveAsGRAFile(
        data: HttpResponse<Blob>,
        fileNamePrefix: string
    ): void {
        const contentDisposition = data.headers.get('content-disposition');
        const filename = fileNamePrefix;
        const blob = data.body;
        const url = window.URL.createObjectURL(blob ?? new Blob());
        this.createAnchorElement(url, filename);
    }

    private createAnchorElement(url: string, filename: string): void {
        const anchor = document.createElement('a');
        anchor.download = filename;
        anchor.href = url;
        anchor.click();
    }

    private getFilenameFromContentDisposition(
        contentDisposition: string,
        fileNamePrefix: string,
        toShowDateTime: boolean,
    ): string {
        const regex = /filename=(?<filename>[^,;]+);/g;
        const match = regex.exec(contentDisposition);
        if (match != null && match.groups != null) {
            const filename = match.groups['filename'];
            return filename;
        } else {
            if (toShowDateTime) {
                const dateTime = this.dateTimeUtil.convertDate(new Date(), 'YYYY/MM/dd HH:mm:ss a');
                return `${fileNamePrefix} - ${dateTime}`;
            } else {
                return `${fileNamePrefix}`;
            }
        }
    }

    private openPdfViewer(dialogInput: PdfViewerInput): void {
        this.dialog.open(PdfViewerComponent, {
            panelClass: 'eventpop',
            minWidth: '90vw',
            data: dialogInput,
        });
    }
}
