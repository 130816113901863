<mat-label class="pl-6 pr-2 py-1 font-bold text-black block border-b flex justify-between items-center">
    {{dialogInput?.downloadedFileName || 'PDF Viewer'}}
    <button mat-icon-button mat-dialog-close>
        <mat-icon class="text-black">cancel</mat-icon>
    </button>
</mat-label>

<mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>
<div class="flex flex-col p-3 relative" style="width: 100%; height:90vh">
    <div class="mb-2">
        <a mat-button class="float-right" [color]="'accent'" (click)="downloadPdf()" *ngIf="this.pdfSrc">
            <mat-icon svgIcon="heroicons_solid:download"></mat-icon>
            <span>Download</span>
        </a>
    </div>

    <pdf-viewer *ngIf="pdfSrc"
        [src]="pdfSrc"
        [rotation]="0"
        [original-size]="true"
        [show-all]="true"
        [autoresize]="true"
        [fit-to-page]="true"
        [render-text]="true"
        [external-link-target]="'blank'"
        [show-borders]="false"
        (on-progress)="onProgress($event)"
        (after-load-complete)="afterLoadComplete($event)"
        style="height: 100%;">
    </pdf-viewer>
</div>
