import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UtilService } from '../util.service';

@Injectable({
    providedIn: 'root'
})
export class DateTimeUtilService {

    constructor(private datePipe: DatePipe, private utils: UtilService) { }

    convertDateToSubmit(date: Date): string | null {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    convertDate(date: Date, formate: string): string | null {
        return formate ? this.datePipe.transform(date, formate) : this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    getTimeFromTimeString(time: string): string {
        if (!this.utils.isNullOrEmpty(time)) {
            const tempDate = new Date();
            const timeList = time.split(':');
            tempDate.setHours(Number(timeList[0]));
            tempDate.setMinutes(Number(timeList[1]));
            tempDate.setSeconds(Number(timeList[2]));
            return this.datePipe.transform(tempDate, 'hh:mm a') || '';
        }
        return '';
    }

    getDateForFileName(date: Date): string {
        return this.datePipe.transform(date, 'yyyy_M_dd_HH-mm-ss') || '';
    }

    getChartDate(date: Date): string | null {
        return this.datePipe.transform(date, 'dd-MMM-yy');
    }

    parseUTCDateToLocal(dateStr: string): string {
        return new Date(dateStr).toLocaleString();
        // let date = new Date(dateStr);
        // return new Date(
        //   date.getUTCFullYear(),
        //   date.getUTCMonth(),
        //   date.getUTCDate(),
        //   date.getUTCHours(),
        //   date.getUTCMinutes(),
        //   date.getUTCSeconds()
        // );
    }
}
