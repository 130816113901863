import { AbstractControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ERROR_KEY, customErrors } from 'app/enums/custom-validator-key-enum/validator-error-key.enum';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
    @Input() control: AbstractControl

    customErrors = customErrors
    errorKeys = ERROR_KEY

    constructor() { }
    ngOnInit(): void {

    }

}
