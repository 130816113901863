import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Qsection } from 'app/interfaces/rfx-interface/questionnaire.interface';
import { removeNullIdFromObject } from 'app/helper/shared-function';

interface SectionPopup {
    formValue: Qsection,
    sectionLength: number,
    submitForm: Function,
}

export interface sectionFormValue {
    name: string,
    description: string
}

@Component({
    selector: 'app-q-section-form',
    templateUrl: './q-section-form.component.html',
    styleUrls: ['./q-section-form.component.scss']
})
export class QSectionFormComponent implements OnInit {
    sectionForm: FormGroup

    constructor(
        private d: MatDialog,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private popupInfo: SectionPopup) {
    }

    ngOnInit(): void {
        this.initsectionForm()
        const previousFormValue = this.popupInfo.formValue
        if (previousFormValue) {            
            this.sectionForm.patchValue(previousFormValue)
        }        
    }

    initsectionForm() {
        this.sectionForm = this.fb.group({
            sectionSequence : this.popupInfo.sectionLength,
            id : null,
            isChecked: false,
            sNo: this.popupInfo.sectionLength,
            name: ['', [Validators.required, Validators.maxLength(250)]],
            description: ['', [Validators.maxLength(500)]],
            questions: [[]]
        })
    }
    
    addSection() {
        if (!this.sectionForm.valid) {
            this.sectionForm.markAllAsTouched()            
            return
        }

        if (this.popupInfo.submitForm instanceof Function) {    
            const sectionValue = this.sectionForm.value;
            removeNullIdFromObject(sectionValue, 'id')            

            this.popupInfo.submitForm(this.sectionForm.value)
            this.d.closeAll()
        }
    }

}

