import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/core/auth/auth.service';
import { downloadAndSaveFile } from 'app/helper/shared-function';
import { PdfViewerInput } from 'app/interfaces/common-interface/common-interface';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
    pdfSrc: string = '';
    loading = false;

    constructor(
        private authService: AuthService,
        public dialogRef: MatDialogRef<PdfViewerComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogInput: PdfViewerInput
    ) { }

    ngOnInit(): void {
        if(this.dialogInput){
            this.pdfSrc = this.dialogInput.pdfSrc;
        }
    }

    downloadPdf(): void {
        downloadAndSaveFile(this.pdfSrc, this.dialogInput?.downloadedFileName);
    }

    onProgress(event: {loaded: number; total: number}): void {
        this.loading = event.loaded < event.total;
    }

    afterLoadComplete(pdf: PDFDocumentProxy): void {
        setTimeout(() => {
            this.loading = false;
        }, 300);
     }

}
