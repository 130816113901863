import { NgControl, ValidationErrors, ControlValueAccessor, Validators } from '@angular/forms';
import { Component, Input, Optional, Self } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';

@Component({
    selector: 'app-text-area',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements ControlValueAccessor {
    @Input() fieldLabel: string
    @Input() placeholder: string;
    @Input() maxLength: number 
    @Input() rows: number = 4
    @Input() readonly: boolean

    validatoinInfo: ValidationErrors

    stateMatcher: ErrorStateMatcher
    initialValue: string | number = ''
    isFieldRequird: boolean
    isFieldDisabled: boolean = false

    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    onChange: (value: string | number) => {};
    onTouched: () => void;


    writeValue(value: string | number): void {
        this.initialValue = value
    }

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void { this.onTouched = fn }

    setDisabledState?(isDisabled: boolean): void {
        this.isFieldDisabled = isDisabled
    }

    inputHandler(inputValue: string) {
        const trimmedInput = inputValue.trimLeft()
        this.initialValue = trimmedInput
        this.onTouched()
        this.onChange(trimmedInput)
    }

    ngAfterViewInit(): void {
        const timeId = setTimeout(() => {
            if (this.control) {
                this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                this.isFieldRequird = this.control?.control?.hasValidator(Validators.required)
                clearTimeout(timeId)
            }
        })
    }
}
