import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OnlyNumberDirective } from '@fuse/directives/only-number-directive';
import { FormFieldsModule } from 'app/modules/form-fields/form-fields.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from './../modules/material/material.module';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { VisibilityDirective } from './directive/visibility.directive';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { LoaderComponent } from './loader/loader.component';
import { MultilineErrorSnackComponent } from './multiline-error-snack/multiline-error-snack.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { AmpmPipe } from './pipes/ampm.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SourcingProposalComponent } from './rfx/sourcing-proposal/sourcing-proposal.component';
import { AddBrandComponent } from './shared-popup/add-brand/add-brand.component';
import { EditSupportingDocsPopupComponent } from './shared-popup/edit-supporting-docs-popup/edit-supporting-docs-popup.component';
import { RfxEventComponent } from './shared-popup/rfx-event/rfx-event.component';

const maskConfig: Partial<IConfig> = {
    validation: true,
    showMaskTyped: true
};

const components = [
    OnlyNumberDirective,
    EditSupportingDocsPopupComponent,
    ConfirmDialogComponent,
    AddBrandComponent,
    RfxEventComponent,
    VisibilityDirective,
    SafeHtmlPipe,
    AmpmPipe,
    SourcingProposalComponent,
    LoaderComponent,
    MultilineErrorSnackComponent,
    ContextMenuComponent,
    PdfViewerComponent,
    ImageViewerComponent,
];

@NgModule({
    declarations: [
        ...components,
        OrderByPipe,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormFieldsModule,
        ReactiveFormsModule,
        FormsModule,
        NgxMaskModule.forRoot(maskConfig),
        PdfViewerModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        NgxMaskModule,
        PdfViewerModule,
        ...components
    ]
})
export class SharedModule {
}
