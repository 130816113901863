import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalVariable } from 'app/config';
import { AuthService } from 'app/core/auth/auth.service';
import { DOCUMENTS } from 'app/core/constants/api-constant';
import { downloadAndSaveFile } from 'app/helper/shared-function';
import { ImgViewerInput, PdfViewerInput } from 'app/interfaces/common-interface/common-interface';
import { DeleteSupportingDoc, SupportingDocument } from 'app/interfaces/rfx-interface/rfx-form.interface';
import { ImageViewerComponent } from 'app/shared/image-viewer/image-viewer.component';
import { PdfViewerComponent } from 'app/shared/pdf-viewer/pdf-viewer.component';
import { Observable } from 'rxjs/internal/Observable';
import { CustomHttpService } from './custom-http-service/custom-http.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(
        private customService: CustomHttpService,
        private http: HttpClient,
        private authService: AuthService,
        private dialog: MatDialog
    ) { }

    uploadDocument(formData: FormData): Observable<SupportingDocument> {
        return this.customService.reactivePost<SupportingDocument>(`${DOCUMENTS.DOCUMENT_UPLOAD}`, formData);
    }

    deleteDocuments(docs: Array<DeleteSupportingDoc>): Observable<DeleteSupportingDoc> {
        return this.customService.reactivePost<DeleteSupportingDoc>(DOCUMENTS.DOCUMENT_DELETE, docs);
    }

    downloadDocument(originalFileName: string, downloadedFileName: string, imgPath?: string): any {
        const fileNameArray = originalFileName.split('.');
        const extension: string = fileNameArray[fileNameArray.length - 1];

        if (['jpg', 'jpeg', 'png'].includes(extension)) {
            this.openImageViewer({ imgSrc: imgPath, downloadedFileName: downloadedFileName });
        } else {
            this.downloadAndOpenDocs(originalFileName, downloadedFileName, ['pdf'].includes(extension));
        }
    }

    downloadAndOpenDocs(originalFileName: string, downloadedFileName: string, isPdf: boolean): void {
        const headers = new HttpHeaders().set(
            'authorization',
            'Bearer ' + this.authService.accessToken
        );

        this.http
            .get(`${GlobalVariable.BASE_URL + DOCUMENTS.DOCUMENT_DOWNLOAD}/${originalFileName}`, {
                headers,
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe((response: HttpResponse<Blob>) => {
                const fileUrl = window.URL.createObjectURL(response.body ?? new Blob());
                if (isPdf) {
                    this.openPdfViewer({ pdfSrc: fileUrl, downloadedFileName: downloadedFileName });
                } else {
                    downloadAndSaveFile(fileUrl, downloadedFileName.split('.')[0]);
                }
            });
    }

    public openImageViewer(dialogInput: ImgViewerInput): void {
        this.dialog.open(ImageViewerComponent, {
            panelClass: 'eventpop',
            data: dialogInput,
        });
    }

    public openPdfViewer(dialogInput: PdfViewerInput): void {
        this.dialog.open(PdfViewerComponent, {
            panelClass: 'eventpop',
            minWidth: '60vw',
            minHeight: '100vh',
            data: dialogInput,
        });
    }

    uploadMultipleDocument(formData: FormData): Observable<Array<SupportingDocument>> {
        return this.customService.reactivePost<Array<SupportingDocument>>(`${DOCUMENTS.MULTIPLE_DOCUMENT_UPLOAD}`, formData);
    }

}
