import { AfterViewInit, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';
import { formatDateTime } from 'app/helper/shared-function';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() fieldLabel: string
    @Input() placeholder:string = 'select time'

    defaultTime: string
    isFieldRequird: boolean
    stateMatcher: ErrorStateMatcher
    isFieldDisabled: boolean


    onTouched = () => { };
    updateMainForm = (selectedValue: string) => { }

    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    ngOnInit(): void {

    }

    timeEvent(selectedTime: string) {        
        this.updateMainForm(selectedTime)
    }
    
    writeValue(defaultTime: string): void {
        if (!defaultTime) return
        this.defaultTime = defaultTime

    }

    registerOnChange(fn: any): void { this.updateMainForm = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }

    setDisabledState?(isDisabled: boolean): void {
        this.isFieldDisabled = isDisabled
    }

    ngAfterViewInit(): void {
        const timeId = setTimeout(() => {
            if (this.control) {
                this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                this.isFieldRequird = this.control?.control?.hasValidator(Validators.required)
                clearTimeout(timeId)
            }
        })
    }

}
