import { AfterViewInit, Component, EventEmitter, Input, Optional, Output, Self, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';
import moment from 'moment';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class DatePickerComponent implements ControlValueAccessor, AfterViewInit {
    @Input() fieldLabel: string
    @Input() disablePastDate: boolean = true
    @Input() PastDateToDisable: Date = new Date()
    @Input() PostDateToDisable: Date
    @Input() showClearButton:boolean
    @Input() getDateAsString: boolean
    @Output() selectedDateEvent = new EventEmitter<Date | string>();
    @Output() clickOnClearButton = new EventEmitter<Date>();

    defaultDate: Date | string
    todayDate: Date = new Date();

    isFieldRequird: boolean
    isFieldDisabled: boolean
    stateMatcher: ErrorStateMatcher

    onTouched = () => { };
    updateMainForm = (selectedValue: Date | string) => { }

    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }


    extractDate(utc) {
        if(!utc) return null;
        return moment(utc).format('YYYY-MM-DD')
    }

    dateEvent(event: Date) {
        this.updateMainForm(this.extractDate(event))
        this.selectedDateEvent.emit(this.extractDate(event));           
    }

    writeValue(defaultDate: Date): void {
        
        this.defaultDate = this.extractDate(defaultDate) 
        // console.log(this.defaultDate);

        if (!defaultDate) return;
        if (this.control?.control) {
            setTimeout(()=>{
                this.updateMainForm(this.extractDate(defaultDate))
            },100)
        }
    }

    registerOnChange(fn: any): void { this.updateMainForm = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }
    setDisabledState(disabled: boolean) { this.isFieldDisabled = disabled }


    ngAfterViewInit(): void {
        const timeId = setTimeout(() => {
            if (this.control) {
                this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                this.isFieldRequird = this.control?.control?.hasValidator(Validators.required)
                clearTimeout(timeId)
            }
        })
    }

    resetDate(){
        this.defaultDate = null
        this.clickOnClearButton.emit()
        this.updateMainForm(null)
        
    }
}
