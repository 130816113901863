import { AfterViewInit, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';

@Component({
  selector: 'app-number-input-field',
  templateUrl: './number-input-field.component.html',
  styleUrls: ['./number-input-field.component.scss']
})
export class NumberInputFieldComponent implements ControlValueAccessor, Validators, AfterViewInit, OnInit {

  @Input() maxDecimalPlace: number = 2;
  @Input() fieldLabel: string;
  @Input() placeholder: string = '0.00';
  @Input() maxLength: number;
  @Input() showErrorMessage: boolean = true;
  @Input() allowRoundingAtStart: boolean = true;
  @Input() allowNegatives: boolean = false;
  @Output() changeEvent = new EventEmitter<string | number>();

  decimalPlaceSupport: string;
  stateMatcher: ErrorStateMatcher;
  inputValue: string = '';
  isFieldRequired: boolean;
  isFieldDisabled: boolean = false;

  
  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }
  ngOnInit(): void {
    this.decimalPlaceSupport = 'separator.' + this.maxDecimalPlace;
  }

  onChange = (value: string | number) => { };
  onTouched = () => { };

  inputHandler() {
    if (this.inputValue) {
      var toFix = Number(this.inputValue).toFixed(this.maxDecimalPlace);
      this.onChange(Number(toFix));
      this.changeEvent.next(Number(toFix));
    } else {
      this.onChange(0.00);
      this.changeEvent.next(0.00);
    }
  }

  writeValue(value: any): void {
    let vl = value?.toString() ?? '0';
    if (vl == '0') {
      this.onChange(0);
      this.inputValue = '';
    } else {
      if (this.allowRoundingAtStart) {
        this.inputValue = Number(vl).toFixed(this.maxDecimalPlace);
      } else {
        this.inputValue = Number(vl).toString();
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isFieldDisabled = isDisabled
  }

  ngAfterViewInit(): void {
    const timeId = setTimeout(() => {
      if (this.control) {
        this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
        this.isFieldRequired = this.control?.control?.hasValidator(Validators.required)
        clearTimeout(timeId)
      }
    })
  }

}
