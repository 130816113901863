import { UtilService } from './../../../core/services/util.service';
import { FormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-add-brand',
    templateUrl: './add-brand.component.html',
    styleUrls: ['./add-brand.component.scss']
})
export class AddBrandComponent implements OnInit {

    brandField = new FormControl('', Validators.required)
    brandList: string[] = []
    isBrandAlreadyExist: boolean
    constructor(
        private util_service: UtilService,
        @Inject(MAT_DIALOG_DATA) private defaultBrandList: string[],
        private dialogRef: MatDialogRef<AddBrandComponent>,
    ) { }

    ngOnInit(): void {
        // console.log(this.defaultBrandList);
        this.defaultBrandList && this.brandList.push(...this.defaultBrandList)
    }

    addMoreBrand() {
        if (!this.brandField.valid) {
            this.brandField.markAsTouched()
            return
        }
        if (this.brandList.includes(this.brandField.value)) {
            this.isBrandAlreadyExist = true
            this.util_service.showErrorSnack('Brand already exist', 2000)
            return
        }

        this.brandList.push(this.brandField.value)
        this.brandField.reset()
        this.isBrandAlreadyExist = false
    }

    removeBrand(index: number) {
        this.brandList.splice(index, 1)
    }

    updateParentField() {
        if (this.brandField.value) {
            this.util_service.showErrorSnack('please add your brand in list', 2000)
            return
        }

        this.dialogRef.close(this.brandList.join(','))
    }

}
