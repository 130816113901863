import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CommonService } from 'app/core/services/common/common-service/common.service';
import { RfxTemplateService } from 'app/core/services/rfx-service/rfx-template-service/rfx-template.service';
import { EVENT_TYPE, RFX_ROUTE_ACTION, STATUS } from 'app/enums/common-enum/common-enum';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FuseLoadingService } from '@fuse/services/loading';
import { UserService } from 'app/core/services/user-service/user.service';
import { UtilService } from 'app/core/services/util.service';
import { RfxService } from 'app/core/services/rfx-service/rfx/rfx.service';
import { DROPDOWN_TYPE } from 'app/enums/form-enum/form-enum';

interface DialogData {
    preprId: number;
}

export interface RfxEventInfo {
    preprId: string;
    event: string;
    rfxTemplateId: string;
}

@Component({
    selector: 'app-rfx-event',
    templateUrl: './rfx-event.component.html',
    styleUrls: ['./rfx-event.component.scss']
})
export class RfxEventComponent implements OnInit, OnDestroy {
    eventSubs$: Subscription[] = [];

    eventType: { id: number; name: string }[] = [];
    templateList: any[] = [];
    dropdownType = DROPDOWN_TYPE;
    rfxEventForm: FormGroup;
    repeatPurchaseForm: FormGroup
    finishedRfxList
    userList

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private commonService: CommonService,
        private rfxService: RfxService,
        private rfxTemplateService: RfxTemplateService,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private dialog: MatDialog,
        private util_service: UtilService,
        private fuseService: FuseLoadingService
    ) {
        this.fuseService.show();
    }

    ngOnInit(): void {
        this.intiRfxEventForm();
        this.initRepeatPurchaseForm()
        this.getEventList();
        this.getUserList()
        this.getFinishedRfxLising()

    }

    intiRfxEventForm(): void {
        this.rfxEventForm = this.fb.group({
            preprId: this.dialogData.preprId,
            event: ['', Validators.required],
            rfxTemplateId: ['', Validators.required],
        });
    }

    initRepeatPurchaseForm() {
        this.repeatPurchaseForm = this.fb.group({
            rfxId: [null, Validators.required],
            newTeamMembers: [null, Validators.required]
        })
    }

    getFinishedRfxLising() {
        const rfxListing = this.rfxService.getFinishedRfxList().subscribe((res) => {
            this.finishedRfxList = res
        })
        this.eventSubs$.push(rfxListing)
    }

    getUserList() {
        const userSubs = this.commonService.fetchAllActiveUserList().subscribe((res) => {
            this.userList = res
        })

        this.eventSubs$.push(userSubs)
    }

    selectedEvent(event: EVENT_TYPE) {
        this.getTemplateNames(event);
    }

    doRepeatPurchase() {
        // this.rfxService.repeatPurchase({}).subscribe()
    }

    getEventList(): void {
        const eventListSubs = this.commonService.fetchEventType().subscribe((res) => {
            this.eventType = res;
        });
        this.eventSubs$.push(eventListSubs);
    }

    getTemplateNames(eventType: EVENT_TYPE): void {
        const listSubs = this.rfxTemplateService.getRfxTemplateDropdown(eventType).subscribe((list) => {
            this.templateList = list;
        });
        this.eventSubs$.push(listSubs);
    }

    createRfx(): void {
        const formValue = this.rfxEventForm.value;
        if (!this.rfxEventForm.valid) {
            this.rfxEventForm.markAllAsTouched();
            return;
        }
        this.router.navigate(['/rfx/form'],
            {
                queryParams: {
                    rfxEventInfo: JSON.stringify(formValue),
                    action: RFX_ROUTE_ACTION.PRE_PR_AND_RFX_METADATA
                },
            }
        ).then(() => this.dialog.closeAll())
    }

    concludeEvent() {
        if (this.repeatPurchaseForm.valid) {
            this.rfxService.repeatPurchase(this.repeatPurchaseForm.value).subscribe(() => {
                this.util_service.showSuccessSnack('Event concluded')
                this.dialog.closeAll()
                this.router.navigate(['/pre-pr/prepr-list']);
            })
        }

        else {
            this.repeatPurchaseForm.markAllAsTouched()
        }
    }

    closeRfx() {
        this.dialog.closeAll();
        this.router.navigate(['/pre-pr/prepr-list']);
    }

    ngOnDestroy(): void {
        this.eventSubs$.forEach((subs: Subscription) => {
            subs.unsubscribe();
        });
    }

}
