import { FormGroup, FormControl } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupportingDocument } from 'app/interfaces/rfx-interface/rfx-form.interface';
import { DOCUMENT_TYPE } from 'app/enums/rfx-enum/rfx.enum';
import { internalOrExternal } from 'app/helper/shared-function';


interface UpdateDocs {
    previousValue: SupportingDocument
}

@Component({
    selector: 'app-edit-supporting-docs-popup',
    templateUrl: './edit-supporting-docs-popup.component.html',
    styleUrls: ['./edit-supporting-docs-popup.component.scss']
})
export class EditSupportingDocsPopupComponent implements OnInit {
    updateDocForm = new FormGroup({
        description: new FormControl(''),
        availability: new FormControl(''),

    })

    DocumentType = DOCUMENT_TYPE

    constructor(
        public dialogRef: MatDialogRef<EditSupportingDocsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) private updateInfo: UpdateDocs,
    ) { }

    ngOnInit(): void {
        if (this.updateInfo.previousValue) {
            this.updateDocForm.patchValue(this.updateInfo.previousValue)
        }
    }

    handleAvailabitly(checked: boolean) {
        this.updateDocForm.patchValue({ availability: checked ? DOCUMENT_TYPE.INTERNAL : DOCUMENT_TYPE.EXTERNAL })        
    }

    updateForm() {
        this.dialogRef.close(this.updateDocForm.value)
        // this.updateInfo.updateFn(this.updateDocForm.value)
    }

}
