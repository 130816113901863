<div class="form-field-container">
    <mat-form-field class="fuse-mat-dense w-full"
        floatLabel="always"
        appearance="fill">
        <mat-label *ngIf="fieldLabel"
            [ngClass]="{ 'error' : control?.touched && control?.invalid }">
            {{ fieldLabel }}
        </mat-label>
        <textarea matInput
            [rows]="rows"
            [required]="isFieldRequird"
            [readonly]="readonly"
            (input)="inputHandler($event.target.value)"
            [ngModel]="initialValue"
            [disabled]="isFieldDisabled"
            class="p-0 my-1"
            (blur)="onTouched()"
            [errorStateMatcher]="stateMatcher"
            [maxlength]="maxLength ?? ''"
            [placeholder]="placeholder">
    </textarea>
        <mat-hint class="text-[#24B1F1] mt-2"
            *ngIf="maxLength">
            Max {{ maxLength }} characters only |
            <span class="text-gray-700">
                {{ maxLength - control.value.length }} characters Left
            </span>
        </mat-hint>
    </mat-form-field>
    <app-error-message *ngIf="control?.control?.errors"
        [control]="control?.control">
    </app-error-message>
</div>