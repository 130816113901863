import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule, forwardRef } from '@angular/core';
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { InputFieldComponent } from './input-field/input-field.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { ReadMoreComponent } from './readmore/readmore.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TimePickerComponent } from './time-picker/time-picker.component';

import { TypeofModule } from '@fuse/pipes/typeof';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from '../material/material.module';
import { NumberInputFieldComponent } from './number-input-field/number-input-field.component';
import { NonDecimalInputFieldComponent } from './non-decimal-input-field/non-decimal-input-field.component';
import { QSectionFormComponent } from './shared-forms/questionnaire-form/q-section-form/q-section-form.component';
import { QestionFormComponent } from './shared-forms/questionnaire-form/qestion-form/qestion-form.component';
import { QuestionnaireFormComponent } from './shared-forms/questionnaire-form/questionnaire-form.component';
import { TeamMemberFormComponent } from './shared-forms/team-member-form/team-member-form.component';
import { TeamMemberViewerTypeComponent } from './shared-forms/team-member-form/team-member-viewer-type/team-member-viewer-type.component';

const components = [
    InputFieldComponent,
    TextAreaComponent,
    ErrorMessageComponent,
    DropdownComponent,
    ReadMoreComponent,
    DatePickerComponent,
    TimePickerComponent,
    CheckboxComponent,
    MultiSelectDropdownComponent,
    QuestionnaireFormComponent,
    QestionFormComponent,
    QSectionFormComponent,
    TeamMemberFormComponent,
    NonDecimalInputFieldComponent,
    NumberInputFieldComponent,
];

@NgModule({
    declarations: [
        ...components,
        TeamMemberViewerTypeComponent,
    ],
    imports: [
        CommonModule,
        NgxMatSelectSearchModule,
        // NgxMaterialTimepickerModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        TypeofModule,
        NgxMaskModule.forRoot()
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        ...components
    ],
    providers: [
        TitleCasePipe,
    ]
})
export class FormFieldsModule { }
