import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[visibility]'
})
export class VisibilityDirective implements AfterViewInit {

  @Input() visibility = 'visible';

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    this.renderer.removeClass(this.el.nativeElement, 'gone-element');
    this.renderer.removeClass(this.el.nativeElement, 'invisible-element');
    this.renderer.removeClass(this.el.nativeElement, 'visible-element');

    if (this.visibility == 'gone') {
      this.renderer.addClass(this.el.nativeElement, 'gone-element');
    } else if (this.visibility == 'invisible') {
      this.renderer.addClass(this.el.nativeElement, 'invisible-element');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'visible-element');
    }
  }
}
