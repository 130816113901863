<mat-dialog-content>
    <form>
        <div class="flex flex-col">
            <div class="flex items-center justify-between">
                <!-- <app-input-field class="md:w-10/12" placeholder="Enter brand"></app-input-field> -->
                <mat-form-field class="fuse-mat-dense w-full" [floatLabel]="'always'">
                    <mat-label [ngClass]="{'danger' : brandField.touched &&  brandField.errors }" >Brand Name</mat-label>
                    <input matInput [formControl]="brandField" placeholder="Enter Brand" maxlength="50">
                </mat-form-field>

                <button type="button" mat-icon-button class="pl-5 mt-2" (click)="addMoreBrand()">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
            </div>
        </div>

        <div *ngIf="brandList.length" class="min-h-[120px] bg-card border rounded-xl mb-3">
            <mat-list class="pb-2">
                <ng-container>
                    <mat-list-item class="h-[30px] flex" *ngFor="let brand of brandList; let i = index">
                        <button type="button" mat-icon-button matListIcon (click)="removeBrand(i)">
                            <mat-icon class="text-2xl" [ngClass]="{'danger' : brand === brandField.value && isBrandAlreadyExist }">delete
                            </mat-icon>
                        </button>
                        <p matLine style="white-space: break-spaces;" class="text-base"
                            [ngClass]="{'danger' : brand === brandField.value && isBrandAlreadyExist }"> {{ brand }} </p>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>

        <div  class="text-right mt-5">
            <button type="button" color="accent" mat-raised-button (click)="updateParentField()">ADD</button>
        </div>
    </form>


</mat-dialog-content>