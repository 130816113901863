import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DeleteSupportingDoc,
    RfxApprovalHistory,
    RfxAuditHistory,
    RfxEscalateBody,
    RfxExtendEvent,
    RfxForm,
    SupportingDocument,
} from 'app/interfaces/rfx-interface/rfx-form.interface';
import { QualifiedSupplier } from 'app/interfaces/sourcing-progress-event/qualified-supplier.interface';
import { SourcingAward } from 'app/interfaces/sourcing-progress-event/sourcing-award.interface';
import {
    DownloadPayload,
    EvaluationItemDelete,
    RfxEnvelopApprovalHistory,
    SourcingEvalution,
    SourcingProposal,
    SupplierMessageInput,
    SupplierResponse,
    SupplierStatusChangeInput,
} from 'app/interfaces/sourcing-progress-event/sourcing-evalution.interface';
import { SourcingProgress } from 'app/interfaces/sourcing-progress-event/sourcing-progress.interface';
// import moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import * as apiConstants from '../../../constants/api-constant';
import { CustomHttpService } from '../../common/custom-http-service/custom-http.service';
import moment from 'moment-timezone';
import { environment } from 'environments/environment';
import { DownloadService } from '../../common/download.service';
import { map } from 'rxjs';
import { ActiveSourcingList } from 'app/interfaces/common-interface/common-interface';
import { BillOfQuantity } from 'app/interfaces/bill-of-quantity';
import { ProjectService } from 'app/modules/admin/dashboards/project/project.service';
import { USER_MODULE } from 'app/enums/common-enum/common-enum';

@Injectable({
    providedIn: 'root',
})
export class RfxService {
    constructor(
        private http: HttpClient,
        private projectService: ProjectService,
        private customService: CustomHttpService,
        private downloadService: DownloadService
    ) { }

    createRfxForm(rfxForm): Observable<any> {
        return this.customService.reactivePost<any>(
            apiConstants.RFX_FORM.CREATE_RFX,
            rfxForm
        );
    }

    updateRfxForm(rfxForm): Observable<any> {
        return this.customService.reactivePut<any>(
            apiConstants.RFX_FORM.UPDATE_RFX_FORM,
            rfxForm
        );
    }

    updateRfxSummaryMeeting(meeting: any): Observable<any> {
        return this.customService.reactivePatch(apiConstants.RFX_FORM.UPDATE_RFX_SUMMARY_MEETING, meeting);
    }

    createNegotiation(rfxFormData, rfxId: number): Observable<any> {
        const params = new HttpParams().set('rfxId', rfxId);
        return this.customService.reactivePost(
            apiConstants.RFX_FORM.CREATE_NEGO,
            rfxFormData,
            { params }
        );
    }

    getRfxFormDetailsById(rfxId: number): Observable<RfxForm> {
        return this.customService.reactiveGet(
            `${apiConstants.RFX_FORM.RFX_BY_ID}/${rfxId}`
        );
    }

    cancelRfxForm(rfxId: number): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.RFX_FORM.CANCEL_RFX_REQUEST}/${rfxId}`,
            null
        );
    }

    getPreprAndRfxMetadata(
        templateId: string,
        preprId: string
    ): Observable<any> {
        const ids = `/${templateId}/${preprId}`;
        return this.customService.reactiveGet(
            apiConstants.RFX_FORM.PRE_PR_AND_RFX_METADATA + ids
        );
    }

    submitForApproval(rfxId: number): Observable<any> {
        const access = this.projectService.getUserRoleModuleWise(USER_MODULE.SOURCING);
        let params = new HttpParams()
            .set('rfxId', rfxId)
            .set('tmz', moment.tz.guess());
        if (access.length) {
            params = params.set('isSourcing', access[0]);
        }
        // return this.customService.reactivePatch<any>(`${apiConstants.RFX_FORM.SUBMIT_FOR_APPROVAL}?rfxId=${rfxId}`, null);
        return this.customService.reactivePatch<any>(
            apiConstants.RFX_FORM.SUBMIT_FOR_APPROVAL,
            null,
            { params }
        );
    }

    submitNegotiation(rfxId: number): Observable<any> {
        const params = new HttpParams()
            .set('rfxId', rfxId)
            .set('tmz', moment.tz.guess());
        return this.customService.reactivePost<any>(
            apiConstants.RFX_FORM.SUBMIT_NEGOTIATION,
            null,
            { params }
        );
    }

    changeApprovalStatus(body: any): Observable<any> {
        return this.customService.reactivePatch<any>(
            apiConstants.RFX_FORM.CHANGE_APPROVAL_STATUS,
            body
        );
    }

    // rejectPrePr(body: any): Observable<any> {
    //     return this.customService.reactivePatch<any>(
    //         apiConstants.RFX_FORM.REJECT_PRE_PR,
    //         body
    //     );
    // }

    approverBack(rfxId: number): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.RFX_FORM.APPROVER_BACK}?rfxId=${rfxId}`,
            null
        );
    }

    getRfxApprovalHistory(
        rfxId: number
    ): Observable<Array<RfxApprovalHistory>> {
        return this.customService.reactiveGet<Array<RfxApprovalHistory>>(
            `${apiConstants.RFX_FORM.RFX_APPROVAL_HISTORY}?rfxId=${rfxId}`
        );
    }

    getRfxEnvelopApprovalHistories(
        rfxId: number
    ): Observable<RfxEnvelopApprovalHistory> {
        return this.customService.reactiveGet<RfxEnvelopApprovalHistory>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.ENVELOP_APPROVAL_HISTORIES}/${rfxId}`
        );
    }

    getRfxAuditHistory(rfxId: number): Observable<Array<RfxAuditHistory>> {
        const params = new HttpParams().set('rfxId', rfxId);
        return this.customService.reactiveGet<Array<RfxAuditHistory>>(
            apiConstants.RFX_FORM.RFX_AUDIT_HISTORY,
            { params }
        );
    }

    updateApprovalRoute(body: any): Observable<any> {
        return this.customService.reactivePatch<any>(
            apiConstants.RFX_FORM.UPDATE_APPROVAL_ROUTE,
            body
        );
    }

    updateProposalRoute(body: any): Observable<any> {
        return this.customService.reactivePatch<any>(
            apiConstants.RFX_FORM.UPDATE_PROPOSAL_ROUTE,
            body
        );
    }

    downloadRfxSummaryPdf(rfxId: number): Observable<any> {
        return this.customService.reactivePost<any>(
            `${apiConstants.RFX_FORM.DOWNLOAD_RFX_SUMMARY}/${rfxId}`,
            null
        );
    }

    rfxSourcingProgress(rfxId: number): Observable<SourcingProgress> {
        return this.customService.reactiveGet<SourcingProgress>(
            apiConstants.SOURCING_EVENT_PROGRESS.SOURCING_PROGRESS + '/' + rfxId
        );
    }

    rfxSourcingEvalution(rfxId: number): Observable<any> {
        return this.customService.reactiveGet<any>(
            apiConstants.SOURCING_EVENT_PROGRESS.SOURCING_EVALUTION +
            '/' +
            rfxId
        );
    }

    rfxEvalutionDetailByRfxId(rfxId: number): Observable<SourcingEvalution> {
        return this.customService.reactiveGet<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.SOURCING_EVALUTION_DETAILS_BY_RFXID}/${rfxId}`
        );
    }

    deleteUndoEvaluationBoqItem(body: EvaluationItemDelete): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.DELETE_UNDO_EVALUATION_BOQ_ITEM}`,
            body
        );
    }

    getQualifiedSuppliers(
        rfxSupplierId: number
    ): Observable<QualifiedSupplier> {
        const params = new HttpParams().set('rfxSupplierId', rfxSupplierId);
        return this.customService.reactiveGet<QualifiedSupplier>(
            apiConstants.RFX_FORM.GET_QUALIFIED_SUPPLIER,
            { params }
        );
    }

    saveEvalution(body: {
        id: number;
        supplierEvaluationStatus: Array<any>;
        selectionCriteria: Array<any>;
    }): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.SAVE_EVALUTION}`,
            body
        );
    }

    submitEvalution(
        body: { envelopeId: number; rfxId: number; comment: string; isSourcing: string; },
        sequence: number
    ): Observable<any> {
        return this.customService.reactivePatch<any>(
            apiConstants.SOURCING_EVENT_PROGRESS.SUBMIT_EVALUTION + sequence,
            body
        );
    }

    finishEvalution(
        body: { rfxId: number, isSourcing: string },
        sequence: number
    ): Observable<any> {
        return this.customService.reactivePatch<any>(
            apiConstants.SOURCING_EVENT_PROGRESS.FINISH_EVALUTION + sequence,
            body
        );
    }

    openEnvelope(body: { rfxId: number; sequence: number }): Observable<any> {
        return this.customService.reactivePatch<any>(
            apiConstants.SOURCING_EVENT_PROGRESS.OPEN_ENVELOPE,
            body
        );
    }

    changeSupplierStatusByRfxId(
        rfxId: number,
        body: SupplierStatusChangeInput
    ): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.CHANGE_SUPPLIER_STATUS_BY_RFXID}/${rfxId}`,
            body
        );
    }

    getSupplierResponseMessages(
        rfxId: number
    ): Observable<Array<SupplierResponse>> {
        return this.customService.reactiveGet<Array<SupplierResponse>>(
            `${apiConstants.SUPPLIER_RESPONSE.GET_SUPPLIER_RESPONSE_MESSAGES}?rfxId=${rfxId}`
        );
    }

    uploadDocs(file: FormData): Observable<SupportingDocument> {
        return this.customService.reactivePost<SupportingDocument>(
            apiConstants.DOCUMENTS.DOCUMENT_UPLOAD,
            file
        );
    }

    // deleteDocs(originalname: Array<string>): Observable<DeleteSupportingDoc> {
    //     return this.customService.reactivePost<DeleteSupportingDoc>(
    //         apiConstants.DOCUMENTS.DOCUMENT_DELETE,
    //         originalname
    //     );
    // }

    rfxSourcingProposalByRfxId(rfxId: number): Observable<SourcingProposal> {
        return this.customService.reactiveGet<SourcingProposal>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.SOURCING_PROPOSAL_DETAILS}/${rfxId}`
        );
    }

    rfxSPByRfxId(rfxId: number): Observable<any> {
        return this.customService.reactiveGet<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.SOURCING_PROPOSAL_DETAILS}/${rfxId}`
        );
    }

    approveRejectEnvelop(
        sequence: number,
        body: { rfxId: number; remark?: string; status: string; envelopeId: number }
    ): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.APPROVE_REJECT_ENVELOP}/${sequence}`,
            body
        );
    }

    saveDraftOrApproval(body: any, acN: string): Observable<any> {
        return this.customService.reactivePatch<any>(
            apiConstants.SOURCING_EVENT_PROGRESS.SAVE_DRAFT_OR_APPROVAL +
            '?acN=' +
            acN,
            body
        );
    }

    getOverAllCostByAwardCriteria(
        rfxId: number,
        value: string
    ): Observable<any> {
        return this.customService.reactiveGet<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.GET_RECOMANDATION_PROPOSAL}/${rfxId}?awardCriteria=${value}`
        );
    }
    getOverAllCostByAwardCriteriaForManual(
        rfxId: number,
        itemId: string,
        vendorCode: string
    ): Observable<any> {
        return this.customService.reactivePost<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.MANUAL_SUPPLIER_SELECTION}/${rfxId}`,
            {
                itemId,
                vendorCode,
            }
        );
    }
    getSourcingAwardByRfxId(rfxId: number): Observable<SourcingAward> {
        return this.customService.reactiveGet<SourcingAward>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.GET_SOURCING_AWARD_BY_RFXID}/${rfxId}`
        );
    }

    approveRejectProposal(body: {
        rfxId: number;
        remark?: string;
    }): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.APPROVE_REJECT_PROPOSAL}`,
            body
        );
    }

    concludeRfxEvent(
        rfxId: number,
        body: { remark: string; concludingDocument: SupportingDocument }
    ): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.RFX_FORM.CONCLUDE_RFX}?rfxId=${rfxId}`,
            body
        );
    }

    refreshSourcingProposal(
        rfxId: number,
        body: { remark: string; sourcingProposalDoc: SupportingDocument }
    ): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.RFX_FORM.REFRESH_SOURCING_PROPOSAL}?rfxId=${rfxId}`,
            body
        );
    }

    suspendEvent(rfxId: number): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.SUSPEND_EVENT}/${rfxId}`,
            null
        );
    }

    closeEvent(rfxId: number): Observable<any> {
        const requestBody = {
            closingDate: moment().format('YYYY-MM-DD'),
            closingTime: moment().format('HH:mm'),
        };

        const params = new HttpParams().set('tmz', moment.tz.guess());
        return this.customService.reactivePatch<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.CLOSE_EVENT}/${rfxId}`,
            requestBody,
            { params }
        );
    }

    extendEvent(rfxId: number, body: RfxExtendEvent): Observable<any> {
        return this.customService.reactivePatch<any>(
            `${apiConstants.SOURCING_EVENT_PROGRESS.EXTEND_EVENT}/${rfxId}`,
            body
        );
    }
    getAllNegosByRfxId(
        childId: number,
        parentEventId?: number
    ): Observable<Array<RfxForm>> {
        let params = new HttpParams().set('childId', childId);
        if (parentEventId) {
            params = params.set('parentEventId', parentEventId);
        }
        return this.customService.reactiveGet(
            `${apiConstants.RFX_FORM.GET_ALL_NEGOS}`,
            { params }
        );
    }

    buyerSendMessage(supplierMessage: SupplierMessageInput): Observable<any> {
        return this.customService.reactivePatch(
            `${apiConstants.SUPPLIER_RESPONSE.BUYER_SEND_MESSAGE}`,
            supplierMessage
        );
    }

    sendMessageFromBuyer(
        rfxId: number,
        supplierMessage: SupplierMessageInput
    ): Observable<any> {
        const params = new HttpParams().set('rfxId', rfxId);
        return this.customService.reactivePost(
            `${apiConstants.SUPPLIER_RESPONSE.SEND_MESSAGE_FROM_BUYER}`,
            supplierMessage,
            { params }
        );
    }

    getMessageHistory(
        supplierResponseId: number
    ): Observable<SupplierResponse> {
        return this.customService.reactiveGet(
            `${apiConstants.SUPPLIER_RESPONSE.GET_MESSAGE_HISTORY}?supplierResponseId=${supplierResponseId}`
        );
    }

    repeatPurchase(purchaseInfo): Observable<any> {
        return this.customService.reactivePatch(
            apiConstants.RFX_FORM.REPEAT_PURCHASE,
            purchaseInfo
        );
    }

    getFinishedRfxList(): Observable<ActiveSourcingList[]> {
        return this.customService.reactiveGet<ActiveSourcingList[]>(
            apiConstants.RFX_FORM.FINISHED_RFX_LIST
        ).pipe(map((li: ActiveSourcingList[]) => li.map((l) => {
            l.displayName = l.rfxSequenceId + ' - ' + l.title;
            return l;
        })));
    }

    // getDMFinishedRfxList() {
    //     return this.customService.reactiveGet(
    //         apiConstants.RFX_FORM.FINISHED_RFX_LIST
    //     )
    // }

    downloadPDFSummary(rfxSequenceId: number, body: DownloadPayload): void {
        this.downloadService.callDownloadAPI(
            `${environment.URL}${apiConstants.SOURCING_EVENT_PROGRESS.RFX_EVALUATION_SUMMARY_REPORT}`,
            'Sourcing Summary',
            body,
            'application/pdf',
            `Sourcing Summary - ${rfxSequenceId}`,
            true
        );
    }

    downloadPDFDetail(rfxSequenceId: number, body: DownloadPayload): void {
        this.downloadService.callDownloadAPI(
            `${environment.URL}${apiConstants.SOURCING_EVENT_PROGRESS.DOWNLOAD_PDF_SUMMARY}`,
            'Sourcing Detail',
            body,
            'application/pdf',
            `Sourcing Detail - ${rfxSequenceId}`,
            true
        );
    }

    downloadSourcingProposalExcel(rfxSequenceId: number, body: DownloadPayload): void {
        this.downloadService.downloadExcel(
            `${environment.URL}${apiConstants.SOURCING_EVENT_PROGRESS.DOWNLOAD_SOURCING_PROPOSAL_EXCEL}`,
            'Sourcing Proposal',
            body,
            `${rfxSequenceId} - Sourcing Proposal Report`
        );
    }

    sourcingEscalate(body: RfxEscalateBody): Observable<any> {
        return this.customService.reactivePatch<any>(`${apiConstants.RFX_FORM.RFX_ESCALATE}`,
            body
        );
    }

    downloadSampleBQExcel(): void {
        this.downloadService.downloadExcel(`${environment.URL + apiConstants.RFX_FORM.SAMPLE_RFX_BOQ}`, 'Bill of Quantity', null);
    }

    downloadBQExcel(boqList: Array<BillOfQuantity>, purchaseOrgId): void {
        this.downloadService.downloadExcel(`${environment.URL + apiConstants.RFX_FORM.EXPORT_RFX_BOQ}?purchaseOrg=${purchaseOrgId}`, 'Bill of Quantity', boqList);
    }

    uploadBQExcel(file: any): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(environment.URL + apiConstants.RFX_FORM.UPLOAD_RFX_BOQ, formData, {
            headers: { Accept: '*/*' },
        });
    }
}
