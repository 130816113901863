<mat-label class="mx-6 px-6 py-3 text-gray-900 font-bold border border-b-0 mt-6 flex justify-between">
    Event Team Member
</mat-label>
<form action="">
    <div class="mx-6 p-6 pb-3 border">
        <div class="md:flex">
            <div class="md:w-6/12 w-full md:pr-5 inline-flex">
                <app-dropdown [options]="userList"
                    [outputPattern]="['name', 'userId']"
                    (changeEvent)="addTeamMember($event)"
                    [disableField]="isFieldDisabled || disableForm "
                    [useDefaultCasing]="true"
                    [dropdownType]="DROPDOWN_TYPE.SEARCH"
                    class="pr-5 w-full"
                    placeholder="Select Team Members"
                    >
                    
                </app-dropdown>
                <mat-checkbox *ngIf="displayReadonlyCheckbox"
                    [formControl]="checkboxFormControl"
                    class="md:w-3/12"
                    color='primary'>
                    Read Only
                </mat-checkbox>
            </div>

            <div class="md:w-6/12 w-full">
                <div class="py-2 px-3 min-h-20 h-auto bg-card border rounded-lg overflow-hidden" *ngIf="selectedMembers.length">
                    <div class="w-full">
                        <div *ngFor="let member of selectedMembers; let i = index"
                            class="flex items-center">
                            <button [disabled]="isFieldDisabled || disableForm "
                                mat-icon-button
                                class=" w-1/12"
                                (click)="removeTeamMember(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <p class="text-base w-full pl-3 "
                                style="white-space: break-spaces"
                                [ngClass]="{'danger' : duplicatedUserId === member.userId}">
                                {{ member.name || member?.user?.name }}
                                ( {{ member.viewStatus | titlecase }} )
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>