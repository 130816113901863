<div class="input-field-container">
    <mat-form-field class="fuse-mat-dense w-full"
        floatLabel="always"
        appearance="fill">
        <mat-label [ngClass]="{'danger' : (control?.touched || control?.dirty) && control?.errors}"
            *ngIf="fieldLabel">
            {{ fieldLabel }}
        </mat-label>
        <input matInput
            [type]="fieldType"
            [placeholder]="placeholder"
            (input)="inputHandler($event.target.value)"
            [required]="isFieldRequired"
            [disabled]="isFieldDisabled"
            [errorStateMatcher]="stateMatcher"
            [ngModel]="initialValue"
            (blur)="onTouched()"
            [ngStyle]="useCapitalize ? { 'text-transform': 'capitalize' } : {'text-transform': '-'}"
            [maxlength]="maxLength ?? ''" />

    </mat-form-field>
    <app-error-message *ngIf="showErrorMessage && control?.control?.errors"
        [control]="control?.control">
    </app-error-message>
</div>