import { curentUserInfo, hasUserLogin } from 'app/helper/shared-function';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrls } from 'app/config';
import { UserByDepartment } from 'app/interfaces/user-interface/user-interface';
import { UserRole } from 'app/interfaces/user-role';
import { User } from 'app/interfaces/users';
import { UserPayload } from 'app/interfaces/users-payload';
import { environment } from 'environments/environment';
import { map, Observable, ReplaySubject, Subject, tap } from 'rxjs';
import * as apiConstants from '../../constants/api-constant';
import { CustomHttpService } from '../common/custom-http-service/custom-http.service';
import { UtilService } from '../util.service';
import { USER_ROLE_ACCESS_MODULE_NAMES } from 'app/enums/common-enum/common-enum';


@Injectable({
    providedIn: 'root',
})
export class UserService {
    url = environment.URL;
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    constructor(
        private http: HttpClient,
        private utils: UtilService,
        private custom_service: CustomHttpService,
    ) { }

    getLoggedUserPayload(): Observable<UserPayload> {
        return this.http.get<UserPayload>(`${ApiUrls.USER_DATA_PAYLOAD}`);
    }

    getUserRoleListByDeptId(departmentId: number): Observable<Array<UserRole>> {
        return this.http.get<Array<UserRole>>(
            `${ApiUrls.USER_ROLE_BY_DEPT_ID}/${departmentId}`
        );
    }

    getUserRoleByDepartmentId(departmentId) {
        return this.http.get<Array<any>>(
            `${ApiUrls.USER_ROLE_BY_DEPARTMENT_ID}/${departmentId}`
        );
    }

    getUniqueUserRoleListByDeptId(departmentId: number): Observable<Array<UserRole>> {
        return this.http.get<Array<UserRole>>(`${ApiUrls.UNIQUE_USER_ROLE_BY_DEPT_ID}/${departmentId}`);
    }

    getUniqueUserRoleLists(): Observable<Array<UserRole>> {
        return this.http.get<Array<UserRole>>(`${ApiUrls.UNIQUE_USER_ROLE_BY_DEPT_ID}`);
    }

    getUniqueUserRoleList(): Observable<Array<UserRole>> {
        return this.http.get<Array<UserRole>>(`${ApiUrls.UNIQUE_USER_ROLE}`);
    }

    getUserList(
        page?: number,
        size?: number,
        searchText?: string
    ): Observable<any> {
        page = !page ? 1 : page;
        size = !size ? 10 : size;
        let url = `${ApiUrls.USER_LIST}?page=${page}&size=${size}`;
        if (!this.utils.isNullOrEmpty(searchText)) {
            url += `&keyword=${searchText}`;
        }
        return this.http.get(`${url}`);
    }

    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        const user = curentUserInfo();
        return this.http.get<User>(`${ApiUrls.SINGLE_USER}/${user.id}`).pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     * @param user
     */
    update(user: User): Observable<any> {
        return this.http.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    createUserRole(role: any): Observable<any> {
        return this.http.post(`${ApiUrls.CREATE_USER_ROLE}`, role);
    }

    updateUserRole(roleId: string, role: any): Observable<any> {
        return this.http.put(`${ApiUrls.UPDATE_USER_ROLE}/${roleId}`, role);
    }

    getUserRoleById(roleId: string): Observable<any> {
        return this.http.get(`${ApiUrls.SINGLE_USER_ROLE}/${roleId}`);
    }

    getUserRoleList(
        page?: number,
        size?: number,
        searchText?: string
    ): Observable<any> {
        page = !page ? 1 : page;
        size = !size ? 10 : size;
        let url = `${ApiUrls.USER_ROLE_LIST}?page=${page}&size=${size}`;
        if (!this.utils.isNullOrEmpty(searchText)) {
            url += `&keyword=${searchText}`;
        }
        return this.http.get(`${url}`);
    }

    getUserById(id) {
        return this.http.get(`${ApiUrls.SINGLE_USER}/${id}`);
    }

    getDepartmentFromUserId(id): Observable<any> {
        return this.http.get(`${ApiUrls.USER_DEPARTMENT}/${id}`);
    }

    updateUserById(userId: any, user?: any): Observable<any> {
        return this.http.put(`${ApiUrls.UPDATE_USERS}/${userId}`, user);
    }

    getUserModuleAccessListDummy(
        page?: number,
        size?: number,
        searchText?: string
    ): Observable<any> {
        let url = `${ApiUrls.USER_MODULE_LIST}?&page=${page ? page : '0'
            }&size=${size ? size : '0'}`;
        if (!this.utils.isNullOrEmpty(searchText)) {
            url += `&keyword=${searchText}`;
        }
        return this.http.get(`${url}`);
    }

    createUserModuleAccess(formData) {
        return this.http.post(`${ApiUrls.CREATE_USER_MODULE}`, formData);
    }

    updateUserModule(moduleId: string, updateModuleData: any) {
        return this.http.put(
            `${ApiUrls.UPDATE_USER_MODULE}/${moduleId}`,
            updateModuleData
        );
    }

    getModuleById(moduleId: string): Observable<any> {
        return this.http.get(`${ApiUrls.SINGLE_USER_MODULE}/${moduleId}`);
    }

    getPurchasingList(): Observable<any> {
        return this.http.get(`${ApiUrls.USER_PURCHASING_LIST}`);
    }

    getPrePrList(): Observable<any> {
        return this.http.get(`${ApiUrls.USER_PRE_PR_LIST}`);
    }

    getPrList(): Observable<any> {
        return this.http.get(`${ApiUrls.USER_PR_LIST}`);
    }

    getRfxList(): Observable<any> {
        return this.http.get(`${ApiUrls.USER_RFX_LIST}`);
    }

    getApprovalManagementList(): Observable<any> {
        return this.http.get(`${ApiUrls.USER_APPROVAL_MANAGEMENT_LIST}`);
    }

    getUserRoleByDept(deptId, userId): Observable<any> {
        return this.http.get(`${ApiUrls.USER_ROLE_BY_DEPT}/${deptId}/${userId}`);
    }

    getPurchOrgbyId(id) {
        return this.http.get(`${ApiUrls.SINGLE_PURCHASING_LIST}/${id}`);
    }

    getPrById(id): Observable<any> {
        return this.http.get(`${ApiUrls.SINGLE_PRE_PR_LIST}/${id}`);
    }

    getPrePrById(id): Observable<any> {
        return this.http.get(`${ApiUrls.SINGLE_PR_LIST}/${id}`);
    }

    getRfxById(id): Observable<any> {
        return this.http.get(`${ApiUrls.SINGLE_RFX_LIST}/${id}`);
    }

    getUsersByDepartment(departmentId: number): Observable<UserByDepartment[]> {
        return this.custom_service.reactiveGet<UserByDepartment[]>(apiConstants.USER.USERS_BY_DEPARTMENT + '/' + departmentId)
            .pipe(map((res) => {
                return res.map((re) => {
                    re.userId = re.id
                    return re
                })
            }))
    }

    uploadUserProfilePic(formData: FormData): Observable<any> {
        return this.http.post<any>(`${ApiUrls.UPLOAD_PROFILE_PIC}`, formData, {
            headers: { Accept: '*/*' }
        });
    }

    updateUserProfile(formData: { id: number; contactNumber?: string; profileImg?: string }): Observable<any> {
        return this.http.put<any>(`${ApiUrls.UPDATE_USER_PROFILE}`, formData);
    }

    getAuditHistoryList(params?: HttpParams): Observable<any> {
        return this.http.get(`${ApiUrls.USER_PROFILE_AUDIT_HISTORY}`, { params: params })
    }




    getUserModuleAccessList(
        page?: number,
        size?: number,
        searchText?: string
    ): Observable<any> {
        let url = `${ApiUrls.USER_GET_LIST_ACCESS_RIGHTS}?&page=${page ? page : '0'}&size=${size ? size : '1000'}`;

        if (!this.utils.isNullOrEmpty(searchText)) {
            url += `&keyword=${searchText}`;
        }
        return this.http.get(`${url}`);
    }

    getUserAccessRights(): Observable<any> {
        let url = `${ApiUrls.USER_GET_ACCESS_RIGHTS}`;
        return this.http.get(`${url}`);
    }

    createUserAccessRights(formData) {
        return this.http.post(`${ApiUrls.USER_CREATE_ACCESS_RIGHTS}`, formData);
    }

    updateUserAccessRights(moduleId: string, updateModuleData: any) {
        return this.http.put(
            `${ApiUrls.USER_UPDATE_ACCESS_RIGHTS}/${moduleId}`,
            updateModuleData
        );
    }

    getViewUserAccessRights(moduleId: string): Observable<any> {
        return this.http.get(`${ApiUrls.USER_GET_VIEW_ACCESS_RIGHTS}/${moduleId}`);
    }

    async getCheckAndSetUserRole() {
        sessionStorage.removeItem('user-role');

        if (!hasUserLogin()) {
            return;
        }

        let permissionArray = {};
        let permissionArraySub = {};
        if (sessionStorage.getItem('user-role') === null || sessionStorage.getItem('user-role') == "{}") {
            if (curentUserInfo().roleId) {
                await this.getUserRoleById(curentUserInfo().roleId.toString()).subscribe(res => {
                    res.roleModuleAccess.forEach(element => {
                        if (element?.module?.status === 'Active') {
                            if (element.module && element.module != null && element.module.selectedParentRights && element.module.selectedParentRights != null) {
                                element.module.selectedParentRights.forEach(subElement => {
                                    if (subElement.selectedSubModule && subElement.selectedSubModule.length > 0) {
                                        if (!permissionArraySub[subElement.parentModule.name]) {
                                            permissionArraySub[subElement.parentModule.name] = {};
                                        }
                                        subElement.selectedSubModule.forEach(innerElement => {
                                            if (!permissionArraySub[subElement.parentModule.name][innerElement.subModule.name]) {
                                                permissionArraySub[subElement.parentModule.name][innerElement.subModule.name] = [];
                                            }
                                            if (innerElement?.subModule?.status == 'Active' && innerElement?.function?.status == 'Active') {
                                                permissionArraySub[subElement.parentModule.name][innerElement.subModule.name].push(innerElement.function.name);
                                                // if(permissionArraySub[subElement.parentModule.name][innerElement.subModule.name]?.length == 0){
                                                //     permissionArraySub[subElement.parentModule.name][innerElement.subModule.name].push(innerElement.function.name)
                                                // }else{
                                                //     permissionArraySub[subElement.parentModule.name][innerElement.subModule.name] = this.userAccessCheckHighPermissionWise(permissionArraySub[subElement.parentModule.name][innerElement.subModule.name], innerElement.function.name);
                                                // }
                                            }
                                        });
                                    } else {
                                        if (!permissionArray[subElement.parentModule.name]) {
                                            permissionArray[subElement.parentModule.name] = [];
                                        }
                                        if (subElement?.parentModule?.status == 'Active' && subElement?.function?.status == 'Active') {
                                            // if(permissionArray[subElement.parentModule.name]?.length == 0){
                                            //     permissionArray[subElement.parentModule.name].push(subElement.function.name)
                                            // }else{
                                            //     permissionArray[subElement.parentModule.name] = this.userAccessCheckHighPermissionWise(permissionArray[subElement.parentModule.name], subElement.function.name);
                                            // }
                                            permissionArray[subElement.parentModule.name].push(subElement.function.name);
                                        }
                                    }
                                });
                            }
                        }
                    });

                    Object.keys(permissionArray).map(module => {
                        permissionArray[module] = this.getArrangeUserAccessArray(permissionArray[module]);
                    });

                    Object.keys(permissionArraySub).map(module => {
                        Object.keys(permissionArraySub[module]).map(submodule => {
                            permissionArraySub[module][submodule] = this.getArrangeUserAccessArray(permissionArraySub[module][submodule]);
                        });
                    });
                    permissionArray = Object.assign({}, permissionArray, permissionArraySub);

                    sessionStorage.setItem('user-role', JSON.stringify(permissionArray));
                });
            }
        }
    }

    getArrangeUserAccessArray(array){
        let tempArray= [];
        if (array.includes(USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR)) {
            tempArray.push(USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR);
        }
        if (array.includes(USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR_VIEWER)) {
            tempArray.push(USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR_VIEWER);
        }
        if (array.includes(USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_ADMINISTRATOR)) {
            tempArray.push(USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_ADMINISTRATOR);
        }
        if (array.includes(USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_VIEWER)) {
            tempArray.push(USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_VIEWER);
        }
        if (array.includes(USER_ROLE_ACCESS_MODULE_NAMES.NORMAL)) {
            tempArray.push(USER_ROLE_ACCESS_MODULE_NAMES.NORMAL);
        }

        return tempArray;
    }

    getUniqueUsers(): Observable<any> {
        return this.custom_service.reactiveGet(apiConstants.USER.UNIQUE_USER_ROLE)
    }

    userAccessCheckHighPermissionWise(arrayItem, item) {
        if (arrayItem[0] == USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR || item == USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR) {
            return [USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR];
        }
        if (arrayItem[0] == USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR_VIEWER || item == USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR_VIEWER) {
            return [USER_ROLE_ACCESS_MODULE_NAMES.ADMINISTRATOR_VIEWER];
        }
        if (arrayItem[0] == USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_ADMINISTRATOR || item == USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_ADMINISTRATOR) {
            return [USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_ADMINISTRATOR];
        }
        if (arrayItem[0] == USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_VIEWER || item == USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_VIEWER) {
            return [USER_ROLE_ACCESS_MODULE_NAMES.DEPARTMENT_VIEWER];
        }
        return [USER_ROLE_ACCESS_MODULE_NAMES.NORMAL];
    }
}
