import { INPUT_FIELD_TYPE } from './../../../../../enums/form-enum/form-enum';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
    Question,
    Questionnaire,
} from 'app/interfaces/rfx-interface/questionnaire.interface';
import { generateArrayFromEnum, removeNullIdFromObject } from 'app/helper/shared-function';
import { ANSWER_TYPE } from 'app/enums/rfx-enum/rfx.enum';
import { UtilService } from 'app/core/services/util.service';
import { EVALUATION_MAPPING } from 'app/enums/common-enum/common-enum';
import { TitleCasePipe } from '@angular/common';
import { ADD_OR_UPDATE } from '../questionnaire-form.component';

const evaluationMappingList = [
    // { name: EVALUATION_MAPPING.QUALITY_SPECS, validation: null },
    { name: EVALUATION_MAPPING.LEAD_TIME, validation: 'required' },
    // { name: EVALUATION_MAPPING.COST, validation: null },
    { name: EVALUATION_MAPPING.WARRANTY, validation: 'required' },
];

interface QuestionMatData {
    formValue: Question;
    set: Questionnaire;
    previousQuestions: Question[];
    submitForm: Function;
    config: { disableEvalutionMapping: boolean; action: ADD_OR_UPDATE };
}

@Component({
    selector: 'app-qestion-form',
    templateUrl: './qestion-form.component.html',
    styleUrls: ['./qestion-form.component.scss'],
})
export class QestionFormComponent implements OnInit {
    questionForm: FormGroup;
    answerTypeList = generateArrayFromEnum(ANSWER_TYPE);

    evaluationList = evaluationMappingList;
    ANSWER_TYPE_ENUM = ANSWER_TYPE;
    INPUT_FIELD_TYPE = INPUT_FIELD_TYPE;
    selectedAnswerType: string; // selected value form answer type dropdown

    extractedAnswers: string[] = [ANSWER_TYPE.CHOICE, ANSWER_TYPE.CHECKBOX];
    evalutionError: string = '';

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private titleCasePipe: TitleCasePipe,
        private utils: UtilService,
        @Inject(MAT_DIALOG_DATA) public questionData: QuestionMatData
    ) {}

    ngOnInit(): void {
        this.initQuestionForm();
        this.patchQuesitonForm();

        if (this.questionData.config.action === ADD_OR_UPDATE.ADD) {
            this.questionForm
                .get('questionSequence')
                .setValue(this.questionData.previousQuestions.length + 1);
        }
    }

    initQuestionForm() {
        this.questionForm = this.fb.group({
            questionSequence: 1,
            id: null,
            question: ['', [Validators.required, Validators.maxLength(250)]],
            answerType: ['', Validators.required],
            evaluationMapping: [''],
            isRequired: false,
            canSupplierAttachDocument: false,
            isAttachmentRequired: false,
        });
    }

    evalutionMappingEvent(mapping: EVALUATION_MAPPING) {
        if (!this.questionData.set) return;

        const evaluationMappingMap = new Map<string, number>();
        this.questionData.set.sections.forEach((section) => {
            section.questions.forEach((question) => {
                if (question.evaluationMapping === mapping) {
                    if (evaluationMappingMap.has(question.evaluationMapping)) {
                        evaluationMappingMap.set(
                            question.evaluationMapping,
                            evaluationMappingMap.get(
                                question.evaluationMapping
                            ) + 1
                        );
                    } else {
                        evaluationMappingMap.set(question.evaluationMapping, 1);
                    }
                }
            });
        });

        const duplicateMappingNames = [];
        for (const key of evaluationMappingMap.keys()) {
            if (evaluationMappingMap.get(key) > 0) {
                duplicateMappingNames.push(this.titleCasePipe.transform(key));
            }
        }

        if (duplicateMappingNames.length > 0) {
            this.evalutionError = `${duplicateMappingNames.join(
                ','
            )} is already selected.`;
            this.utils.showErrorSnack(this.evalutionError);
            return;
        } else {
            this.evalutionError = '';
        }

        // if (!this.questionData.previousQuestions.length) return

        // this.questionData.previousQuestions.forEach((question) => {
        //     if (question.evaluationMapping === mapping) {

        //         this.evalutionError = true
        //         this.utils.showErrorSnack(`${mapping} Is Already Selected`)
        //         this.questionForm.get('evaluationMapping').setValue('')

        //         return
        //     }

        //     this.evalutionError = false
        // })
    }

    choiceWithScoreForm(): FormGroup {
        return this.fb.group({
            id: null,
            score: [''],
            value: ['',Validators.required],
        });
    }
    
    patchQuesitonForm() {
        const questionInfo: Question = this.questionData.formValue;
        if (!questionInfo) return;

        this.questionForm.patchValue(questionInfo);

        questionInfo.answerType
            ? this.controlBasedOnselectedAnswer(
                  questionInfo.answerType as ANSWER_TYPE
              )
            : null;

        if (this.extractedAnswers.includes(questionInfo.answerType)) {
            this.choices.clear();
            questionInfo.choices?.forEach((choice, index) => {
                this.addMoreOption();
                this.choices.controls[index].patchValue(choice);
            });
            return;
        }

        if (questionInfo.answerType === ANSWER_TYPE.CHOICE_WITH_SCORE) {
            this.choiceWithScore.clear();
            questionInfo.scoreChoices?.forEach((choice, index) => {
                this.addMoreScoreWithChoice();
                this.choiceWithScore.controls[index].patchValue(choice);
            });
        }
    }

    controlBasedOnselectedAnswer(answer: ANSWER_TYPE) {
        this.selectedAnswerType = answer;
        this.questionForm.removeControl('choices');
        this.questionForm.removeControl('scoreChoices');

        // adding control in questionForm based on extractedAnswers
        if (this.extractedAnswers.includes(answer)) {
            this.questionForm.addControl(
                'choices',
                this.fb.array([this.fb.control('',Validators.required)], Validators.required)
            );
            return;
        }

        if (answer === ANSWER_TYPE.CHOICE_WITH_SCORE) {
            this.questionForm.addControl(
                'scoreChoices',
                this.fb.array([this.choiceWithScoreForm()],Validators.required)
            );
        }

        if (answer === ANSWER_TYPE.FILE_ATTACHMENT) {
            this.questionForm.get('canSupplierAttachDocument').setValue(true);
            this.questionForm.get('isRequired').disable();
            this.questionForm.get('isRequired').updateValueAndValidity();
        } else {
            this.questionForm.get('canSupplierAttachDocument').setValue(false);
            this.questionForm.get('isRequired').enable();
            this.questionForm.get('isRequired').updateValueAndValidity();
        }
    }

    get choices() {
        return this.questionForm.get('choices') as FormArray;
    }

    get choiceWithScore() {
        return this.questionForm.get('scoreChoices') as FormArray;
    }

    addMoreOption() {
        this.choices.push(this.fb.control('', Validators.required));
    }
    addMoreScoreWithChoice() {
        this.choiceWithScore.push(this.choiceWithScoreForm());
    }
    removeOption(index: number) {
        this.choices.removeAt(index);
    }
    removeChoiceWithScore(index: number) {
        this.choiceWithScore.removeAt(index);
    }

    saveQuestion() {
        if (!this.questionForm.valid) {
            this.questionForm.markAllAsTouched();
            return;
        }
        if (this.evalutionError !== '') {
            this.utils.showErrorSnack(this.evalutionError);
            return;
        }

        const questionValue = this.questionForm.value;
        removeNullIdFromObject(questionValue, 'id')  
        this.questionData.submitForm(this.questionForm.value);
        this.dialog.closeAll();
    }
}
