import { AbstractControl, FormGroupDirective, NgForm, ValidationErrors } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ERROR_KEY } from "app/enums/custom-validator-key-enum/validator-error-key.enum";
import { Section } from "app/interfaces/supplier-onboarding/supplier-onboarding-list.interface";

export class CustomFieldErrorMatcher implements ErrorStateMatcher {
    constructor(private customControl: AbstractControl) { }
    isErrorState(control: AbstractControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return this.customControl && (this.customControl.touched || this.customControl.dirty) && this.customControl.invalid;
    }
}

// validation is not working is if default value is false so here is the solution.
export function validateFalseValue(control: AbstractControl): ValidationErrors | null {
    if (control.value === false) return { [ERROR_KEY.REQUIRED]: true }
    return null;
}

export function numberMaxLength(maxLen: number): ValidationErrors | null {
    return function (control: AbstractControl) {
        if (!control.value) return null;
        const controlValue = control.value.toString()
        const maxLength = Array.from(Array(maxLen).keys())

        if (controlValue.length > maxLength.length) {
            return {
                touched: true,
                maxlength: {
                    [ERROR_KEY.ACTUAL_LENGTH]: controlValue.length,
                    [ERROR_KEY.REQUIRED_LENGTH]: maxLen,
                },
            }
        }
    }
}

export function decimalValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const controlValue = control.value.toString() as string

    if (controlValue.includes('.')) return { [ERROR_KEY.DECIMAL_ERROR]: true }
    else return null
}

export function numberValidator(control: AbstractControl): ValidationErrors | null {
    if (isNaN(control.value)) {
        return { [ERROR_KEY.NUMBER_VALIDATION]: true }
    }
    return null
}

export function duplicateErr(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
        return { [ERROR_KEY.DUPLICATE_ERROR]: true }
    }
    return null
}

export function emptyArrValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value?.length) {
        return { emptyArrayError: true }
    }
    return null
}

export function defaultError(control: AbstractControl): ValidationErrors | null {
    return { defaultError: true }
}

export function specialCharacterValidator() {
    const regex = new RegExp('^[0-9]*$');

    return function (control: AbstractControl): ValidationErrors | null {
        if (!control.value) return null;

        if (regex.test(control.value)) {
            return null
        }

        else return { [ERROR_KEY.NUMBER_VALIDATION]: "Please provide number only" }
    }

}

/**
 * This validator only validates sections and questions.
 */
export function questionnaireValidator(control: AbstractControl): ValidationErrors | null {

    const questionnaire = control.value;
    let err = null;

    if(!questionnaire || !questionnaire.length ){
        return {[ ERROR_KEY.SECTION_ERROR] : 'Please add section' }
    }

    questionnaire.forEach((ques: Section) => {
        if (!ques.questions.length) {
            err = { [ERROR_KEY.QUESTION_ERROR]: 'Please add question' };
            return
        }
        else err = null;
    });

    return err

}