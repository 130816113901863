import { AfterViewInit, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { INPUT_FIELD_TYPE } from 'app/enums/form-enum/form-enum';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';


@Component({
    selector: 'app-input-field',
    templateUrl: './input-field.component.html',
    styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements ControlValueAccessor, Validators, AfterViewInit {
    @Input() fieldLabel: string
    @Input() fieldType: INPUT_FIELD_TYPE = INPUT_FIELD_TYPE.TEXT
    @Input() placeholder: string
    @Input() useCapitalize: boolean = false
    @Input() maxLength: number // to show character limit
    @Input() showErrorMessage: boolean = true;

    @Output() changeEvent = new EventEmitter<string | number>()

    stateMatcher: ErrorStateMatcher
    inputFieldType = INPUT_FIELD_TYPE

    initialValue: string | number = null
    isFieldRequired: boolean
    isFieldDisabled: boolean = false



    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    onChange = (value: string | number) => { };
    onTouched = () => { };

    inputHandler(inputValue: string) {
        this.initialValue = inputValue
        if (this.fieldType === this.inputFieldType.NUMBER) {
            this.onChange(Number(inputValue))
            this.changeEvent.next(Number(inputValue))
            return
        }

        this.onChange(inputValue || null)
        this.changeEvent.next(inputValue || null)
    }

    writeValue(value: string | number): void {
        this.initialValue = value || null
    }

    registerOnChange(fn: any): void { this.onChange = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }
    setDisabledState?(isDisabled: boolean): void { this.isFieldDisabled = isDisabled }

    ngAfterViewInit(): void {
        const timeId = setTimeout(() => {
            if (this.control) {
                this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                this.isFieldRequired = this.control?.control?.hasValidator(Validators.required)
                clearTimeout(timeId)
            }
        })
    }

}
