import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { AfterViewInit, Component, Input, Optional, Self, OnChanges, Output, EventEmitter, SimpleChanges, NgZone } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomFieldErrorMatcher } from 'app/helper/custom-validators';



@Component({
    selector: 'app-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
    @Input() fieldLabel: string
    @Input() placeholder: string
    @Input() searchPlaceHolder: string
    @Input() useUppercase: boolean = false
    @Input() disableField: boolean = false 
    @Input() useDefaultCasing: boolean;
    @Input() useSearch:boolean = true

    @Input() options: any[] = []
    @Input() selectedOptions: string[] = []
    @Input() optionsToDisable: string | number[] = []
    @Input() outputPattern: any[] = []
    @Input() optionViewKey: string = 'name'
    @Input() required: boolean 


    @Output() changeEvent = new EventEmitter()
    @Output() closeEvent = new EventEmitter()

    stateMatcher: ErrorStateMatcher

    searchTerm = null;
    isFieldRequired: boolean
    isFieldDisabled: boolean

    filteredOptions: any[] = []
    selectedResults: string[] = []

   
    constructor(@Self() @Optional() public control: NgControl, private zone: NgZone) {
        this.control && (this.control.valueAccessor = this);
    }

    updateMainForm = (value: any) => { }
    onTouched = () => { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) this.filteredOptions = this.options

        if (changes.selectedOptions) {
            !this.selectedOptions.length && (this.selectedResults = this.selectedOptions);
            this.selectedOptions.length && this.checkDropdownOptions(this.selectedOptions);
        };


        if (this.searchTerm) {
            this.searchHandler(this.searchTerm);
        };
    }

    
    searchHandler(input: string) {
        this.searchTerm = input;
        this.filteredOptions = this.options.filter((el: any) => {
            if (el[this.optionViewKey].toLowerCase().includes(input.toLowerCase())) {
                return el
            }
        })
    }

    matSearchClear() {
        this.filteredOptions = this.options;
    }

    checkboxHandler(selected: string[]) {
        const result: { [key: string]: string | number }[] = []

        const selectedOptionData = selected.map((value: string) => {
            return this.options.find((el: any) => value === el[this.optionViewKey])
        })

        if (this.outputPattern && this.outputPattern.length && this.outputPattern[0].length === 0) {
            const premitiveResult = selectedOptionData.map((data) => {
                return data[this.outputPattern[1]]
            })

            this.updateProcesssedData(premitiveResult)
            return
        }

        if (this.outputPattern.length && typeof this.outputPattern[0] !== 'object') {
            selectedOptionData.forEach((selectedValue: any) => {
                const patternBasedObject: { [key: string]: string | number } = {}
                this.outputPattern.forEach((pattern: string) => {
                    patternBasedObject[pattern] = selectedValue[pattern]
                })
                result.push(patternBasedObject)
            })

            this.updateProcesssedData(result)
            return
        }

        if (typeof this.outputPattern[0] === 'object') {
            this.updateProcesssedData(selectedOptionData)
            return
        }
        
        this.updateProcesssedData(selected.length ? selected : null )
    }

    checkDropdownOptions(defaultReslut: any) {
        if (!defaultReslut) return

        const outputPattern = typeof this.outputPattern[0] === 'string' ? this.outputPattern[0] : this.optionViewKey
        const defaultResArr = defaultReslut.map((el: any) => {

            if (!this.options) return
            if (!el[outputPattern]) return

            const matchedOptions = this.options.find((allEl) => allEl[outputPattern] === el[outputPattern])
            if (matchedOptions) return matchedOptions[this.optionViewKey]

        })
        const filterdRes = defaultResArr.filter((el: string) => el)
        filterdRes.length && (this.selectedResults = defaultResArr)

    }

    updateProcesssedData(data: any) {
        this.updateMainForm(data)
        this.changeEvent.next(data)
    }

    pannelToggle(isOpen: boolean) {
        if (!isOpen) {
            this.filteredOptions = this.options
        }
    }

    writeValue(defaultValue: any): void {
        this.selectedResults = defaultValue
        if (!defaultValue) return


        if (this.searchTerm) {
            this.searchHandler(this.searchTerm);
        };

        const counter = setInterval(() => {
            if (defaultValue && defaultValue?.length && this.options?.length) {
                this.checkDropdownOptions(defaultValue)
                clearInterval(counter)
            }
        }, 500)

    }

    registerOnChange(fn: any): void { this.updateMainForm = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }
    setDisabledState?(isDisabled: boolean): void {
        this.isFieldDisabled = isDisabled
    }

    ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            const timer = setTimeout(() => {
                if (this.control) {
                    this.stateMatcher = new CustomFieldErrorMatcher(this.control.control);
                    this.isFieldRequired = this.control?.control?.hasValidator(Validators.required)
                    clearTimeout(timer)
                }
            }, 100)
        })
    }

    closedMatSelect() {
        this.closeEvent.next(true)
    }
}
