import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RfxService } from 'app/core/services/rfx-service/rfx/rfx.service';
import { UtilService } from 'app/core/services/util.service';
import { RfxApprovalStageEnum } from 'app/enums/rfx-enum/rfx-approval-stage-enum.enum';

@Component({
    selector: 'app-rfx-escalate-popup',
    templateUrl: './rfx-escalate-popup.component.html',
    styleUrls: ['./rfx-escalate-popup.component.scss']
})
export class RfxEscalatePopupComponent implements OnInit {

    escalateForm: FormGroup;
    showValidation = false;
    rfxApprovalStageEnum = RfxApprovalStageEnum;
    maxRemarkLength = 500;

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<RfxEscalatePopupComponent>,
        private rfxService: RfxService,
        private utils: UtilService,
        @Inject(MAT_DIALOG_DATA)
        private dialogData: {
            rfxId: number;
            sourcingApprovalStage: RfxApprovalStageEnum;
            envelopeSequence?: number;
        }
    ) { }

    ngOnInit(): void {
        this.escalateForm = this.formBuilder.group({
            rfxId: [this.dialogData?.rfxId],
            remark: ['', [Validators.required, Validators.maxLength(this.maxRemarkLength)]],
            action: ['Escalated'],
            sourcingApprovalStage: [this.dialogData?.sourcingApprovalStage]
        });

        if (this.dialogData?.sourcingApprovalStage === this.rfxApprovalStageEnum.SOURCING_EVALUATION_APPROVAL) {
            this.escalateForm.addControl('envelopeSequence',
                new FormControl(this.dialogData?.envelopeSequence, [Validators.required]));
        }
    }

    onSubmit(): void {
        if (this.escalateForm.invalid) {
            this.showValidation = true;
            return;
        }
        this.showValidation = false;

        this.rfxService.sourcingEscalate(this.escalateForm.value).subscribe({
            next: (response) => {
                this.utils.showSuccessSnack(response.message);
                this.dialogRef.close(true);
            },
            error: (err: any) => {
                console.error(err);
            }
        });
    }

}
