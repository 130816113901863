<div class="input-field-container">
    <mat-form-field class="fuse-mat-dense w-full" floatLabel="always" appearance="fill">
        <mat-label [ngClass]="{'danger' : (control?.touched || control?.dirty) && control?.errors}" *ngIf="fieldLabel">
            {{ fieldLabel }}
        </mat-label>
        
        <input matInput 
        type="text" 
        [allowNegativeNumbers]="allowNegatives"
        [showMaskTyped]="false"  
        [mask]="decimalPlaceSupport" 
        thousandSeparator="," 
        separatorLimit="9999999999999" 
        [autocomplete]="false" 
        [placeholder]="placeholder" 
        (input)="inputHandler()" 
        [required]="isFieldRequired" 
        [disabled]="isFieldDisabled" 
        [errorStateMatcher]="stateMatcher" 
        [(ngModel)]="inputValue" 
        (blur)="onTouched()" 
        [maxlength]="maxLength ?? ''" 
        />

    </mat-form-field>
    <app-error-message *ngIf="showErrorMessage && control?.control?.errors" [control]="control?.control"></app-error-message>
</div>