import { curentUserInfo } from 'app/helper/shared-function';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { Router } from '@angular/router';
import { LoaderService } from 'app/core/services/loader.service';

// prevent to run loader in these apis
const endpoints = [
    'api/buyer/rfx-form/update',
    'api/buyer/supplier-form-response/evaluate',
    'api/buyer/supplier-form/check/isvalidform',
    'api/buyer/supplier-form/create',
    'api/buyer/supplier-form/update'
]

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private loader: LoaderService
    ) {
    }

    isValidUrl(urlString: string) {
        try {
            return Boolean(new URL(urlString));
        }
        catch (e) {
            return false;
        }
    }

    getURLPath = (url: string) => {
        if (this.isValidUrl(url)) {
            const tempURL = new URL(url);
            return tempURL.pathname.toLowerCase().replace(/^\/|\/$/gm, "");
        }
    };


    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newReq = req.clone();
        if (this._authService.accessToken) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
            });
        }

        const currentUrl = this.getURLPath(req.url)
        if(!endpoints.includes(currentUrl)){            
            this.loader.setLoading(true, req.url);
        }


        
        // Response
        return next.handle(newReq).pipe(
            map((evt) => {
                if (evt instanceof HttpResponse) {
                    this.loader.setLoading(false, req.url);
                }
                return evt;
            }),

            catchError((error) => {
                this.loader.setLoading(false, req.url);
                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {

                    // Sign out
                    this._authService.signOut().subscribe();

                    sessionStorage.clear();
                    localStorage.clear();
                    this._router.navigate(['sign-in']);

                    // Reload the app
                    // location.reload();
                }
                // SAP response redirect issue
                // else if (error instanceof HttpErrorResponse && error.status === 403) { // done by sudhanshu
                //     this._router.navigate(['/404']);
                // }

                return throwError(error);
            })
        );
    }
}
