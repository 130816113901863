<form action="" [formGroup]="updateDocForm">
    <div style="width: 500px">
    <mat-dialog-content class="mat-typography">
        <button mat-icon-button class="close-button float-right" mat-dialog-close>
            <mat-icon class="close-icon">close</mat-icon>
        </button>
        <mat-form-field class="fuse-mat-dense w-10/12 mr-3" appearance="fill" style="margin-top: 15px">
            <mat-label>Enter Description</mat-label>
            <textarea formControlName="description" rows="5" cols="1000" matInput style="width: 100%"></textarea>
        </mat-form-field>
        <mat-checkbox (change)="handleAvailabitly($event.checked)"
            [checked]="updateDocForm.get('availability').value === DocumentType.INTERNAL" class="flex flex-row my-4"
            [color]="'primary'">
            Internal Document
        </mat-checkbox>
        <div class="float-right">
            <button type="button" mat-button mat-flat-button
                class="mat-focus-indicator bg-accent-700 mat-flat-button mat-button-base mat-primary mat-button"
                (click)="updateForm()" mat-dialog-close> Update
            </button>
        </div>
    </mat-dialog-content>
</div>
</form>