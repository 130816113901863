<mat-form-field class="fuse-mat-dense w-full"
    floatLabel="always">
    <mat-label [ngClass]="{'danger' : (control?.touched || control?.dirty) && control?.errors}"
        *ngIf="fieldLabel">
        {{ fieldLabel }}
    </mat-label>
    <input matInput
        [(ngModel)]="defaultTime"
        [placeholder]="placeholder"
        id="native-time" 
        [style.cursor]="'pointer'"
        [ngClass]="{'disabled-color' : isFieldDisabled}"
        [errorStateMatcher]="stateMatcher"
        [required]="isFieldRequird"
        (blur)="onTouched()"
        (change)="timeEvent($event.target.value)"
        type="time" />

</mat-form-field>


<!-- <mat-form-field class="fuse-mat-dense w-full"
    floatLabel="always">
    <mat-label [ngClass]="{'danger' : (control?.touched || control?.dirty) && control?.errors}"
        *ngIf="fieldLabel">
        {{ fieldLabel }}
    </mat-label>
    <input matInput
        [ngxTimepicker]="timePickerOne"
        [required]="isFieldRequird"
        [format]="12"
        [errorStateMatcher]="stateMatcher"
        placeholder="HH:MM AM/PM"
        [(ngModel)]="defaultTime"
        [readonly]="true"
        (blur)="onTouched()"
        [disabled]="isFieldDisabled"
        [style.cursor]="'pointer'"
        [ngClass]="{'disabled-color' : isFieldDisabled}"
        #timeField />
    <mat-icon [style.cursor]="'pointer'"
        (click)="timeField.click()"
        matSuffix>schedule</mat-icon>
    <ngx-material-timepicker (timeSet)="timeEvent($event)"
        #timePickerOne></ngx-material-timepicker>
</mat-form-field> -->




<!-- <input type="time"> -->