import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImgViewerInput } from 'app/interfaces/common-interface/common-interface';


@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

    imgSrc: string = '';

    constructor(
        public dialogRef: MatDialogRef<ImageViewerComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogInput: ImgViewerInput
    ) { }

    ngOnInit(): void {
        if(this.dialogInput){
            this.imgSrc = this.dialogInput.imgSrc;
        }
    }
}
