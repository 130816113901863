<mat-label class="pl-6 pr-2 font-bold text-black block border-b flex justify-between items-center">Question
    <button mat-icon-button mat-dialog-close>
        <mat-icon class="text-black">cancel</mat-icon>
    </button>
</mat-label>
<form action="" [formGroup]="questionForm">
    <div class="mx-6">
        <div class="mt-6 md:flex">
            <div class="md:w-3/12 w-full">
                <mat-label class="font-bold text-black">Question *</mat-label>
            </div>
            <div class="md:w-9/12 w-full">
                <app-input-field formControlName="question" placeholder="Question" [maxLength]="250"></app-input-field>
            </div>
        </div>
        <div class="mt-3 md:flex">
            <div class="md:w-3/12 w-full">
                <mat-label class="font-bold text-black">Answer Type *</mat-label>
            </div>
            <div class="md:w-9/12 w-full">
                <app-dropdown (changeEvent)="controlBasedOnselectedAnswer($event)" formControlName="answerType"
                    [options]="answerTypeList" placeholder="Answer Type">
                </app-dropdown>
            </div>
        </div>
        <div class="mt-3 mb-2 md:flex" *ngIf="!questionData?.config?.disableEvalutionMapping" >
            <div class="md:w-3/12 w-full">
                <mat-label class="font-bold text-black">Evaluation Mapping</mat-label>
            </div>
            <div class="md:w-9/12 w-full">
                <app-dropdown formControlName="evaluationMapping" [options]="evaluationList" [outputPattern]="['name']"
                    placeholder="Evaluation Mapping" (changeEvent)="evalutionMappingEvent($event)" >
                </app-dropdown>
            </div>
        </div>

        <!-- ng-container will display based on answer type dropdown for example text is selected text ng-container will display -->
        <ng-container *ngIf="selectedAnswerType === ANSWER_TYPE_ENUM.CHOICE && questionForm.get('choices')"
            formArrayName="choices">
            <div class="flex items-center justify-between border-b" [ngClass]="{'border-rose-600' : choice.errors && choice.touched }"
                *ngFor="let choice of questionForm.get('choices').controls; let i = index">
                <mat-radio-button value="1" class="block">
                    <input type="text" [formControlName]="i" class="pl-3" [placeholder]="'Option ' + ( i + 1 )">
                    <span *ngIf="choice.errors && choice.touched" class="choice-error" >Required Field.</span>
                </mat-radio-button>
                <button mat-icon-button (click)="removeOption(i)">
                    <mat-icon>close</mat-icon>
                </button>
                <!-- <ng-container *ngIf="choice?.errors?.required===true">At least 1 answer option is required for question creation</ng-container> -->

            </div>
            <p *ngIf="questionForm.get('choices').errors" class="text-red-700" > At least 1 answer option is required for question creation </p>
            <button class="block mt-3" (click)="addMoreOption()">Add More Choice</button>
        </ng-container>

        <ng-container
            *ngIf="selectedAnswerType === ANSWER_TYPE_ENUM.CHOICE_WITH_SCORE && questionForm.get('scoreChoices') "
            formArrayName="scoreChoices">
            <div class="flex w-full" *ngFor="let opt of questionForm.get('scoreChoices').controls; let i = index"
                [formGroupName]="i">
                <div class="w-3/12 pr-5">
                    <mat-form-field class="fuse-mat-dense w-full" >
                        <mat-label>Option Score</mat-label>
                        <input matInput type="number" formControlName="score" class="pl-3" [maxLength]="100" >
                    </mat-form-field>
                    <!-- <app-input-field fieldLabel="Option Score" formControlName="score"
                        [fieldType]="INPUT_FIELD_TYPE.NUMBER">
                    </app-input-field> -->
                </div>
                <div class="w-9/12">
                    <mat-label class="font-medium">Option value</mat-label>
                    <div class="flex items-center justify-between border-b " [ngClass]="{'border-rose-600' : !opt.valid && opt.touched }">
                        <mat-radio-button value="1" class="block w-full">
                            <input type="text" formControlName="value" class="pl-3 w-full"
                                [placeholder]="'Option ' + ( i + 1 )">
                                <span *ngIf="!opt.valid && opt.touched" class="scoreChoice-error" >Required Field.</span>
                        </mat-radio-button>
                        <button mat-icon-button (click)="removeChoiceWithScore(i)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <p *ngIf="questionForm.get('scoreChoices').errors" class="text-red-700" > At least 1 answer option is required for question creation </p>
            <button class="block mt-3" (click)="addMoreScoreWithChoice()">Add More Option</button>
        </ng-container>

        <ng-container *ngIf="selectedAnswerType === ANSWER_TYPE_ENUM.CHECKBOX && questionForm.get('choices')"
            formArrayName="choices">
            <div class="flex items-center justify-between border-b" [ngClass]="{'border-rose-600' : choice.errors && choice.touched }" 
                *ngFor="let choice of questionForm.get('choices').controls; let i = index">
                <mat-checkbox class="w-full">
                    <input type="text" [formControlName]="i" class="pl-3 w-full" [placeholder]="'Option ' + ( i + 1 )">
                    <span *ngIf="choice.errors && choice.touched" class="checkBox-error" >Required Field.</span>
                </mat-checkbox>
                <button mat-icon-button (click)="removeOption(i)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <p *ngIf="questionForm.get('choices').errors" class="text-red-700" > At least 1 answer option is required for question creation </p>
            <button class="block mt-3" (click)="addMoreOption()">Add More Choice</button>
        </ng-container>

        <!-- <ng-container *ngIf="selectedAnswerType === ANSWER_TYPE_ENUM.DATE && questionForm.get('choices')">
            <mat-form-field class="fuse-mat-dense w-8/12">
                <mat-label>Choose a date</mat-label>
                <input matInput formControlName="choices" [matDatepicker]="picker">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedAnswerType === ANSWER_TYPE_ENUM.NUMBER && questionForm.get('choices')">
            <mat-form-field class="fuse-mat-dense w-8/12 mt-3" floatLabel="always">
                <input formControlName="choices" matInput type="number" />
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="selectedAnswerType === ANSWER_TYPE_ENUM.TEXT && questionForm.get('choices')">
            <mat-form-field class="fuse-mat-dense w-8/12 mt-3" floatLabel="always">
                <input formControlName="choices" matInput />
            </mat-form-field>
        </ng-container> -->

        <!-- <div>
            <button class="mat-primary my-4 rounded bg-[#4AC56A]" mat-raised-button>
                Add Attachments
            </button>
        </div> -->

        <div class="border-b my-4"></div>

        <div class="flex flex-auto w-full py-2">
            <mat-slide-toggle labelPosition="before" formControlName="isRequired" class="pr-5">This is required
            </mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" formControlName="canSupplierAttachDocument" class="pr-5">Supplier
                can attach document</mat-slide-toggle>
            <mat-slide-toggle labelPosition="before" formControlName="isAttachmentRequired" class="pl-3">Attachment
                Required</mat-slide-toggle>
        </div>
    </div>
</form>
<div class="px-6 py-3 flex items-center justify-end mt-4 bg-gray-100">
    <button type="button" mat-raised-button class="bg-[#D9D9D9] rounded" mat-dialog-close>Cancel</button>
    <button class="mat-primary ml-4 rounded" mat-raised-button (click)="saveQuestion()">Save</button>
</div>