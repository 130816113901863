<div class="field-error">
    <p *ngIf="control?.errors?.required && (control?.touched || control?.dirty)"> Required Field. </p>

    <span *ngIf="control?.errors?.maxlength"> Required Length 
        {{ control?.errors?.maxlength?.requiredLength }} | Actual Length {{ control?.errors?.maxlength?.actualLength }}. 
    </span>
    <span *ngIf="control?.errors?.minlength"> Required Length 
        {{ control?.errors?.minlength?.requiredLength }} | Actual Length {{ control?.errors?.minlength?.actualLength }}. 
    </span>
    <p *ngIf="control?.errors?.max"> Upto 
        {{ control?.errors?.max?.max }} | Actual {{ control?.errors?.max?.actual }}. 
    </p>
    <p *ngIf="control?.errors?.min"> Upto 
        {{ control?.errors?.min?.min }} | Actual {{ control?.errors?.min?.actual }}. 
    </p>
    <p *ngIf="control?.errors?.email"> Please provide valid email. </p>

    <span *ngIf="control?.errors?.[errorKeys.NUMBER_VALIDATION]"> Please provide number only. </span>

    <p *ngIf="control?.errors?.[errorKeys.DUPLICATE_ERROR]"> Please provide unique value. </p>

    <p *ngIf="control?.errors?.[errorKeys.SECTION_ERROR]"> Please add section. </p>

    <p *ngIf="control?.errors?.[errorKeys.QUESTION_ERROR]"> Please add question. </p>
    
</div>

<!-- <div class="field-error" >
    <ng-container  *ngIf="control?.errors" >
        <ng-container *ngFor="let err of control.errors | keyvalue" > 
            <span >{{ customErrors[err.key] }}</span>
            <span> {{ err | json }} </span>
        </ng-container>
    </ng-container>
</div> -->