export enum RfxEventType {
    QUOTATION = 'request for quotation',
    TENDER = 'request for tender',
}

export enum RfxTemplateStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum ViewerType {
    VIEWER = 'viewer',
    ASSOCIATE_VIEWER = 'associate viewer',
}

export enum APPROVAL_ROUTE_TYPE {
    APPROVAL_ROUTING = 'approval-routing',
    SOURCING_PROPOSAL_ROUTING = 'sourcing-proposal-routing'
}

export enum AttachmentType {
    BILL_OF_QUANTITY = 'bill of quantity',
    QUESTIONNAIRE = 'questionnaire',
}


export enum ANSWER_TYPE {
    CHOICE = 'choice',
    CHOICE_WITH_SCORE = 'choice with score',
    TEXT = 'text',
    CHECKBOX = 'checkbox',
    DATE = 'date',
    NUMBER = 'number',
    FILE_ATTACHMENT = 'File Attachment'
}


export enum DOCUMENT_TYPE {
    EXTERNAL = 'External',
    INTERNAL = 'Internal'
}

export enum RFX_BASED_ON {
    PRE_PR = 'pre pr',
    RFX_TEMPLATE = 'rfx template'
}

export enum BUYER_RESPONSE_STATUS {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
}

export enum SUPPLIER_STATUS {
    INVITED = 'INVITED',
    PREVIEWED = 'PREVIEWED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    SUBMITTED = 'SUBMITTED',
    QUALIFIED = 'QUALIFIED',
    DISQUALIFIED = 'DISQUALIFIED',
}

export enum SUPPLIER_SELECTION {
    MANUAL = 'manual',
    SUPPLIER_CATEGORY = 'supplier-category'
}

export enum MEETING_TYPE {
    MS_TEAMS = 'MS Teams',
    ONSITE = 'On Site'
}
