<h1 mat-dialog-title>
    Confirmation
</h1>

<div mat-dialog-content>
    <p>{{message}}</p>
</div>

<div mat-dialog-actions
    class="flex items-center justify-end py-5 px-21">
    <button mat-raised-button class="bg-[#D9D9D9]" (click)="onDismiss()">No</button>
    <button mat-raised-button
        color="primary"
        (click)="onConfirm()"
        class="ml-5">Yes</button>
</div>