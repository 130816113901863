<mat-label class="pl-6 pr-2 py-1 font-bold text-black block border-b flex justify-between items-center">
    {{dialogInput?.downloadedFileName || 'Image Viewer'}}
    <button mat-icon-button mat-dialog-close>
        <mat-icon class="text-black">cancel</mat-icon>
    </button>
</mat-label>
<div class="flex flex-col p-3 w-full">
    <div class="mb-2" *ngIf="!dialogInput?.hideDownloadBtn">
        <a mat-button class="float-right" [color]="'accent'" [href]="imgSrc" [download]="this.dialogInput?.downloadedFileName" *ngIf="imgSrc">
            <mat-icon svgIcon="heroicons_solid:download"></mat-icon>
            <span>Download</span>
        </a>
    </div>
    <div class="img-container">
        <img [src]="imgSrc" alt="No Image" class="w-full" *ngIf="imgSrc">
    </div>
</div>
