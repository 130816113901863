<mat-label class="pl-6 pr-2 font-bold text-black block border-b flex justify-between items-center">Create New Section
    <button mat-icon-button mat-dialog-close>
        <mat-icon class="text-black">cancel</mat-icon>
    </button>
</mat-label>
<form action="" [formGroup]="sectionForm">
    <div class="mt-6 mx-6 md:flex">
        <div class="md:w-3/12 w-full">
            <mat-label class="font-bold text-black mt-3">Name *</mat-label>
        </div>
        <div class="md:w-9/12 w-full">
            <app-input-field formControlName="name" placeholder="Enter Section Name" [maxLength]="250"></app-input-field>
        </div>
    </div>

    <div class="mt-3 mx-6 md:flex">
        <div class="md:w-3/12 w-full">
            <mat-label class="font-bold text-black mt-3">Section Description</mat-label>
        </div>
        <div class="md:w-9/12 w-full">
            <app-text-area formControlName="description" [maxLength]="500" ></app-text-area>
        </div>
    </div>
</form>
<div class="px-6 py-3 flex items-center justify-end mt-4 bg-gray-100">
    <button type="button" mat-raised-button class="bg-[#D9D9D9] rounded" mat-dialog-close>Cancel</button>
    <button type="button" class="mat-primary ml-4 rounded" mat-raised-button
        (click)="addSection()">Save</button>
</div>