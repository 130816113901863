export enum INPUT_FIELD_TYPE {
    NUMBER = 'number',
    TEXT = 'text',
    TIME = 'time',
    CUSTOM_TIME = 'custom-time',
    MULTIPLE_INPUT = 'multiple-ib'
}

export enum DROPDOWN_TYPE {
    SIMPLE = 'simple',
    SEARCH = 'search-dropdown',
}


export enum QUESTIONNAIRE_SOURCE {
    RFX = 'RFX',
    RFX_TEMPLATE = 'RFX_TEMPLATE',
}