import { DOCUMENT_TYPE } from 'app/enums/rfx-enum/rfx.enum';
import { ViewerTypeEnum } from 'app/enums/viewer-type.enum';
import { EventType } from 'app/interfaces/common-interface/common-interface';
import { RfxSelectedSupplier } from 'app/interfaces/rfx-interface/rfx-form.interface';
import { dropdown } from 'app/interfaces/shared-form-field-interface/dropdown.interface';
import { User } from 'app/interfaces/users';
import moment from 'moment';
import * as momentBusiness from 'moment-business-days';


export function generateArrayFromEnum(enumValue: any): dropdown[] {
    const value: dropdown[] = []; // string all enum value in this array
    let index = 0
    for (let i in enumValue) {
        index++
        value.push({ id: index, name: enumValue[i] });
    }
    return value;
}


// export function formatDateTime(dateTime) {
//     if (!dateTime) return
//     const date = new Date(dateTime)
//     const filterdTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
//     return `${date.toLocaleDateString('en-US')} ${filterdTime}`

// }

export function formatDateTime(
    utc: any,
    displayTime?: string,
    showOnlyTime?: string
) {
    /**
     * @param utc
     * @param displayTime if you want to display time along with date than pass this (displayTime) args
     * @param shoOnlyTime if you want to display only time than pass (showOnlyTime) this args
     */
    if (!showOnlyTime) {
        return moment(utc).format(`DD/MM/YYYY ${displayTime ? 'h:mm a' : ''}`);
    } else return moment(utc).format('h:mm a');
}

export function continentalTimeToAmPM(time: string) {
    if (!time) return '00:00'; 
    const [hour, min] = time.split(':')
   
    if (parseInt(hour) >= 12) {
        const convertedTime = parseInt(hour) - 12
        const localTime = `${convertedTime === 0 ? 12 : convertedTime}:${min} PM`
        return localTime
    }
    else {
        const hr = hour === '00' ? 12 : hour;        
        return `${ hr }:${ min } AM`
    }
}

export function curentUserInfo(): User {
    if (sessionStorage.getItem('t-pro') === null) {
        return null;
    }
    return JSON.parse(sessionStorage.getItem('t-pro')).user
}
export function hasUserLogin(): boolean {
    if (sessionStorage.getItem('t-pro') === null) {
        return false;
    }
    return true;
}

export function internalOrExternal(choice: boolean): DOCUMENT_TYPE {
    return choice ? DOCUMENT_TYPE.EXTERNAL : DOCUMENT_TYPE.INTERNAL
}


export function removeNullIdFromObject(obj: {}, id: string) {
    for (let key in obj) {
        switch (typeof obj[key]) {
            case 'object':
                if (id.indexOf(key) > -1) delete obj[key];
                else removeNullIdFromObject(obj[key], id);
                break;
            default:
                if (id.indexOf(key) > -1 && !obj[key]) delete obj[key];
                break;
        }
    }
}

export function removeKeyFromObject(obj: {}, id: string) {
    for (let key in obj) {
        switch (typeof obj[key]) {

            case 'object':
                if (id.indexOf(key) > -1) delete obj[key];
                else removeKeyFromObject(obj[key], id);
                break;
            default:
                if (id.indexOf(key) > -1) delete obj[key];
                break;
        }
    }
}

export function getEventTypesAbbr(eventTypes: EventType[], eventName: string): string {
    if (eventName && eventName !== '') {
        const event = eventTypes.find(item => item.event_type_description.toLowerCase() === eventName.toLowerCase());
        return event ? event.event_type_name : '--';
    }
    return '--';
}

export const getArrayFromString = (value: string, seperator: string): string[] => (value && value !== '') ? value.split(seperator) : [];

export function parseUTCDateToLocal(dateStr: string): Date {
    // return new Date(dateStr + ' UTC');
    let date = new Date(dateStr);
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );
}


export function filterUserName(userList: any[], userId: number) {
    const name = userList.find((list) => list.id === userId)?.name
    return name

}


export function checkDuplicateNumber(arr: number[], targetNumber: number): boolean {
    let counter = 0

    for (let i of arr) {
        i === targetNumber && counter++
    }

    return counter > 1 ? true : false
}

// convert utc to local
export function utcToLocal(utcDate: string, format: string) {
    const stillUtc = moment.utc(utcDate).toDate();
    const local = moment(stillUtc).local().format(format);
    return local;
}

export const sortArrayOfObject = (array: Array<any>,
    fieldName: string, sortOrder: 'asc' | 'desc' = 'asc'): Array<any> => {
    const temp = array?.sort((a, b) => {
        const fieldA = a[fieldName].toLocaleLowerCase();
        const fieldB = b[fieldName].toLocaleLowerCase();
        return sortOrder === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
    });
    return temp;
};

export const sortSupplier = (selectedSupplier: Array<RfxSelectedSupplier>): Array<any> => {
    const temp = selectedSupplier?.sort((a, b) => {        
        const fa = a.supplier?.companyName.toLocaleLowerCase();
        const fb = b.supplier?.companyName.toLocaleLowerCase();
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });
    return temp;
};


export const isAssociateOwnerMember = (teamMembers: Array<any>): boolean => {
    const associateOwnerIndex = teamMembers?.findIndex(
        member =>
            member.userId === curentUserInfo().id &&
            member.viewStatus === ViewerTypeEnum.ASSOCIATE_OWNER.toLowerCase()
    );

    return associateOwnerIndex >= 0;
};

export const isViewerMember = (teamMembers: Array<any>): boolean => {
    const viewerIndex = teamMembers?.findIndex(
        member =>
            member.userId === curentUserInfo().id &&
            member.viewStatus === ViewerTypeEnum.VIEWER.toLowerCase()
    );

    return viewerIndex >= 0;
};

export const isUserExistInList = (list: Array<any>): boolean => {
    if (list?.length > 0) {
        return list.findIndex(item => item.userId === curentUserInfo().id) >= 0;
    }
    return false;
};

export const downloadAndSaveFile = (fileUrl: string, downloadedFileName: string): void => {
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.href = fileUrl;
    downloadLink.setAttribute('download', downloadedFileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

export function skipWeekends(howManyDays: number = 2) {
    let today = moment(
        momentBusiness(
            moment().format('DD-MM-YYYY'),
            'DD-MM-YYYY',
        )
            .businessAdd(howManyDays)
            .toDate(),
    ).format('YYYY-MM-DD');

    return today
}

//Output: "ac75-89c1-e705-845e"
export function getUniqueId(): string {
    const stringArr = [];
    for (let i = 0; i < 4; i++) {
        const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        stringArr.push(S4);
    }
    return stringArr.join('-');
}
