/* eslint-disable @typescript-eslint/naming-convention */
export enum STATUS {
    ACTIVE = 'Active',
    IN_ACTIVE = 'Inactive',
}

export enum EVENT_TYPE {
    RFQ = 'RFQ',
    RFT = 'RFT',
    RFA = 'RFA',
    RFP = 'RFP',
    RFI = 'RFI',
}

export enum RFX_FORM_STATUS {
    DRAFT = 'Draft',
    PENDING = 'Pending',
    ACTIVE = 'Active',
    // APPROVED = 'approved',
    // REJECTED = 'rejected',
    CANCELLED = 'cancelled',
    // ESCALATE = 'escalated',
    FINISHED = 'Finished',
    // INACTIVE = 'inactive',
    // SUSPENDED = 'Suspended',

    // new statuses for rfx/sourcing.
    PENDING_BUYER_EVALUATION = 'Pending Buyer Evaluation',
    PENDING_REQUESTOR_EVALUATION = 'Pending Requestor Evaluation',
    PENDING_EVALUATION_APPROVAL = 'Pending Evaluation Approval',
    COMPLETE = 'Completed',
    SOURCING_PROPOSAL_DRAFT = 'Sourcing Proposal Draft',
    SOURCING_PROPOSAL_PENDING = 'Sourcing Proposal Pending',
    // CLOSED = 'Closed'
}

export enum RFX_APPROVAL_LEVEL_STATUS {
    DRAFT = 'Draft',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    PRE_PR_REJECTED = 'pre pr rejected', /// need to add in BE
    ESCALATED = 'Escalated'
}

export enum RFX_APPROVAL_ACTION {
    APPROVED = 'approved',
    REJECTED = 'rejected',
    PRE_PR_REJECTED = 'pre pr rejected',
}

export enum RFX_ROUTE_ACTION {
    RFX_TEMPLATE_METADATA = 'APPLY RFX TEMPLATE METADATA',
    PRE_PR_AND_RFX_METADATA = 'APPLY PRE PR AND RFX METADATA',
    EDIT_RFX_FORM = 'EDIT RFX FORM',
    CREATE_NEGO = 'create-negotiation'
}

export enum EQUIVALENT_BRAND_ALLOWED {
    YES = 'Yes',
    YES_WITH_OPTION = 'Yes with option',
    NO = 'No',
    NOT_APPLICABLE = 'Not applicable',
}

export enum ADDRESS_TYPE {
    MANUAL = 'MANUAL',
    LIST = 'LIST',
}

export enum PO_FILTER_STATUS {
    ACCEPTED = 'accepted',
    BLOCKED = 'blocked',
    DECLINE = 'declined',
    FULFILLED = 'fulfilled',
    NEW = 'new',
    PARTIAL = 'partial',
    READY = 'ready',
    //CANCELLED = 'cancelled',
}

export enum GRA_STATUS {
    DRAFT = 'draft',
    PENDING = 'pending',
    BILLED = 'billed',
    UNBILLED = 'unbilled'
}

export enum ConcludeMenuEnum {
    CREATE_NEXT_EVENT = 'Create Next Event',
    CONCLUDE_EVENT_WITH_REMARK = 'Conclude Event with Remark',
    SOURCING_PROPOSAL = 'Sourcing Proposal',
    VIEW_AWARD = 'View Award'
}


export enum EVALUATION_MAPPING {
    QUALITY_SPECS = 'quality / specification',
    LEAD_TIME = 'lead time',
    COST = 'cost',
    WARRANTY = 'warranty'
}

export enum APPROVAL_MODULES {
    GRA = 'GRA',
    INVOICE = 'Invoice',
    EINVOICE = 'eInvoice',
    ANNOUNCEMENT = 'Announcement',
    SUPPLIER = 'Supplier',
}

export enum BUDGET_STATUS {
    SCHEDULED = 'Scheduled',
    EXPIRED = 'Expired',
    ACTIVE = 'Active',
    BUFFER = 'Buffer',
}

export enum PR_STATUS {
    ECAPEX_DRAFT = 'ECAPEX Draft',
    ECAPEX_PENDING = 'ECAPEX Pending',
    ECAPEX_APPROVED = 'ECAPEX Approved',
    ECAPEX_CANCELLED = 'ECAPEX Cancelled	',
    PR_DRAFT = 'PR Draft',
    PR_PENDING = 'PR Pending',
    PR_FINISHED = 'PR Finished',
    PR_CANCELLED = 'PR Cancelled',
}

export enum USER_MODULE {
    DASHBOARD = 'Dashboard',
    PRE_PR = 'Pre-PR',

    SOURCING = 'Sourcing',
    PO = 'Purchase Order',
    GRA = 'Goods Receipt Advice',
    INVOICE = 'Invoice',
    PAYMENT_REMITTANCE = 'Payment Remittance',
    CONTRACT_PRICE = 'Contract Price',
    SUPPLIER_MANAGEMENT = 'Supplier Management',
    TEMPLATE_MANAGEMENT = 'Template Management',
    USER_MANAGEMENT = 'User Management',
    SYSTEM_SETTING = 'System Setting',
    ANNOUNCEMENT = 'Announcement',
    BUDGET_MANAGEMENT = 'Budget Management',
    PR = 'Purchase Requisition',
    REQUEST_TRACKING_REPORT = 'Request Tracking Report',
    SELF_MANAGE_TRACKING_REPORT = 'Self Manage Tracking Report',
}


export enum PRE_PR {
    PRE_PR_List = 'Pre-PR List',
    COPY_FROM_PREVIOUS = 'Copy From Previous',
}

export enum PR {
    PR_LIST = 'PR List',
    ERROR_CLEARANCE = 'Error Clearance',
}

export enum GRAA {
    GRA_LIST = 'GRA List',
    ERROR_CLEARANCE = 'Error Clearance',
}

export enum SUPPLIER_MANAGEMENT {
    SUPPLIER_LIST = 'Supplier List',
    SUPPLIER_FORM = 'Supplier Form',
    SUPPLIER_ROLES = 'Supplier Roles',
    SUPPLIER_MODULE_ACCESS_RIGHTS = 'Supplier User Module Access Rights',
}

export enum TEMPLATE_MANAGEMENT {
    PRE_PR_TEMPLATE = 'Pre-PR Template',
    SOURCING_TEMPLATE = 'Sourcing Template',
    PR_TEMPLATE = 'PR Template',
    APPROVAL_MANAGEMENT_TEMPLATE = 'Approval Management Template',
}

export enum USER_MANAGEMENT {
    USER_LIST = 'User List',
    USER_ROLES = 'User Roles',
    USER_MODULE_ACCESS_RIGHTS = 'User Module Access Rights',
}

export enum SYSTEM_SETTING {
    SUPPLIER_CATEGORY = 'Supplier Category',
    SUPPLIER_TAG = 'Supplier Tag',
    SUPPROTING_DOCUMENT = 'Supporting Document',
    DEPARTMENT = 'Department',
    ASSET_NUMBER_LIST = 'Asset Number list',
    COST_CENTER = 'Cost Center',
    INTERNAL_ORDER_NUMBER = 'Internal Order Number',
    GL_CODE = 'GL Code',
    ACCOUNT_ASSIGNMENT = 'Account Assignment',
    PROFITABILITYY_SEGMENT_MODEL = 'Profitability Segment - Model',
    PROFITABILITYY_SEGMENT_CLASS = 'Profitability Segment - Class',
    PROFITABILITYY_SEGMENT_SERIES = 'Profitability Segment - Series',
    PROFITABILITYY_SEGMENT_PROFIT_CENTER = 'Profitability Segment - Profit Center',
    PROFITABILITYY_SEGMENT_BUSINESS_AREA = 'Profitability Segment - Business Area',
    PAYMENT_TERMS = 'Payment Terms',
    ADDRESS = 'Address',
    WARRANTY = 'Warranty',
    PLANT = 'Plant',
    UOM = 'UOM',
    CURRENCY = 'Currency',
    SUPPLIER_PASSWORD_POLICY = 'Supplier Password Policy',
    EMAIL_SERVER_SETTING = 'Email Server Setting',
    ID_SETTING = 'ID setting',
    PURCHASING_ORGANIZATION = 'Purchasing Organization',
    VENDOR_ACCOUNT_GROUP = 'Vendor Account Group',
    INDUSTRY_CATEGORY = 'Industry Category',
    BANK = 'Bank',
    RECON_ACCOUNT = 'Recon Account',
    PAYMENT_METHOD = 'Payment Method',
    HOUSR_BANK = 'House Bank',
}

export enum USER_ROLE_ACCESS_MODULE_NAMES {
    ADMINISTRATOR = 'Administrator',
    ADMINISTRATOR_VIEWER = 'Administrator Viewer',
    DEPARTMENT_ADMINISTRATOR = 'Department Administrator',
    DEPARTMENT_VIEWER = 'Department Viewer',
    NORMAL = 'Normal',
}

export enum USER_ROLE_ACCESS_MODULE_IS_NAMES {
    isDepartmentViewer = 'isDepartmentViewer',
    isDepartmentAdmin = 'isDepartmentAdmin',
    isAdmin = 'isAdmin',
    isAdminViewer = 'isAdminViewer',
    isNormal = 'isNormal',
}
